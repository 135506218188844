import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import Button from '../../../../../../components/button/button';
import { useLocation } from "react-router-dom";
import urls from "../../../../../../utils/services/apiUrl.json";
import { postMethodWithToken } from "../../../../../../utils/services/api";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StorefrontIcon from '@material-ui/icons/Storefront';

const ApproveStoreDetails = () => {
    const history = useHistory();
    const location = useLocation();
    const [approvedDetails,setApprovedDetails]=useState(location.state.item);

    const gotoBack = async () => {
        history.goBack()
    };

    const onHold = async () => {
        let body ={
            shop_id : approvedDetails.id
        };
        await postMethodWithToken(urls.approve + "on_hold", body).then((response) => {
            if (response.status === 200) {
                history.push("/stores");
            }
        });
    };

    return (
        <div className="w-100 screenLeftMargin">
            <div className="row mt-3 pl-3 ml-3 mr-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <h1 className="heading-title">
                        <StorefrontIcon fontSize="large"/> Approved Store
                    </h1>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <Button onClick={() => onHold()} className="btn btn-primary ml-md-2" name="On Hold"></Button>
                </div>
            </div>
            
            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <div className='reviewBox'>
                        <div className='row '>
                            <div className='col-4 mt-4 pl-5'>
                                <h3 className='h6-label'>Shop Details</h3>
                                <div>
                                    <p className='h2-label mb-2 mt-3'>{approvedDetails.store_company_name}</p>
                                    <p className='h2-label'>ID: <span>{approvedDetails.id}</span></p>
                                </div>
                            </div>

                            <div className='col-4 mt-4'>
                                <h3 className='h6-label'>Owner Details</h3>
                                <p className='h2-label mt-3'>{approvedDetails.owner_name}</p>
                            </div>
                            
                            <div className='col-4 mt-4 pr-5'>
                                <h3 className='h6-label'>Contact</h3>
                                <p className='mb-1 mt-3'>
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> {approvedDetails.email}
                                </p>
                                <p> 
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> 
                                    {approvedDetails.country_code}{approvedDetails.phone_number}
                                </p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 ml-4 mt-3 pl-4'>
                                <span><p className='h5-label'>Store Details</p></span>
                                <p className='h6-label mb-0'>Store Name  <span className='leadBox-details pl-4 ml-2'>: {approvedDetails.store_company_name}</span></p>
                                <p className='h6-label mb-0'>Store Type  <span className='leadBox-details pl-4 ml-3'>: Full Time (offline)</span></p>
                                <p className='h6-label mb-0'>Store Category  <span className='leadBox-details pl-2 ml-1'>: Electronics</span></p>
                                <p className='h6-label mb-0'>Revenue  <span className='leadBox-details pl-5 ml-2'>: 20</span></p>
                                <p className='h5-label mt-5'>Address</p>
                                <p className='leadBox-details w-50'>{approvedDetails.location}</p>
                            </div>
                            <div className='col-sm-5 mt-3'>
                                <h1 className='h5-label'>Description</h1>
                                <p className='leadBox-details'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore
                                    magna aliqua. Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApproveStoreDetails;