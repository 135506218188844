import React from 'react';
import './Notification.scss';
import Bell from "../../../../assets/images/Notification_N.svg";
import Email from "../../../../assets/images/Email_E.svg";
import Bell1 from "../../../../assets/images/Bell_B.svg";
import NoImage from "../../../../assets/images/storelisting.png";
import { useHistory } from "react-router-dom";
import EmailIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Notification = () => {
    const history = useHistory();

    const gotoBack = async () => {
        history.goBack()
    };

    const notification = async () => {
        history.push("/notifications");
    };

    return (
        <div className='screenLeftMargin'>
            <div>
                <button className="Button ml-5 mt-3" onClick={() => gotoBack()}>
                    <ArrowBackIcon/> Back
                </button>
            </div>
            <div className='notifications-icon ml-3' onClick={() => notification()}>
                <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
            </div>
            <div className='notifications-icon '>
                <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
            </div>
            <div className=''>
                <div className='d-flex mt-5'>
                    <img className='noti-icon mt-1 ml-5' src={Bell} alt="" />
                    <h1 className='noti-text ml-3'>Notifications</h1>
                </div>

                <div>
                    <h1 className='Days mt-5 ml-5'>
                        Today
                    </h1>
                    <table className='notification-Section ml-5'>
                        <tr>
                            <td>
                                <img src={NoImage} style={{width: "50px", height: "50px", borderRadius: "50%"}}/>
                                Ben Timothy added a new document to his profile.
                            </td>
                        </tr>
                    </table>
                </div>

                <div>
                    <h1 className='Days mt-5 ml-5'>
                        Earlier
                    </h1>
                    <table className='notification-Section ml-5'>
                        <tr>
                            <td>
                                <img src={NoImage} style={{width: "50px", height: "50px", borderRadius: "50%"}}/>
                                Ben Timothy added a new document to his profile.
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Notification;
