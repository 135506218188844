import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Checkbox, InputAdornment } from "@material-ui/core";
import Modal from "react-modal";
import { SketchPicker } from "react-color";
import Button from "../../../../../components/button/button";
import { useDropzone } from "react-dropzone";
import { Draggable } from "react-beautiful-dnd";
import JoditEditor from "jodit-react";
import CreatableSelect from "react-select/creatable";
import { getMethod, postMethodWithTokenProgress, postMethodWithToken, getProductDetails, putMethodWithToken } from "../../../../../utils/services/api";
import urls from "../../../../../utils/services/apiUrl.json";
import { getStoreId, getLocation, getProductId, getProductEditableFlag, setInventoryEditedFlag, getInventoryEditedFlag } from "../../../../../utils/common";
import { toast } from "react-toastify";
import ProgressBar from "../../../../../components/progressBar/progressBar";
import SpinnerLoader from "../../../../../components/loader/spinnerLoader";
import Delete from "../../../../../assets/images/delete.png";
import DeleteIcon from '@material-ui/icons/Delete';
import UploadPhotoIcon from "../../../../../assets/images/add_a_photo.png";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import ReactCrop from 'react-image-crop';
import Switch from "react-switch";
import 'react-image-crop/dist/ReactCrop.css';
import imageCompression from "browser-image-compression";
import OnHoldModal from "../../../../../components/OnHoldModal/onHoldModal";

const checkInventoryInfo = inventoryData => {

    const emptyKeys = [
        'img_ids',
        'tax_detail',
        'upc',
        'partner_sku',
        'stock_qty',
        'low_stock_threshold',
        'invoice_cost',
        'price',
        'compare_at_price',
    ];

    const emptyKeysErrors = [
        'Image',
        'Tax Category',
        'Upc',
        'Sku',
        'Stock Qty',
        'Stock Threshold',
        'Invoice Cost',
        'Sale Price',
        'List Price',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {

        if (emptyKeys[i] === 'img_ids' && inventoryData[emptyKeys[i]].length === 0) {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }

        if (emptyKeys[i] === 'stock_qty' && (isNaN(inventoryData[emptyKeys[i]]) || inventoryData[emptyKeys[i]] === "")) {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }

        if (emptyKeys[i] === 'low_stock_threshold' && (isNaN(inventoryData[emptyKeys[i]]) || inventoryData[emptyKeys[i]] === "")) {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }

        if (!inventoryData[emptyKeys[i]] && emptyKeys[i] !== 'stock_qty' && emptyKeys[i] !== 'low_stock_threshold') {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }

        if (emptyKeys[i] === 'tax_detail' && !inventoryData.tax_exempted === true && !inventoryData[emptyKeys[i]].categoryCode) {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }
    }

    if (inventoryData.colorPickerDropdownValue && inventoryData.colorPickerDropdownValue === 'color' && !inventoryData.color_code) {
        return `Please Fill In Color Code`;
    }

    if (Number(inventoryData.price) > Number(inventoryData.compare_at_price)) {
        return `Please Fill List Price Greater Than Sale Price`;
    }

    if (Number(inventoryData.invoice_cost) > Number(inventoryData.compare_at_price)) {
        return `Please Fill List Price Greater Than Invoice Cost`;
    }

    if (Number(inventoryData.low_stock_threshold) >= Number(inventoryData.stock_qty)) {
        return `Please Fill Stock Threshold Less Than Stock Qty`;
    }

    return '';
};


function InventorySection({ inventoryData, invAttributesData, setInvAttributesData, prevInventoryData, inventorySpecificationProps, inventoryCancellationProps, setSwipeableFlag, skipAttributeChecking }) {
    const [pickerList, setPickerList] = useState([
        { value: "color", label: "Color" },
        { value: "image", label: "Image" },
    ]);

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const imageModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "70vw",
            height: "80vh",
            outline: "none",
            padding: "15px",
        },
    };

    const colorPickerModalStyle = {
        content: {
            top: "40%",
            left: "50%",
            transform: 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "25vw" : "20vw",
            height: screenSize.width <= 1100 ? "55vh" : "56vh",
        },
    };

    const imageModalProfileStyle = {
        content: {
            top: screenSize.width <= 800 ? "60%" : "52%",
            left: screenSize.width <= 800 ? "35%" : "25%",
            transform: screenSize.width <= 800 ? 'translate(-30%, -35%)' : 'translate(-40%, -45%)',
            backgroundColor: "white",
            border: "1px solid black",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "23vw" : screenSize.width <= 1100 ? "23vw" : "25vw",
            height: screenSize.width <= 800 ? "20vh" : "25vh"
        },
    }

    const deleteModalStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "44vw" : screenSize.width <= 1100 ? "32vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    function getInventorySpecificationsConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Product Specifications",
        }
        return config;
    }

    const inventorySpecificationsConfig = useMemo(() => getInventorySpecificationsConfig(inventorySpecificationProps), [inventorySpecificationProps]);

    function getInventoryCancellationPolicy(props) {
        let config = {
            readonly: false,
            placeholder: "Product Cancellation Policy",
        }
        return config;
    }
    const inventoryCancellationPolicy = useMemo(() => getInventoryCancellationPolicy(inventoryCancellationProps), [inventoryCancellationProps]);
    const editor = useRef(null);
    const [loader, setLoader] = useState(false);
    const [inventoryState, setInventoryState] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [taxjarList, setTaxjarList] = useState([]);
    const [taxjarListData, setTaxjarListData] = useState([]);
    const [modalColorPickerFlag, setModalColorPickerFlag] = useState(false);
    const [modalDeleteInventoryFlag, setModalDeleteInventoryFlag] = useState(false);
    const [modalCurInventoryFlag, setModalCurInventoryFlag] = useState(false);
    const [curInventoryIndex, setCurInventoryIndex] = useState(0);
    const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
    const [tagsListIsOpen, setTagsListIsOpen] = useState(false);
    const [InventoryAttributesCount, setInventoryAttributesCount] = useState(0)
    const [expandIndex, setExpandIndex] = useState(0);
    const [selectProductTag, setSelectProductTag] = useState([]);
    const [photoImage, setPhotoImage] = useState("");
    const [imagePicker, setImagePicker] = useState([]);
    const [openImageCrop, setOpenImageCrop] = useState(false);
    const [imageCrop, setImageCrop] = useState();
    const [imageModal, setImageModal] = useState(false);
    const [modalIndex, setModalIndex] = useState(0);
    const [pickerImageIndex, setpickerImageIndex] = useState(0);
    const [clickItem, setClickItem] = useState(false);
    const [modalMe, setModalMe] = useState(false)
    const [selectIndex, setSelectIndex] = useState([])

    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropProfile, setCropProfile] = useState({
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50
    });
    const [cropFlag, setCropFlag] = useState(true)
    const imgRefProfile = useRef(null);

    const dragItem = useRef();
    const dragOverItem = useRef();

    useEffect(() => {
        if (prevInventoryData && prevInventoryData.attributesCount === invAttributesData.length && prevInventoryData.inventoryState.length > 0 && (skipAttributeChecking === true || skipAttributeChecking !== true)) {
            setInventoryState(prevInventoryData.inventoryState)
            setLoader(true)
        } else {

            if (invAttributesData === '' && getProductId() && (getProductEditableFlag() !== 'added' || getInventoryEditedFlag() === 'editable')) {
                getInventoryDataWithoutAtt();
            } else if (getProductId() && (getProductEditableFlag() !== 'added' || getInventoryEditedFlag() === 'editable')) {
                getInventoryData(invAttributesData)
            } else {
                generateInventoryData();
            }
        }
        getLocationList();
        getSteps()
        getTagsListing();
        getCountryList();
        getTaxjarCategory();

    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    };

    useEffect(() => {
        let backData = {
            inventoryState: inventoryState,
            is_submit: false,
            attributesCount: InventoryAttributesCount,
            is_added: false
        }

        inventoryData(backData)

    }, [inventoryState, InventoryAttributesCount]);

    const getTagsListing = async () => {
        await getMethod(urls.tagsList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setTagsList(response.data);
            }
        });
    };

    const getTaxjarCategory = async () => {
        await getMethod(urls.getTexjarList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setTaxjarList(response.data.categories.categories);
                setTaxjarListData(response.data.categories.categories);
            }
        });
    };

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200) {
                setCountryList(response.data);
            }
        });
    };

    const postUPCListCheck = async (data) => {
        let body = {
            "check_upc": data
        };

        await postMethodWithToken(urls.postUPCListCheck, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                // toast.success(response.data.response)
            } else if (response.status === 400) {
                toast.error(response.data.response)
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const postSKUListCheck = async (data) => {
        let body = {
            "check_sku": data
        };

        await postMethodWithToken(urls.postSKUListCheck, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                // toast.success(response.data.response)
            } else if (response.status === 400) {
                toast.error(response.data.response)
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const getInventoryDataWithoutAtt = async () => {
        const attrName = [];
        const attrValue = [];
        const attrNameColor = [];
        const attrValueColor = [];
        const attrDetails = [];

        await getMethod(urls.getAttribute + getProductId() + "/").then((response) => {
            if (response.status === 200) {
                if (response.data.attributes.length > 0) {
                    response.data.attributes.length > 0 && response.data.attributes.map((item, index) => {
                        if (item.value_text !== "color_code_swatch" && item.name !== "color") {
                            if (!attrName.includes(item.name)) {
                                attrName.push(item.name);
                                attrValue[item.name] = [];
                            }

                            if (!attrValue[item.name].includes(item.value_text)) {
                                attrValue[item.name].push(item.value_text)
                            }
                        } else if (item.name === "color") {

                            if (!attrNameColor.includes(item.name)) {
                                attrNameColor.push(item.name);
                                attrValueColor[item.name] = [];
                            }

                            if (!attrValueColor[item.name].includes(item.value_text)) {
                                attrValueColor[item.name].push(item.value_text)
                            }
                        }
                    });

                    if (attrNameColor.length > 0) {
                        attrNameColor.forEach((x, i) => {
                            let data = {
                                name: attrNameColor.map(item => item).join(','),
                                values: attrValueColor[x].map(item => item).join(',').split(','),
                            }

                            attrDetails.push(data)
                        });
                    }

                    if (attrName.length > 0) {
                        attrName.forEach((x, i) => {
                            let data = {
                                name: x,
                                values: attrValue[x].map(item => item).join(',').split(','),

                            }
                            attrDetails.push(data)
                        });
                    }

                    if (attrDetails.length > 0) {
                        getInventoryDataWithoutAttVariants(attrDetails)
                    }

                } else {
                    getInventoryData([])
                }
            }
        }).catch((error) => {
            console.log("API error", error);
        });
    }


    const getInventoryDataWithoutAttVariants = async (data) => {
        let body = {
            attributes: data,
        };

        await postMethodWithToken(urls.variants, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                getInventoryData(response.data)
                setInvAttributesData(response.data)
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const getInventoryData = async (invAttData) => {
        let storeId = getStoreId();
        let productId = getProductId();
        let moduleName = "inventory";

        setInventoryAttributesCount(invAttData.length)

        await getProductDetails(storeId, productId, moduleName).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const inventoryResults = response.data.response;
                const inventoryUploadedFalg = response.data.product_flag;

                if (inventoryResults && inventoryResults.length > 0 && inventoryResults[0].stock_record !== '' && inventoryResults[0].stock_record !== null) {
                    if (invAttData.length > 0) {

                        let inventoryDataArray = [];

                        invAttData.forEach((item, index) => {
                            let curIndex = ''
                            const itemKeys = Object.keys(item);

                            inventoryResults.forEach((value, i) => {
                                if (value.attributes.length > 0) {
                                    let getAttArray = {}
                                    value.attributes.forEach((x, j) => {
                                        if (x.value_text !== "color_code_swatch") {
                                            getAttArray[x.name] = x.value_text
                                        }
                                    })

                                    if (JSON.stringify(item) === JSON.stringify(getAttArray)) {
                                        curIndex = i;
                                    }
                                }
                            })

                            if (curIndex !== '') {

                                let getImageID = [];
                                let getImageFile = [];
                                if (inventoryResults[curIndex].images && inventoryResults[curIndex].images.length > 0) {
                                    inventoryResults[curIndex].images.forEach((img, v) => {
                                        let imageObject = {
                                            image_id: img.id,
                                            order_image: img.display_order,
                                        };
                                        getImageID.push(imageObject);

                                        let productImageObject = {
                                            file: img.original,
                                            id: img.id,
                                            order_image: img.display_order,
                                        };
                                        getImageFile.push(productImageObject);
                                    })
                                }

                                let getImagePickerID = [];
                                if (inventoryResults[curIndex].image_picker && inventoryResults[curIndex].image_picker.length > 0) {
                                    inventoryResults[curIndex].image_picker.forEach((img, v) => {

                                        let imageObject = {
                                            image_id: img.id,
                                            order_image: img.display_order,
                                        };
                                        getImagePickerID.push(imageObject);
                                        let productImageObject = {
                                            file: img.original,
                                            id: img.id,
                                            order_image: img.display_order,
                                            index: curIndex
                                        };
                                        imagePicker.push(productImageObject);
                                        // setImagePicker([productImageObject]);
                                    })
                                }
                                inventoryResults.length > 0 && inventoryResults.map((k, i) => {
                                    selectIndex.push(i)
                                })
                                let data = {
                                    id: inventoryResults[curIndex].id,
                                    location: inventoryResults[curIndex].location !== null && inventoryResults[curIndex].location,
                                    upc: inventoryResults[curIndex].upc !== "" && inventoryResults[curIndex].upc,
                                    partner_sku: inventoryResults[curIndex].stock_record.partner_sku !== null && inventoryResults[curIndex].stock_record.partner_sku,
                                    stock_qty: inventoryResults[curIndex].stock_record.num_in_stock,
                                    price: inventoryResults[curIndex].stock_record.price,
                                    low_stock_threshold: inventoryResults[curIndex].stock_record.low_stock_threshold,
                                    supplier: inventoryResults[curIndex].stock_record.supplier !== null && inventoryResults[curIndex].stock_record.supplier,
                                    invoice_cost: inventoryResults[curIndex].stock_record.invoice_cost,
                                    compare_at_price: inventoryResults[curIndex].compare_at_price,
                                    img_ids: getImageID,
                                    inventory_images: getImageFile,
                                    color_code: inventoryResults[curIndex].color_code !== null ? inventoryResults[curIndex].color_code : null,
                                    attributes: [item],
                                    tax_exempted: inventoryResults[curIndex].stock_record.tax_exempted,
                                    not_returnable: inventoryResults[curIndex].not_returnable,
                                    product_status_clearence: inventoryResults[curIndex].product_status_clearence,
                                    product_status_flag: inventoryResults[curIndex].product_status_flag,
                                    max_order_quantity_order_level: inventoryResults[curIndex].max_order_quantity_order_level !== null && inventoryResults[curIndex].max_order_quantity_order_level,
                                    max_order_quantity_account_level: inventoryResults[curIndex].max_order_quantity_account_level !== null && inventoryResults[curIndex].max_order_quantity_account_level,
                                    cancellation_policies: inventoryResults[curIndex].cancellation_policy !== null && inventoryResults[curIndex].cancellation_policy,
                                    varient_specification: inventoryResults[curIndex].varient_specification !== null && inventoryResults[curIndex].varient_specification,
                                    product_tags: inventoryResults[curIndex].product_tags,
                                    brand: '',
                                    image_picker: getImagePickerID,
                                    colorPickerDropdownValue: getImagePickerID.length > 0 ? 'image' : inventoryResults[curIndex].color_code !== null ? 'color' : '',
                                    is_variant: true,
                                    is_editable: true,
                                    order_status_flag: inventoryResults[curIndex].order_status_flag,
                                    manufactureName: inventoryResults[curIndex].manufactureName !== null ? inventoryResults[curIndex].manufactureName : "",
                                    manufactureAddress: inventoryResults[curIndex].manufactureAddress,
                                    countryOrigin: inventoryResults[curIndex].countryOrigin,
                                    soldBy: inventoryResults[curIndex].soldBy,
                                    tax_detail: {
                                        categoryCode: inventoryResults[curIndex].tax_data ? inventoryResults[curIndex].tax_data.categoryCode : '',
                                        categoryName: inventoryResults[curIndex].tax_data ? inventoryResults[curIndex].tax_data.categoryName : '',
                                    }
                                }

                                inventoryDataArray.unshift(data);

                            } else {
                                let data = {
                                    is_variant: true,
                                    location: '',
                                    upc: '',
                                    partner_sku: '',
                                    stock_qty: '',
                                    price: '',
                                    low_stock_threshold: '',
                                    supplier: '',
                                    invoice_cost: '',
                                    compare_at_price: '',
                                    img_ids: [],
                                    inventory_images: [],
                                    image_picker: [],
                                    color_code: '',
                                    attributes: [item],
                                    tax_exempted: false,
                                    not_returnable: false,
                                    product_status_clearence: false,
                                    product_status_flag: false,
                                    max_order_quantity_order_level: '',
                                    max_order_quantity_account_level: '',
                                    cancellation_policies: '',
                                    varient_specification: '',
                                    product_tags: '',
                                    brand: '',
                                    colorPickerDropdownValue: itemKeys.includes('color') ? 'color' : '',
                                    is_editable: false,
                                    order_status_flag: '',
                                    manufactureName: '',
                                    manufactureAddress: '',
                                    countryOrigin: '',
                                    soldBy: '',
                                    tax_detail: {
                                        categoryCode: "",
                                        categoryName: "",
                                    }
                                };

                                inventoryDataArray.unshift(data);
                            }
                        })

                        if (skipAttributeChecking === true) {
                            let inventoryUploadedDataArray = [];

                            inventoryDataArray.forEach((item, index) => {
                                if (item.upc && item.partner_sku) {
                                    inventoryUploadedDataArray.push(item);
                                }
                            })

                            setInventoryState(inventoryUploadedDataArray)
                        } else {
                            setInventoryState(inventoryDataArray)
                        }

                    } else {
                        let inventoryDataArray = [];
                        let curIndex = 0;

                        let getImageID = [];
                        let getImageFile = [];
                        if (inventoryResults[curIndex].images && inventoryResults[curIndex].images.length > 0) {
                            inventoryResults[curIndex].images.forEach((img, v) => {
                                let imageObject = {
                                    image_id: img.id,
                                    order_image: img.display_order,
                                };
                                getImageID.push(imageObject);

                                let productImageObject = {
                                    file: img.original,
                                    id: img.id,
                                    order_image: img.display_order,
                                };
                                getImageFile.push(productImageObject);
                            })
                        }

                        let getImagePickerID = [];

                        if (inventoryResults[curIndex].image_picker !== null && inventoryResults[curIndex].image_picker.length > 0) {
                            inventoryResults[curIndex].image_picker.forEach((img, v) => {

                                let imageObject = {
                                    image_id: img.id,
                                    order_image: img.display_order,
                                };
                                getImagePickerID.push(imageObject);
                                let productImageObject = {
                                    file: img.original,
                                    id: img.id,
                                    order_image: img.display_order,
                                    index: curIndex
                                };
                                imagePicker.push(productImageObject);
                                // setImagePicker([productImageObject]);
                            })
                        }

                        let data = {
                            id: inventoryResults[curIndex].id,
                            location: inventoryResults[curIndex].location !== null && inventoryResults[curIndex].location,
                            upc: inventoryResults[curIndex].upc !== "" && inventoryResults[curIndex].upc,
                            partner_sku: inventoryResults[curIndex].stock_record.partner_sku !== null && inventoryResults[curIndex].stock_record.partner_sku,
                            stock_qty: inventoryResults[curIndex].stock_record.num_in_stock,
                            price: inventoryResults[curIndex].stock_record.price,
                            low_stock_threshold: inventoryResults[curIndex].stock_record.low_stock_threshold,
                            supplier: inventoryResults[curIndex].stock_record.supplier !== null && inventoryResults[curIndex].stock_record.supplier,
                            invoice_cost: inventoryResults[curIndex].stock_record.invoice_cost,
                            compare_at_price: inventoryResults[curIndex].compare_at_price,
                            img_ids: getImageID,
                            inventory_images: getImageFile,
                            color_code: inventoryResults[curIndex].color_code !== null && inventoryResults[curIndex].color_code,
                            attributes: [],
                            tax_exempted: inventoryResults[curIndex].stock_record.tax_exempted,
                            not_returnable: inventoryResults[curIndex].not_returnable,
                            product_status_clearence: inventoryResults[curIndex].product_status_clearence,
                            product_status_flag: inventoryResults[curIndex].product_status_flag,
                            max_order_quantity_order_level: inventoryResults[curIndex].max_order_quantity_order_level !== null && inventoryResults[curIndex].max_order_quantity_order_level,
                            max_order_quantity_account_level: inventoryResults[curIndex].max_order_quantity_account_level !== null && inventoryResults[curIndex].max_order_quantity_account_level,
                            cancellation_policies: inventoryResults[curIndex].cancellation_policy !== null && inventoryResults[curIndex].cancellation_policy,
                            varient_specification: inventoryResults[curIndex].varient_specification !== null && inventoryResults[curIndex].varient_specification,
                            product_tags: inventoryResults[curIndex].product_tags,
                            brand: '',
                            image_picker: getImagePickerID,
                            colorPickerDropdownValue: '',
                            is_variant: false,
                            is_editable: true,
                            order_status_flag: inventoryResults[curIndex].order_status_flag,
                            manufactureName: inventoryResults[curIndex].manufactureName !== null ? inventoryResults[curIndex].manufactureName : "",
                            manufactureAddress: inventoryResults[curIndex].manufactureAddress !== null && inventoryResults[curIndex].manufactureAddress,
                            countryOrigin: inventoryResults[curIndex].countryOrigin !== null && inventoryResults[curIndex].countryOrigin,
                            soldBy: inventoryResults[curIndex].soldBy !== null && inventoryResults[curIndex].soldBy,
                            tax_detail: {
                                categoryCode: inventoryResults[curIndex].tax_data ? inventoryResults[curIndex].tax_data.categoryCode : '',
                                categoryName: inventoryResults[curIndex].tax_data ? inventoryResults[curIndex].tax_data.categoryName : '',
                            }
                        }

                        inventoryDataArray.unshift(data);

                        setInventoryState(inventoryDataArray)
                    }

                    setInventoryEditedFlag('editable')
                } else {
                    generateInventoryData();
                    setInventoryEditedFlag('added')
                }

                setLoader(true)
            }
        }).catch((error) => {
            console.log("API error", error);
        });
    }

    function generateInventoryData() {
        let inventoryDataArray = [];

        if (invAttributesData.length > 0) {
            setInventoryAttributesCount(invAttributesData.length)

            invAttributesData.forEach((item, index) => {
                const itemKeys = Object.keys(item);

                let data = {
                    is_variant: true,
                    location: '',
                    upc: '',
                    partner_sku: '',
                    stock_qty: '',
                    price: '',
                    low_stock_threshold: '',
                    supplier: '',
                    invoice_cost: '',
                    compare_at_price: '',
                    img_ids: [],
                    inventory_images: [],
                    image_picker: [],
                    color_code: '',
                    attributes: [item],
                    tax_exempted: false,
                    not_returnable: false,
                    product_status_clearence: false,
                    product_status_flag: false,
                    max_order_quantity_order_level: '',
                    max_order_quantity_account_level: '',
                    cancellation_policies: '',
                    varient_specification: '',
                    product_tags: '',
                    brand: '',
                    colorPickerDropdownValue: itemKeys.includes('color') ? 'color' : '',
                    is_editable: false,
                    order_status_flag: '',
                    manufactureName: '',
                    manufactureAddress: '',
                    countryOrigin: '',
                    soldBy: '',
                    tax_detail: {
                        categoryCode: "",
                        categoryName: "",
                    }
                };

                inventoryDataArray.unshift(data);
            })
        } else {
            let data = {
                is_variant: false,
                location: '',
                upc: '',
                partner_sku: '',
                stock_qty: '',
                price: '',
                low_stock_threshold: '',
                supplier: '',
                invoice_cost: '',
                compare_at_price: '',
                img_ids: [],
                inventory_images: [],
                image_picker: [],
                color_code: '',
                attributes: [],
                tax_exempted: false,
                not_returnable: false,
                product_status_clearence: false,
                product_status_flag: false,
                max_order_quantity_order_level: '',
                max_order_quantity_account_level: '',
                cancellation_policies: '',
                varient_specification: '',
                product_tags: '',
                brand: '',
                colorPickerDropdownValue: '',
                is_editable: false,
                order_status_flag: '',
                manufactureName: '',
                manufactureAddress: '',
                countryOrigin: '',
                soldBy: '',
                tax_detail: {
                    categoryCode: "",
                    categoryName: "",
                }
            };

            inventoryDataArray.unshift(data);
        }

        setInventoryState(inventoryDataArray)
        setLoader(true)
    }

    const getLocationList = async () => {
        let storeId = getStoreId();
        await getMethod(urls.locationList).then((response) => {
            if (response.status === 200) {
                if (response.data.data.length > 0) {
                    let locationListArray = [];
                    response.data.data.forEach((item, index) => {
                        let data = item.address + ', ' + item.city + ', ' + item.state + ', ' + item.country + ', ' + item.postal_code;
                        locationListArray.push(data)
                    })
                    setLocationList(locationListArray);
                }
            }
        });
    };

    const modalSelectColor = (index) => {
        setModalColorPickerFlag(true)
        setCurInventoryIndex(index)

    };

    const closeColorPicker = (index) => {
        setModalColorPickerFlag(false)
        setSwipeableFlag(false)
        selectIndex.splice(index, 1)

        // const list = [...inventoryState]
        // list[index]['color_code'] = ''
        // setInventoryState(list)
    };

    const handleSaveColor = (index) => {
        setModalColorPickerFlag(false)
        setSwipeableFlag(false)
        selectIndex.push(index)

        const list = [...inventoryState]

        if (list[curInventoryIndex].colorPickerDropdownValue !== '') {
            list.forEach((x, i) => {
                if (list[i].attributes[0].color === list[curInventoryIndex].attributes[0].color) {
                    // list[i]['color_code'] = list[curInventoryIndex].color_code
                }
            })
        }

        setInventoryState(list)
    };

    const { getRootProps: getStoreDocument, getInputProps: getStoreInputDocument, isDragActive: getStoreDocumentActive } = useDropzone({
        accept: " .jpeg, .jpg, .png",
        onDrop: (acceptedFile) => {
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    let inventoryImgWithId = inventoryState?.[curInventoryIndex]?.img_ids?.length > 0 ? inventoryState?.[curInventoryIndex]?.img_ids : [];
                    let inventoryImgWithFile = inventoryState?.[curInventoryIndex]?.inventory_images?.length > 0 ? inventoryState?.[curInventoryIndex]?.inventory_images : [];

                    response.data.map((item, index) => {
                        let imageObject = {
                            image_id: item.id,
                            order_image: inventoryState[curInventoryIndex].img_ids && inventoryState[curInventoryIndex].img_ids.length > 0 ? inventoryState[curInventoryIndex].img_ids.length + 1 : index + 1,
                        };
                        inventoryImgWithId.push(imageObject);

                        let productImageObject = {
                            file: item.file,
                            id: item.id,
                            order_image: inventoryState?.[curInventoryIndex]?.inventory_images && inventoryState?.[curInventoryIndex]?.inventory_images?.length > 0 ? inventoryState?.[curInventoryIndex]?.inventory_images?.length + 1 : index + 1,
                        };
                        inventoryImgWithFile.push(productImageObject);
                    });

                    const list = [...inventoryState]
                    list[curInventoryIndex]['img_ids'] = inventoryImgWithId
                    list[curInventoryIndex]['inventory_images'] = inventoryImgWithFile
                    setInventoryState(list)
                } else {
                    toast.error("Please upload the following file types: .jpeg, jpg, png")
                }
            })
        }
    })

    const handleColorPicker = (value, index) => {
        const list = [...inventoryState]
        list[index]['color_code'] = value.hex
        setInventoryState(list)
    }

    const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setImageUploadPercentage(percent);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/jpeg, image/jpg, image/png",
    });

    const dragUploadStart = (e, position) => {
        dragItem.current = position;
    }

    const dragUploadEnter = (e, position) => {
        dragOverItem.current = position;
    }

    const dropUploadEnd = (e) => {
        const list = [...inventoryState];

        const imageWithFile = list[curInventoryIndex].inventory_images;
        const imageWithId = list[curInventoryIndex].img_ids;

        const dragItemContentWithFile = imageWithFile[dragItem.current];
        const dragOverItemContentWithFile = imageWithFile[dragOverItem.current];

        const dragItemContentWithId = imageWithId[dragItem.current];
        const dragOverItemContentWithId = imageWithId[dragOverItem.current];

        imageWithFile.splice(dragItem.current, 1);
        imageWithFile.splice(dragOverItem.current, 0, dragItemContentWithFile);

        imageWithId.splice(dragItem.current, 1);
        imageWithId.splice(dragOverItem.current, 0, dragItemContentWithId);

        dragItem.current = null;
        dragOverItem.current = null;

        imageWithFile.forEach((x, i) => {
            imageWithFile[i]['order_image'] = i + 1;
        })

        imageWithId.forEach((x, i) => {
            imageWithId[i]['order_image'] = i + 1;
        })

        list[curInventoryIndex]['img_ids'] = imageWithId
        list[curInventoryIndex]['inventory_images'] = imageWithFile
        setInventoryState(list)
    }

    const handleDragEndImages = (result) => {
        if (!result.destination) {
            return;
        }

        const list = [...inventoryState];
        let imageWithFileArray = [];
        let imageWithIdArray = [];
        const itemsWithFile = Array.from(list[curInventoryIndex].img_ids);
        const [reorderedFile] = itemsWithFile.splice(result.source.index, 1);
        itemsWithFile.splice(result.destination.index, 0, reorderedFile);

        for (let k = 0; k < itemsWithFile.length; k++) {
            let changeOrder = { file: "", id: "", order: "" };
            changeOrder.file = itemsWithFile[k].file !== undefined ? itemsWithFile[k].file : itemsWithFile[k].original;
            changeOrder.id = itemsWithFile[k].id;
            changeOrder.order_image = k + 1;
            imageWithFileArray.push(changeOrder);

            let changeIdOrder = { image_id: "", order: "" };
            changeIdOrder.image_id = itemsWithFile[k].id;
            changeIdOrder.order_image = k + 1;
            imageWithIdArray.push(changeIdOrder);
        }

        list[curInventoryIndex]['img_ids'] = imageWithIdArray
        list[curInventoryIndex]['inventory_images'] = imageWithFileArray
        setInventoryState(list)
    };

    const handleDeleteImage = (index) => {
        const list = [...inventoryState];
        list[curInventoryIndex].img_ids.splice(index, 1);
        list[curInventoryIndex].inventory_images.splice(index, 1);

        if (list[curInventoryIndex].img_ids.length > 0) {
            list[curInventoryIndex].img_ids.forEach((x, i) => {
                if (list[curInventoryIndex].img_ids[i].order_image !== 1) {
                    list[curInventoryIndex].img_ids[i].order_image = list[curInventoryIndex].img_ids[i].order_image - 1;
                }
            });

            list[curInventoryIndex].inventory_images.forEach((x, i) => {
                if (list[curInventoryIndex].inventory_images[i].order_image !== 1) {
                    list[curInventoryIndex].inventory_images[i].order_image = list[curInventoryIndex].inventory_images[i].order_image - 1;
                }
            });
        }

        setInventoryState(list)
    };

    const handleCloseModal = () => {
        setClickItem(false)
        setImageModal(false)
    }

    const handleImage = (event, index, ind) => {
        setClickItem(ind)
        setModalIndex(index)
        setImageModal(true)
    }

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;

        if (name === 'tax_detail') {

            let taxValue = value.split('$#');

            const list = [...inventoryState]
            list[index][name] = {
                'categoryCode': taxValue[0],
                'categoryName': taxValue[1]
            }
            setInventoryState(list)
        } else if (name === 'stock_qty' || name === 'low_stock_threshold') {
            const list = [...inventoryState]
            if (value !== "") {
                list[index][name] = parseInt(value)
            } else {
                list[index][name] = (value)
            }
            setInventoryState(list)
        } else {
            const list = [...inventoryState]
            list[index][name] = value
            setInventoryState(list)
        }

        if (name === 'upc') {
            postUPCListCheck(value);
        }

        if (name === 'partner_sku') {
            postSKUListCheck(value);
        }

    };

    const handlePriceInputChange = (event, index) => {
        const { name, value } = event.target;

        if (value.length <= 5) {
            const list = [...inventoryState]
            list[index][name] = value
            setInventoryState(list)
        }
    }

    const handlePriceDecimalChange = (event, index) => {
        const { name, value } = event.target;

        if (value.includes('.')) {
            let splitValue = value.split('.');

            let decimalValue = '';
            if (splitValue[1].length === 1) {
                decimalValue = value + 0;
            } else {
                decimalValue = value;
            }

            const list = [...inventoryState]
            list[index][name] = decimalValue;
            setInventoryState(list)
        } else {
            const list = [...inventoryState]
            list[index][name] = parseInt(value).toFixed(2);
            setInventoryState(list)
        }
    }

    const handleCheckboxChange = (event, index) => {
        const { checked } = event.target;

        const list = [...inventoryState]
        list[index][event.target.name] = checked
        setInventoryState(list)
    };

    const handleEditorChange = (value, name, index) => {
        const list = [...inventoryState]
        list[index][name] = value
        setInventoryState(list)
    }

    const handleTagsFocus = () => {
        setTagsListIsOpen(true);
    };

    const handleTagsBlur = () => {
        setTagsListIsOpen(false);
    };

    const handleTagsChange = (event, index) => {

        const list = [...inventoryState]
        list[index]['product_tags'] = event
        setInventoryState(list);

        const array = [];
        event.map((k) => array.push(k.name || k.value))
        setSelectProductTag(array);
    }

    const handleTagsCreate = (event, index) => {
        const newValue = {
            name: event.toLowerCase(),
            id: Math.random(Math.random()),
        };

        const list = [...inventoryState]
        list[index]['product_tags'] = newValue
        setInventoryState(list)

        setTagsList([...tagsList, newValue]);
    }

    const handleSwitchChange = (val, name, index) => {
        const list = [...inventoryState]
        list[index][name] = val;

        setInventoryState(list)
    }

    const deleteInventoryConfirmation = (index) => {
        setModalDeleteInventoryFlag(true)
        setCurInventoryIndex(index)
    }

    const handleDeleteInventory = (index) => {
        const list = [...inventoryState]
        list.splice(index, 1);
        setInventoryState(list)
        setModalDeleteInventoryFlag(false)
        setSwipeableFlag(false)
    }

    const handleModalInventory = (index) => {
        setModalCurInventoryFlag(true)
        setCurInventoryIndex(index)
        setSwipeableFlag(true)
    }

    const onSubmitInventory = async (value) => {

        if (inventoryState.length > 0) {
            let inventoryError = '';
            inventoryState.forEach((data, i) => {

                if (!inventoryError) {
                    inventoryError = checkInventoryInfo(data);
                }
            })

            if (inventoryError) {
                toast.error(inventoryError)
            } else {
                inventoryState[0]["product_tags"] = selectProductTag;
                inventoryState[0]["location"] = inventoryState[0].location === "" ? locationList.length > 0 && locationList[0] : inventoryState[0].location;
                let inventoryBody = {
                    store_id: getStoreId(),
                    product_id: getProductId(),
                    inventory: inventoryState
                };

                if (getProductId() && ((getProductEditableFlag() !== 'added' && getInventoryEditedFlag() === 'editable') || (getProductEditableFlag() === 'added' && getInventoryEditedFlag() === 'editable'))) {

                    await putMethodWithToken(urls.aboutStore + getStoreId() + "/product/" + getProductId() + "/?module=" + value, inventoryBody).then((response) => {
                        if (response.status === 200 || response.status === 201) {
                            toast.success(response.data.success)
                            setInventoryEditedFlag('editable')

                            let backData = {
                                inventoryState: '',
                                is_submit: true,
                                attributesCount: 0,
                                is_added: true
                            }

                            inventoryData(backData)
                        } else {
                            toast.error(response.data.error)
                        }
                    }).catch((error) => {
                        console.log("error", error);
                    });

                } else {

                    await postMethodWithToken(urls.addProduct + value, inventoryBody).then((response) => {
                        if (response.status === 200 || response.status === 201) {
                            setInventoryEditedFlag('editable')

                            toast.success(response.data.success)
                            let backData = {
                                inventoryState: '',
                                is_submit: true,
                                attributesCount: 0,
                                is_added: true,
                            }

                            inventoryData(backData)
                        } else {
                            toast.error(response.data.error)
                        }
                    }).catch((error) => {
                        console.log("error", error);
                    });
                }
            }
        } else {
            let backData = {
                inventoryState: inventoryState,
                is_submit: true,
                attributesCount: InventoryAttributesCount,
                is_added: false,
            }

            inventoryData(backData)
        }
    }

    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
            setCurInventoryIndex(0);
        } else {
            setExpandIndex(index);
            setCurInventoryIndex(index)
        }
    };

    const taxjarCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = taxjarListData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setTaxjarList(results);
        } else {
            setTaxjarList(taxjarListData);
        }
    };

    const getCroppedImg = (image, crop, fileName) => {

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        var uri = canvas.toDataURL("image/jpeg", 1);

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                function (blob) {
                    if (!blob) {
                        console.log("Canvas is empty");
                        return;
                    }
                    blob.name = fileName;
                    blob.src = uri;
                    resolve(blob);
                },
                "image/jpeg",
                1
            );
        });
    };

    const onSelectFileProfile = (e, index) => {
        setpickerImageIndex(index);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setImageCrop(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setOpenImageCrop(true);
        } else {
            toast.error("ImageSizeError");
        }
    };


    const onImageLoad = (img) => {
        imgRefProfile.current = img.currentTarget
    }

    const imageCropConversionProfile = async () => {
        const cropedImage = await getCroppedImg(
            imgRefProfile.current,
            cropProfile,
            "newFile.jpeg"
        );
        setCompletedCrop(cropedImage);
        setCropFlag(true)
    };

    const handleProfile = async (e, inx) => {
        const imageFile = e;
        if (imageFile.size < 10000000) {
            const options = {
                maxSizeMB: 100,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(imageFile, options);
            let formdata = new FormData();
            formdata.append("file", compressedFile, compressedFile.name);

            postMethodWithTokenProgress(urls.fileUpload, formdata).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    selectIndex.push(inx)
                    response.data.map((item, index) => {

                        let imageObject = {
                            image_id: item.id,
                            order_image: inventoryState[curInventoryIndex].image_picker.length > 0 ? inventoryState[curInventoryIndex].image_picker.length + 1 : index + 1,
                        };

                        let productImageObject = {
                            file: item.file,
                            id: item.id,
                            order_image: inventoryState[curInventoryIndex].image_picker.length > 0 ? inventoryState[curInventoryIndex].image_picker.length + 1 : index + 1,
                            index: inx
                        };

                        let indx = 0;
                        imagePicker.map((k, i) => {
                            if (k.index === inx) {
                                indx = i;
                            }
                        })
                        const userExists = imagePicker.some(user => user.index === inx);
                        if (userExists) {
                            imagePicker.splice(indx, 1, productImageObject);
                        } else {
                            imagePicker.push(productImageObject);
                        }
                        // setImagePicker([productImageObject]);

                        const list = [...inventoryState]
                        list[curInventoryIndex]['image_picker'] = [imageObject]
                        setInventoryState(list)
                    });

                }
            });
        }

    };

    return (
        <div>
            {!loader ? (
                <SpinnerLoader />
            ) : (
                <>
                    {inventoryState.length > 0 && inventoryState.map((item, index) => {
                        return (
                            <ValidatorForm className={expandIndex === index ? "inventoryBox" : ""}>
                                <div className="row mt-3">
                                    <div className="col-12 d-flex" style={{ textTransform: "capitalize" }}>
                                        {item.attributes.length > 0 && Object.entries(item.attributes[0]).map(([key, value], kindex) => {
                                            return (
                                                <>
                                                    {kindex === 0 ?
                                                        <div style={{ marginBottom: "15px", color: item.color_code === "" || item?.color_code === "#ffffff" || item?.image_picker?.length > 0 || item.color_code === null ? "#203964" : "white", background: item.color_code ? item.color_code : "", padding: "0px 7px 0 7px" }}><b>{key} :</b>  {value}</div>
                                                        :
                                                        <div style={{ marginBottom: "15px", color: item.color_code === "" || item?.color_code === "#ffffff" || item?.image_picker?.length > 0 || item.color_code !== null ? "#203964" : "white", background: item.color_code ? item.color_code : "", padding: "0px 5px 0 0px" }}><b>, {key} :</b>  {value}</div>
                                                    }
                                                </>
                                            )
                                        })}
                                        <div style={{ height: "20px", width: "20px", marginLeft: "10px" }}>
                                            {imagePicker?.length > 0 && imagePicker.map((k, i) => {
                                                return (
                                                    <>
                                                        {k.index === index &&
                                                            <img src={k?.file} alt="image" style={{ height: "20px", width: "80px", objectFit: "cover" }} />
                                                        }
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>

                                    {expandIndex === index &&
                                        <div className="col-12 mb-4 d-flex">
                                            {item?.inventory_images?.length > 0 && item?.inventory_images?.map((item, ind) => {
                                                return (
                                                    <div className="storeImageBlock d-flex mr-3"
                                                        data-key={ind}
                                                        onDragStart={(e) => dragUploadStart(e, ind)}
                                                        onDragEnter={(e) => dragUploadEnter(e, ind)}
                                                        onDragEnd={dropUploadEnd}
                                                        key={ind}
                                                        draggable>
                                                        <div className="ml-2 mr-3">
                                                            <div className="deleteUploadedImage text-center" onClick={() => handleDeleteImage(ind)}>
                                                                <CloseIcon style={{ fontSize: "18px" }} />
                                                            </div>
                                                            {item?.file.includes('.mp4') ?
                                                                <>
                                                                    <video controls loop="true" autoplay="autoplay" src={item.file} className="imgTagBlock" style={{ objectFit: "cover", cursor: "pointer" }}
                                                                        onClick={(e) => handleImage(e, index, ind)} />
                                                                </>
                                                                :
                                                                <img
                                                                    src={item.file ? item.file : ''}
                                                                    alt=""
                                                                    className="imgTagBlock"
                                                                    style={{ objectFit: "cover", cursor: "pointer" }}
                                                                    onClick={(e) => handleImage(e, index, ind)}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            <div className="storeImageBlock d-flex">
                                                <div className="imageUpload" {...getStoreDocument()}>
                                                    <input {...getStoreInputDocument()} />
                                                    {getStoreDocumentActive ? (
                                                        <p>Drop the files here ...</p>
                                                    ) : (
                                                        <div className="uploadPhoto">
                                                            <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                                <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                <div className="uploadText">Images</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        // <div className="col-xl-12 col-lg-12 mb-3">

                                        //     <div className="row ml-0" >
                                        //         {item.inventory_images.length > 0 && item.inventory_images.map((item, ind) => {
                                        //             return (
                                        //                 <div className="storeImageBlock d-flex mr-3"
                                        //                     data-key={ind}
                                        //                     onDragStart={(e) => dragUploadStart(e, ind)}
                                        //                     onDragEnter={(e) => dragUploadEnter(e, ind)}
                                        //                     onDragEnd={dropUploadEnd}
                                        //                     key={ind}
                                        //                     draggable>
                                        //                     <div className="ml-2 mr-2">
                                        //                         <div className="deleteUploadedImage text-center" onClick={() => handleDeleteImage(ind)}>
                                        //                             <CloseIcon style={{ fontSize: "18px" }} />
                                        //                         </div>
                                        //                         <img
                                        //                             src={item.file ? item.file : ''}
                                        //                             alt=""
                                        //                             className="imgTagBlock"
                                        //                             style={{ objectFit: "cover", cursor: "pointer" }}
                                        //                             onClick={(e) => handleImage(e, index, ind)}
                                        //                         />
                                        //                     </div>
                                        //                 </div>
                                        //             );
                                        //         })}

                                        //         <div className={imageUploadPercentage === 0 ? "hideper mr-3" : "showper d-flex mr-3"}>
                                        //             <ProgressBar bgcolor="#233949" progress={imageUploadPercentage} height={6} />
                                        //         </div>

                                        //         <div className="storeImageBlock d-flex">
                                        //             <div className="imageUpload" {...getRootProps()}>
                                        //                 <input  {...getInputProps()} onChange={(e) => onSelectFileProfile(e, index)} />
                                        //                 {isDragActive ? (
                                        //                     <p>Drop the files here ...</p>
                                        //                 ) : (
                                        //                     <div className="uploadPhoto" onClick={() => setPhotoImage("photoIcon")}>
                                        //                         <div style={{ cursor: "pointer" }} className="uploadInfo">
                                        //                             <div className="uploadImage" ><img width="22" src={UploadPhotoIcon} /></div>
                                        //                             <div className="uploadText">Images</div>
                                        //                         </div>
                                        //                     </div>
                                        //                 )}
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                    }

                                    <div className="col-xl-4 col-lg-6 mb-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <FormControl variant="outlined">
                                                    <InputLabel id="location-select" required name="location">Location</InputLabel>
                                                    <Select
                                                        labelId="location-select"
                                                        name="location"
                                                        id="location-dropdown"
                                                        label="Location"
                                                        errorMessages={
                                                            ("Please Select Location")
                                                        }
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        value={item.location === "" ? locationList.length > 0 && locationList[0] : item.location}
                                                    >
                                                        {locationList.length > 0 && locationList.map((item) => {
                                                            return (
                                                                <MenuItem value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-2 col-lg-3 mb-3">
                                        <TextValidator
                                            required
                                            errorMessages={"Please Enter Invoice Cost"}
                                            validators={[
                                                "required",
                                            ]}
                                            onChange={(e) => handlePriceInputChange(e, index)}
                                            onBlur={(e) => handlePriceDecimalChange(e, index)}
                                            value={item.invoice_cost}
                                            type="number"
                                            variant="outlined"
                                            label="Invoice Cost"
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                            name="invoice_cost"
                                        >
                                        </TextValidator>
                                    </div>

                                    <div className="col-xl-2 col-lg-3 mb-3">
                                        <TextValidator
                                            required
                                            errorMessages={"Please Enter Sale Price"}
                                            validators={[
                                                "required",
                                            ]}
                                            onChange={(e) => handlePriceInputChange(e, index)}
                                            onBlur={(e) => handlePriceDecimalChange(e, index)}
                                            value={item.price}
                                            type="number"
                                            step=".01"
                                            variant="outlined"
                                            label="Sale Price"
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                            name="price"
                                        >
                                        </TextValidator>
                                    </div>

                                    <div className="col-xl-2 col-lg-3 mb-3">
                                        <TextValidator
                                            required
                                            errorMessages={"Please Enter List Price"}
                                            validators={[
                                                "required",
                                            ]}
                                            onChange={(e) => handlePriceInputChange(e, index)}
                                            onBlur={(e) => handlePriceDecimalChange(e, index)}
                                            value={item.compare_at_price}
                                            type="number"
                                            variant="outlined"
                                            label="List Price"
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                            name="compare_at_price"
                                        >
                                        </TextValidator>
                                    </div>

                                    <div className="col-xl-2 col-lg-3 mb-3 d-flex justify-content-center inventoryIcon" style={{ alignItems: "center" }}>
                                        <div className="mr-3">
                                            <InputLabel style={{ fontSize: "12px" }}>On Clearance</InputLabel>
                                            <Switch
                                                checked={item.product_status_clearence}
                                                className={(item.product_status_clearence) ? 'selected_sett' : 'unselected_sett'}
                                                onColor="#1D3762"
                                                offColor="#808080"
                                                onHandleColor="#F2F5F7"
                                                offHandleColor="#F2F5F7"
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                name="product_status_clearence"
                                                onChange={(e) => handleSwitchChange(e, 'product_status_clearence', index)}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="mr-3">
                                            <InputLabel style={{ fontSize: "12px" }}>Active Status</InputLabel>
                                            <Switch
                                                checked={item.product_status_flag}
                                                className={(item.product_status_flag) ? 'selected_sett' : 'unselected_sett'}
                                                onColor="#1D3762"
                                                offColor="#808080"
                                                onHandleColor="#F2F5F7"
                                                offHandleColor="#F2F5F7"
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                name="product_status_flag"
                                                onChange={(e) => handleSwitchChange(e, 'product_status_flag', index)}
                                            />
                                        </div>

                                        <div className="d-flex mr-3" style={{ cursor: "pointer" }}>
                                            {expandIndex !== index ? (
                                                <i style={{ fontSize: "28px", verticalAlign: "middle" }} className="fa fa-angle-down iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                            ) : expandIndex === index ? (
                                                <i style={{ fontSize: "28px", verticalAlign: "middle" }} className="fa fa-angle-up iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                            ) : null}
                                        </div>
                                        {item.order_status_flag !== true &&
                                            <div className="d-flex" style={{ cursor: "pointer" }}>
                                                <DeleteIcon fontSize="medium" onClick={() => deleteInventoryConfirmation(index)} style={{ cursor: "pointer", color: "red" }} />
                                            </div>
                                        }
                                    </div>
                                </div>

                                {expandIndex === index &&
                                    <>
                                        <div className="row">

                                            {item.attributes.length > 0 && Object.entries(item.attributes[0]).map(([key, value]) => {

                                                return (
                                                    <>
                                                        {key === 'color' &&
                                                            <>
                                                                <div className="col-xl-2 col-lg-3 mb-3">
                                                                    <FormControl variant="outlined">
                                                                        <InputLabel required id="picker-select">{"Color/Image"}</InputLabel>
                                                                        <Select
                                                                            labelId="picker-select"
                                                                            id="picker-dropdown"
                                                                            name="colorPickerDropdownValue"
                                                                            onChange={(e) => handleInputChange(e, index)}
                                                                            value={item.colorPickerDropdownValue}
                                                                            label="Picker"
                                                                            errorMessages={["Please Select Color Picker"]}
                                                                            validators={[
                                                                                "required",
                                                                            ]}
                                                                            disabled={selectIndex.includes(index) ? true : false}
                                                                        >
                                                                            {pickerList.map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item.value}>
                                                                                        {item.label}
                                                                                    </MenuItem>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>

                                                                {item.colorPickerDropdownValue === 'color' &&
                                                                    <div className="col-xl-2 col-lg-2 mb-3">
                                                                        <div className="colorPickerDiv"
                                                                            style={{ backgroundColor: item.color_code ? item.color_code : "#FFFFFF" }}
                                                                            onClick={() => modalSelectColor(index)}
                                                                        >
                                                                            <div className="colorPickerDivDiv" style={{ backgroundColor: item.color_code ? item.color_code : "#FFFFFF" }}>{"Pick Color"}</div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {item.colorPickerDropdownValue === 'image' &&
                                                                    <div className="col-xl-2 col-lg-2 mb-3">
                                                                        <div className="colorPickerDiv">
                                                                            <div className="colorPickerDivDiv" onClick={() => setPhotoImage(false)}>
                                                                                <div className="imageUpload" {...getRootProps()} >
                                                                                    <input {...getInputProps()} onChange={(e) => onSelectFileProfile(e, index)} />
                                                                                    {isDragActive ? (
                                                                                        <p>Drop the files here ...</p>
                                                                                    ) : (

                                                                                        item.image_picker.length > 0 ? imagePicker && imagePicker.length > 0 && imagePicker.map((k, i) => (
                                                                                            index === k.index && (k?.file ?
                                                                                                <div style={{ height: "20px", width: "20px" }}>
                                                                                                    <img src={k.file ? k.file : ''} alt="image" style={{ height: "25px", width: "100px", objectFit: "cover" }} />
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{ cursor: "pointer" }} className="uploadInfo">UploadImage</div>
                                                                                            )

                                                                                        ))
                                                                                            : <div style={{ cursor: "pointer" }} className="uploadInfo">UploadImage</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })}

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please Enter Upc Number"}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item.upc}
                                                    inputProps={{ maxLength: 20 }}
                                                    variant="outlined"
                                                    label="UPC"
                                                    name="upc"
                                                    disabled={item.order_status_flag ? true : false}
                                                >
                                                </TextValidator>
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please Enter Sku Number"}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item.partner_sku}
                                                    inputProps={{ maxLength: 20 }}
                                                    variant="outlined"
                                                    label="SKU"
                                                    name="partner_sku"
                                                    disabled={item.order_status_flag ? true : false}
                                                >
                                                </TextValidator>
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please Enter Stock Qty"}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item.stock_qty}
                                                    inputProps={{ min: 0 }}
                                                    variant="outlined"
                                                    label="Stock Qty."
                                                    name="stock_qty"
                                                    type="number"
                                                >
                                                </TextValidator>
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please Enter Inventory Threshold"}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item.low_stock_threshold}
                                                    inputProps={{ min: 0 }}
                                                    variant="outlined"
                                                    label="Inv. Threshold"
                                                    name="low_stock_threshold"
                                                    type="number"
                                                >
                                                </TextValidator>
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item.max_order_quantity_order_level}
                                                    type="number"
                                                    variant="outlined"
                                                    label="Max. Order Qty (Order)"
                                                    name="max_order_quantity_order_level"
                                                    inputProps={{ min: 0 }}
                                                />
                                            </div>
                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item.max_order_quantity_account_level}
                                                    type="number"
                                                    variant="outlined"
                                                    label="Max. Order Qty (Account)"
                                                    name="max_order_quantity_account_level"
                                                    inputProps={{ min: 0 }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-xl-4 col-lg-4 mb-3">
                                                <FormControl variant="outlined">
                                                    <InputLabel required classid="category-select">Tax Category</InputLabel>
                                                    <Select
                                                        MenuProps={{ autoFocus: false }}
                                                        labelId="Product-category"
                                                        id="Product-category-dropdown"
                                                        value={item.tax_detail.categoryCode !== "" ? item.tax_detail.categoryCode + "$#" + item.tax_detail.categoryName : ""}
                                                        defaultValue={item.tax_detail.categoryCode !== "" ? item.tax_detail.categoryCode + "$#" + item.tax_detail.categoryName : ""}
                                                        label="Tax Category"
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        onClose={() => setTaxjarList(taxjarListData)}
                                                        name="tax_detail"
                                                    >
                                                        <ListSubheader>
                                                            <TextField
                                                                size="small"
                                                                autoFocus
                                                                placeholder="Type to search..."
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SearchIcon />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                onChange={taxjarCategoryFilter}
                                                                onKeyDown={(e) => {
                                                                    if (e.key !== "Escape") {
                                                                        e.stopPropagation();
                                                                    }
                                                                }}
                                                            />
                                                        </ListSubheader>
                                                        {taxjarList.length > 0 && taxjarList.map((item) => {
                                                            return (
                                                                <MenuItem key={item.name} value={item.product_tax_code + "$#" + item.name}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={(item.manufactureName && item.manufactureName != null) ? item.manufactureName : ''}
                                                    type="text"
                                                    variant="outlined"
                                                    label="Manufacture Name"
                                                    name="manufactureName"
                                                />
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={(item.manufactureAddress && item.manufactureAddress != null) ? item.manufactureAddress : ''}
                                                    type="text"
                                                    variant="outlined"
                                                    label="Manufacture Address"
                                                    name="manufactureAddress"
                                                />
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <FormControl variant="outlined">
                                                    <InputLabel id="country-select" name="Country Origin" >Country Origin</InputLabel>
                                                    <Select
                                                        labelId="country-select"
                                                        name="countryOrigin"
                                                        id="location-dropdown"
                                                        label="Country Origin"
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={item.countryOrigin}
                                                    >
                                                        {countryList.length > 0 && countryList.map((item) => {
                                                            return (
                                                                <MenuItem value={item.iso_3166_1_a3}>
                                                                    {item.printable_name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="col-xl-2 col-lg-3 mb-3">
                                                <TextValidator
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={(item.soldBy && item.soldBy != null) ? item.soldBy : ''}
                                                    type="text"
                                                    variant="outlined"
                                                    label="Sold By"
                                                    name="soldBy"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-xl-3 mb-3">
                                                                <div className="d-flex">
                                                                    <InputLabel style={{ fontSize: "12px" }} className="mt-2 mr-3 tax_exempted">Tax exempted</InputLabel>
                                                                    <Switch
                                                                        checked={item.tax_exempted}
                                                                        className={(item.tax_exempted) ? 'selected_sett' : 'unselected_sett'}
                                                                        onColor="#1D3762"
                                                                        offColor="#808080"
                                                                        onHandleColor="#F2F5F7"
                                                                        offHandleColor="#F2F5F7"
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        name="tax_exempted"
                                                                        onChange={(e) => handleSwitchChange(e, 'tax_exempted', index)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-xl-3 mb-3">
                                                                <div className="d-flex">
                                                                    <InputLabel style={{ fontSize: "12px" }} className="mt-2 mr-3 tax_exempted">Not returnable</InputLabel>
                                                                    <Switch
                                                                        checked={item.not_returnable}
                                                                        className={(item.not_returnable) ? 'selected_sett' : 'unselected_sett'}
                                                                        onColor="#1D3762"
                                                                        offColor="#808080"
                                                                        onHandleColor="#F2F5F7"
                                                                        offHandleColor="#F2F5F7"
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        name="not_returnable"
                                                                        onChange={(e) => handleSwitchChange(e, 'not_returnable', index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6 mb-3">
                                                <CreatableSelect
                                                    isMulti
                                                    className="customreact-select"
                                                    style={{ backgroundColor: "#ECECEC" }}
                                                    options={tagsList}
                                                    onChange={(e) => handleTagsChange(e, index)}
                                                    menuIsOpen={tagsListIsOpen}
                                                    onFocus={handleTagsFocus}
                                                    onBlur={handleTagsBlur}
                                                    getOptionLabel={(option) => option.name || option.label}
                                                    getOptionValue={(option) => option.slug || option.name}
                                                    // onCreateOption={(e) => handleTagsCreate(e, index)}
                                                    value={item.product_tags}
                                                    name="product_tags"
                                                    placeholder="Add Tag"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="joditEdit col-6">
                                                <div className="mb-2 f-600">Product Warranty Details</div>
                                                <JoditEditor
                                                    ref={editor}
                                                    config={inventorySpecificationsConfig}
                                                    value={(item.varient_specification && item.varient_specification !== null) ? item.varient_specification.toString() : ''}
                                                    onBlur={newContent => handleEditorChange(newContent, 'varient_specification', index)}
                                                    onChange={newContent => { }}
                                                />
                                            </div>
                                            <div className="joditEdit col-6">
                                                <div className="mb-2 f-600">Product Return Policy</div>
                                                <JoditEditor
                                                    ref={editor}
                                                    config={inventoryCancellationPolicy}
                                                    value={(item.cancellation_policies && item.cancellation_policies !== null) ? item.cancellation_policies.toString() : ''}
                                                    onBlur={newContent => handleEditorChange(newContent, 'cancellation_policies', index)}
                                                    onChange={newContent => { }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            </ValidatorForm>
                        )
                    })}
                    <OnHoldModal isOnHold={modalMe} />

                    <div className="row mr-0">
                        <div className="col-12 mt-3 d-flex justify-content-end">
                            <Button onClick={() => onSubmitInventory("inventory")} name="Save" className="btn btn-primary mb-5" />
                        </div>
                    </div>

                    <Modal isOpen={modalColorPickerFlag} style={colorPickerModalStyle} overlayClassName="myoverlay" closeTimeoutMS={500}>
                        <div className="row">
                            <div className="col-12 inventoryColorPicker">
                                <SketchPicker color={inventoryState[curInventoryIndex] ? inventoryState[curInventoryIndex].color_code : ""} onChangeComplete={(val) => handleColorPicker(val, curInventoryIndex)} />
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-3">
                                <Button type="onSubmit" onClick={() => handleSaveColor(curInventoryIndex)} name="Save" className="btn btn-primary mr-3" />
                                <Button type="onSubmit" onClick={() => closeColorPicker(curInventoryIndex)} name="Cancel" className="btn btn-cancle" />
                            </div>
                        </div>
                    </Modal>

                    <Modal isOpen={openImageCrop} style={imageModalStyle} overlayClassName="myoverlay" effect="fadeInUp" onClickAway={() => setOpenImageCrop(false)}>
                        <div style={imageModalStyle} overlayClassName="myoverlay">
                            <div style={{ marginTop: 20, marginBottom: 10, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", }} >
                                <p style={{ marginTop: 0, fontSize: 25, fontWeight: "bold", textAlign: "center", alignItems: "center", display: "flex" }}>cropImage</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <div onClick={() => setOpenImageCrop(false)}
                                        style={{
                                            width: 173, height: 50, background: "#E73D3B", display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff",
                                            fontWeight: "bold", cursor: "pointer", marginRight: 20
                                        }}
                                    >
                                        Cancel
                                    </div>

                                    {completedCrop && cropFlag ? (
                                        <div className="button_center">
                                            <div onClick={() => { setOpenImageCrop(false); handleProfile(completedCrop, pickerImageIndex); }}
                                                style={{
                                                    width: 173, height: 50, background: "#E73D3B", display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff",
                                                    fontWeight: "bold", cursor: "pointer",
                                                }}
                                            >Save</div>
                                        </div>
                                    ) : (
                                        <div className="button_center">
                                            <div style={{
                                                width: 173, height: 50, background: "#a3a3a3", display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff",
                                                fontWeight: "bold", cursor: "pointer",
                                            }}
                                            >Save</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <ReactCrop
                                crop={cropProfile}
                                onChange={(c) => setCropProfile(c)}
                                onComplete={(c) => imageCropConversionProfile(c)}
                            >
                                <img src={imageCrop} onLoad={onImageLoad} />
                            </ReactCrop>

                        </div>
                    </Modal>

                    <Modal isOpen={imageModal} style={imageModalProfileStyle} overlayClassName="myoverlay" closeTimeoutMS={500}>
                        {inventoryState.length > 0 && inventoryState.map((item, index) => (
                            modalIndex === index &&
                            <div className="col-xl-12 col-lg-12 ">
                                <div className="row">
                                    {item.inventory_images.length > 0 && item.inventory_images.map((item, index) => (
                                        <>
                                            {(clickItem === index) ?
                                                <>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="deleteModalImage text-center" onClick={() => handleCloseModal()}>
                                                            <CloseIcon style={{ fontSize: "35px" }} />
                                                        </div>
                                                        <img
                                                            src={item.file ? item.file : ''}
                                                            alt=""
                                                            // className="imgTagBlock"
                                                            style={{ width: "23vw", height: "22vh" }}
                                                        />
                                                    </div>
                                                </>
                                                : <></>
                                            }
                                        </>

                                    ))}

                                </div>
                            </div>


                        ))}
                    </Modal>

                    <Modal isOpen={modalDeleteInventoryFlag} style={deleteModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                        <div className="mt-4 mb-4">
                            <div className="row">
                                <div className="col-12 text-center">Are you sure you want to delete this product variant</div>
                                <div className="col-12 mt-4  d-flex justify-content-center">
                                    <Button
                                        type="onSubmit"
                                        onClick={() => {
                                            setModalDeleteInventoryFlag(false)
                                            setSwipeableFlag(false)
                                        }}
                                        name="Cancel"
                                        className="btn btn-sm btn-cancle mr-3"
                                    />
                                    <Button
                                        type="onSubmit"
                                        onClick={() => { handleDeleteInventory(curInventoryIndex) }}
                                        name="Delete"
                                        className="btn btn-sm btn-primary"
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            )
            }
        </div >
    );
}
export default InventorySection;
