import React, { useEffect } from "react";
import "./App.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Routes from "./routes/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./firebase/firebase";
import { setLatitude, setLongitude } from "./utils/common";
window.$productName = "";
 
function App() {
    useEffect(() => {
        findMe();
    }, []);
  
    const findMe = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLongitude(position.coords.longitude);
            setLatitude(position.coords.latitude);
        });
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <Routes />
        </React.Fragment>
    );
}

export default App;
