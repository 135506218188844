import React,{useEffect, useState}  from "react";
import "./banner.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getMethod } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json"
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";

function Banner(props) {
    const [modalMe, setModalMe] = useState(false)

    useEffect(()=>{
        getSteps()
    },[])
    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    }

    return(
        <div className={props.menu ? "screenLeftMargin w-100 mt-3" :"screenLeftMarginResponse w-100 mt-3"}>
            <div className="row ml-3 mr-3 mobileResponsive">
                <h1 className="col-12 heading-title">Banner(0/2)</h1>
                <OnHoldModal isOnHold={modalMe} />
            </div>
        </div>
    )
}
export default  Banner;