import React from 'react';
import './previousOrders.scss';
import NoImage from "../../../../assets/images/storelisting.png";
import CheckINDb from "../../../../assets/images/orderComplete.png";

const PreviousOrders = () => {
    return (
        <div className="row ml-5 mr-5 mt-3 mobileResponsive">
            <div className='col-12'>
                <div className='previous-order-box'>
                    <div className='order-his-text ml-3 pt-3'>Order History</div>
                    <table className="OrderSection-pre">
                        <tr >
                            <td>
                                <img src={NoImage}
                                    className='mt-3 pr-2'
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                    }}
                                />
                                Art Bookstore
                            </td>
                            <td>18-C Ring Road, California</td>
                            <td>
                                Tuesday, 18 Feb 2020
                            </td>
                            <td>
                            <img src={CheckINDb} style={{width: "15px", height: "15px", marginRight : "1rem"}}/>
                                Check In</td>
                        </tr>
                        <tr >
                            <td>
                                <img src={NoImage}
                                    className='mt-3 pr-2'
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                    }}
                                />
                                Cross Road 
                            </td>
                            <td>18-C Ring Road, California</td>
                            <td>
                                Tuesday, 18 Feb 2020
                            </td>
                            <td>
                            <img src={CheckINDb} style={{width: "15px", height: "15px", marginRight : "1rem"}}/>
                                Check In</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default PreviousOrders;
