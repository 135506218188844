import React, { useState } from "react";
import "./onHoldStore.scss";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../../../../components/button/button";
import urls from "../../../../../../utils/services/apiUrl.json";
import { postMethodWithToken, deleteMethodWithToken} from "../../../../../../utils/services/api";
import Modal from "react-modal";
import { toast } from "react-toastify";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StorefrontIcon from '@material-ui/icons/Storefront';

const OnHoldStore = () => {
    const history = useHistory();
    const location = useLocation();
    const [onHoldDetails, setOnHoldDetails] = useState(location.state.item);
    const [confirmation, setConfirmation] = useState(false);

    const gotoBack = async () => {
        history.goBack()
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: "25vw",
            height: "18vh",
        },
    };

    const backToApproved = async () => {
        let body = {
            shop_id: onHoldDetails.id,
        };
        await postMethodWithToken(urls.approve + "in_approved", body).then((response) => {
            if (response.status === 200) {
                history.push("/stores");
            }
        });
    };

    const cancelRequest = async () => {
        setConfirmation(false);
        await deleteMethodWithToken(urls.deleteStore + onHoldDetails.id + "/").then((response) => {
            if (response.status === 200) {
                history.push("/stores");
                toast.success(response.data.response);
            } else {
                toast.error("Something Went Wrong");
            }
        });
    };

    return (
        <div className="w-100 screenLeftMargin">
            <div className="row mt-3 pl-3 ml-3 mr-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <h1 className="heading-title">
                        <StorefrontIcon fontSize="large"/> On Hold Stores
                    </h1>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <Button onClick={() => backToApproved()} className="btn btn-primary mr-3" name="Approve"></Button>
                    <Button onClick={() => setConfirmation(true)} className="btn btn-cancle" name="Delete"></Button>
                </div>
            </div>

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <div className="reviewBox">
                        <div className="row ">
                            <div className="col-4 mt-4 pl-5">
                                <h3 className="h6-label">Shop Details</h3>
                                <div>
                                    <p className="h2-label mb-2 mt-3">
                                        {onHoldDetails.store_company_name}
                                    </p>
                                    <p className="h2-label">
                                        ID: <span>{onHoldDetails.id}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-4 mt-4">
                                <h3 className="h6-label">Owner Details</h3>
                                <p className="h2-label mt-3">{onHoldDetails.owner_name}</p>
                            </div>

                            <div className="col-4 mt-4 pr-5">
                                <h3 className="h6-label">Contact</h3>
                                <p className="mb-1 mt-3">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {onHoldDetails.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {onHoldDetails.country_code}
                                    {onHoldDetails.phone_number}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Are you sure you want to delete this store?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmation(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3"/>
                            <Button type="onSubmit" onClick={() => {cancelRequest();}} name="Delete" className="btn btn-primary btn-sm"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OnHoldStore;
