import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getToken = () => {
    return sessionStorage.getItem("token");
};

export const removeUserSession = () => {
    sessionStorage.removeItem("token");
    cookies.remove("token");
};

export const setUserOnBoarding = (status) => {
    sessionStorage.setItem("onBoardStatus", status);
};

export const getUserOnBoarding = () => {
    return sessionStorage.getItem("onBoardStatus");
};

export const setUserSession = (token, user) => {
    sessionStorage.setItem("token", token);
};

export const setStoreId = (token) => {
    sessionStorage.setItem("storeId", token);
};

export const getStoreId = () => {
    return sessionStorage.getItem("storeId") || null;
};

export const setProductId = (token) => {
    sessionStorage.setItem("productId", token);
};

export const setProductEditableFlag = (token) => {
    sessionStorage.setItem("productEditable", token);
};

export const setInventoryEditedFlag = (token) => {
    sessionStorage.setItem("inventoryEditable", token);
};

export const setAddonsEditedFlag = (token) => {
    sessionStorage.setItem("addonsEditable", token);
};

export const setShippingEditedFlag = (token) => {
    sessionStorage.setItem("shippingEditable", token);
};

export const setInnerProductId = (token) => {
    sessionStorage.setItem("innerProductId", token);
};

export const setMethod = (method) => {
    sessionStorage.setItem("method", method);
};

export const getMethodToUse = () => {
    return sessionStorage.getItem("method") || null;
};

export const getProductId = () => {
    return sessionStorage.getItem("productId") || null;
};

export const getProductEditableFlag = (token) => {
    return sessionStorage.getItem("productEditable");
};

export const getInventoryEditedFlag = (token) => {
    return sessionStorage.getItem("inventoryEditable");
};

export const getAddonsEditedFlag = (token) => {
    return sessionStorage.getItem("addonsEditable");
};

export const getShippingEditedFlag = (token) => {
    return sessionStorage.getItem("shippingEditable");
};

export const getInnerProductId = () => {
    return sessionStorage.getItem("innerProductId") || null;
};

export const setFinancialId = (token) => {
    sessionStorage.setItem("financialId", token);
};

export const getFinancialId = () => {
    return sessionStorage.getItem("financialId") || null;
};

export const setLocation = (loc) => {
    sessionStorage.setItem("locationStored", loc);
};

export const getLocation = () => {
    return sessionStorage.getItem("locationStored") || null;
};

export const setUserRole = (role) => {
    sessionStorage.setItem("role", role);
};

export const getUserRole = () => {
    return sessionStorage.getItem("role");
};

export const setStripeToken = (token) => {
    sessionStorage.setItem("cardGeneratedToken", token);
};

export const getStripeToken = () => {
    return sessionStorage.getItem("cardGeneratedToken") || null;
};

export const setAboutStoreData=(data)=>{
    sessionStorage.setItem("aboutStoreToPrefillAddress",data)
};

export const getAboutStoreData=()=>{
    return sessionStorage.getItem("aboutStoreToPrefillAddress")||null;
};

export const setLatitude=(data)=>{
    sessionStorage.setItem("Latitude",data)
};

export const getLatitude=()=>{
    return sessionStorage.getItem("Latitude")||null;
};

export const setLongitude=(data)=>{
    sessionStorage.setItem("Longitude",data)
};

export const getLongitude=()=>{
    return sessionStorage.getItem("Longitude")||null;
};
