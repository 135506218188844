import React from "react";
import LinkBased from "./component/linkBased";
import AssociationBased from "./component/associationBased";
import CategoryBased from "./component/categoryBased";
import { useLocation } from "react-router-dom";

const CampaignDetails = () => {
    let location = useLocation();

    return (
        <div className="screenLeftMargin w-100 mt-3">
            { location.type == "Link" ? (
                <LinkBased id={location.id} /> 
            ) : location.type == "Category" ? (
                <CategoryBased id={location.id} /> 
            ) : ( 
                <AssociationBased id={location.id} />
            )}
        </div>
    );
};
export default CampaignDetails;
