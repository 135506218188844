import React, { useState } from "react";
import "./FinancialInfo.scss";
import { getYear } from "date-fns";

const FinancialInfo = () => {
    let financialDetail = window.$ReviewStoreDetails;
    let financialDetails =
        window.$ReviewStoreDetails.store_detail[0].financial_info;

    const getAddress = () => {
        if (financialDetail.store_detail !== undefined) {
            let address = financialDetail.store_detail[0].business_address.unit + " " + financialDetail.store_detail[0].business_address.address + " " + financialDetail.store_detail[0].business_address.city + " " + financialDetail.store_detail[0].business_address.state + " " + financialDetail.store_detail[0].business_address.country + " " + financialDetail.store_detail[0].business_address.postal_code;
            return address;
        }
    };

    const getYear = () => {
        let financialYear = financialDetails.card_detail[0].exp_year;
        let requiredYear = JSON.stringify(financialYear).substring(2, 4);
        return requiredYear;
    };

    return (
        <div className="">
            <div className="FinancialBox mt-3 mb-5">
                <div className="d-flex">
                    <div className="col-6">
                        <div className="h5-label mb-2 pt-3 pl-4">Store Details</div>
                        <div className="h6-label pl-4">
                            Full Name
                            <span className="leadBox-details pl-4 ml-2">
                                : {financialDetails.payment_info.full_name}
                            </span>
                        </div>
                        <div className="h6-label pl-4">
                            Bank Name
                            <span className="leadBox-details pl-3 ml-1">
                                : {financialDetails.payment_info.bank_name}
                            </span>
                        </div>
                        <div className="h6-label pl-4">
                            {financialDetails.payment_info.transit_number !== "" ? "Transit No." : "Routing No."}
                            <span className="leadBox-details pl-3 ml-2">
                                : {financialDetails.payment_info.transit_number !== "" ? financialDetails.payment_info.transit_number : financialDetails.payment_info.routing_number}
                            </span>
                        </div>
                        <div className="h6-label pl-4">
                            Account No.
                            <span className="leadBox-details pl-3">
                                : {financialDetails.payment_info.account_number}
                            </span>
                        </div>
                        <div className="h6-label pl-4">
                            Country
                            <span className="leadBox-details pl-5">
                                : {financialDetail.store_detail[0].business_address.country}
                            </span>
                        </div>
                        <div className="h5-label mt-3 pl-4">Accepted Mode of Payment</div>
                        <div className="pl-4 pt-2 leadBox-details">
                            {financialDetails.payment_mode !== null ? (
                                <span>
                                    <img src={financialDetails.payment_mode.active_image} alt="" />
                                    <span> </span>
                                    {financialDetails.payment_mode.name}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="h5-label mb-2 pt-3">Billing Information</div>
                        <div className="h6-label">
                            Name on Card
                            <span className="leadBox-details pl-4">: {financialDetails.payment_info.full_name}</span>
                        </div>
                        <div className="h6-label">
                            Card No.
                            <span className="leadBox-details pl-5 ml-3">
                                : **** **** ****{" "}
                            </span>
                        </div>
                        <div className="h6-label">
                            MM\YY
                            <span className="leadBox-details pl-5 ml-4">
                                :  {financialDetails.card_detail !== null && financialDetails.card_detail && financialDetails.card_detail[0].exp_month / getYear()}
                            </span>
                        </div>
                        <div className="h6-label ">
                            CVV <span className="leadBox-details pl-5 ml-5">: *** </span>
                        </div>
                        <div className="h5-label mt-3 pt-4"> Billing Address</div>
                        <p className="leadBox-details w-50">{getAddress()}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinancialInfo;
