import React from 'react';
import './OpeningDays.scss';
import Check from '../../../../../../../../../assets/images/checkDB.png';
import Timepicker from '../../../../../../../../../components/timepicker/Timepicker';

const OpeningDays = () => {
   
    return (
        <div className=''>
            <div className='FinancialBox mt-3'>
                <div className='h5-label pt-3 pl-4'>Opening Days</div>
                <div className='mt-3'>
                    <span className="pl-4 leadBox"><img alt="" src={Check} /> <span className='ml-3 '>  </span>Monday</span>
                </div>
                <hr className='mt-3' />
                <div className='d-flex mt-3 ml-4'>
                    <div className='col-3'>
                        <div className='h6-label'>Start of Service</div>
                        <div className='leadBox-details'>Morning</div>
                        <Timepicker />
                    </div>
                    <div className='col-3'>
                        <div className='h6-label'>End of Service</div>
                        <div className='leadBox-details'>Morning</div>
                        <Timepicker />
                    </div>
                    <div className='col-3'>
                        <div className='h6-label'>Start of Service</div>
                        <div className='leadBox-details'>Evening</div>
                        <Timepicker />
                    </div>
                    <div className='col-3'>
                        <div className='h6-label'>End of Service</div>
                        <div className='leadBox-details'>Evening</div>
                        <Timepicker />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpeningDays;
