import React, { useEffect, useState } from "react";
import "./detailsTab.scss";
import TextField from "@material-ui/core/TextField";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardTimePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Row, Col } from "react-bootstrap";

function DetailSection({ showListing, getCollectionName,getCollectionDescription }) {

    const [selectedDate, handleDateChange] = useState(new Date());
    const [value, setValue] = useState({
        collectionName: showListing ? showListing.collectionName : "",
        description: showListing ? showListing.collectionDescription : ""
    })

    const handleCollectionName = (e) => {
        setValue((value) => ({
            ...value,
            [e.target.name]: e.target.value,
        }))
        getCollectionName(e.target.value);
    }

    const handleCollectionDescription = (e) => {
        setValue((value) => ({
            ...value,
            [e.target.name]: e.target.value,
        }))
        getCollectionDescription(e.target.value);
    }

    return (

        <React.Fragment >

            <div className="mt-2">
                <TextField
                    label="Collection Name"
                    name="collectionName"
                    value={value.collectionName}
                    variant="outlined"
                    onChange={(e) => handleCollectionName(e)}
                />
            </div>

            <div className="textFieldDetailSection mt-3">
                <textarea
                    rows="8"
                    placeholder="Description"
                    className="description"
                    value={value.description}
                    name="description"
                    onChange={(e) => handleCollectionDescription(e)}
                >
                </textarea>
            </div>

            {/* <Row className="textFieldDetailDateSection mt-3" >

                <Col>
                    <Row>
                        <Col>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    InputProps={{ disableUnderline: true }}
                                    className="TextFieldDate30"
                                    // id="date-detail"
                                    // variant="filled"
                                    // label="Start Date"
                                    placeholder="Start Date"
                                    value={selectedDate}
                                    onChange={date => handleDateChange(date)}
                                    format="yyyy/MM/dd"
                                />
                            </MuiPickersUtilsProvider>
                        </Col>

                        <Col>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    InputProps={{ disableUnderline: true }}
                                    className="TextFieldDate30"
                                    // id="date-detail"
                                    // variant="filled"
                                    // label="Start Time"
                                    placeholder="Start Time"
                                    // placeholder="08:00 AM"
                                    mask="__:__ _M"
                                    value={selectedDate}
                                    onChange={date => handleDateChange(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Row>
                        <Col>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    InputProps={{ disableUnderline: true }}
                                    className="TextFieldDate30"
                                    // id="date-detail"
                                    // variant="filled"
                                    // label="End Date"
                                    placeholder="End Date"
                                    value={selectedDate}
                                    onChange={date => handleDateChange(date)}
                                    format="yyyy/MM/dd"
                                />
                            </MuiPickersUtilsProvider>
                        </Col>

                        <Col>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    InputProps={{ disableUnderline: true }}
                                    className="TextFieldDate30"
                                    // id="date-detail"
                                    // variant="filled"
                                    // label="End Time"
                                    placeholder="End Time"
                                    // placeholder="08:00 AM"
                                    mask="__:__ _M"
                                    value={selectedDate}
                                    onChange={date => handleDateChange(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                    </Row>
                </Col>
            </Row> */}

        </React.Fragment >
    )
}
export default DetailSection;