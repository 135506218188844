import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import Button from "../../../../../components/button/button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SpinnerLoader from "../../../../../components/loader/spinnerLoader";
import SearchIcon from "@material-ui/icons/Search";
import { getMethod, getProductDetails, postMethodWithTokenProgress, postMethodWithToken, putMethodWithToken } from "../../../../../utils/services/api";
import urls from "../../../../../utils/services/apiUrl.json";
import { getProductId, getStoreId, setProductId, getProductEditableFlag } from "../../../../../utils/common";
import "./general.scss";
import Switch from "react-switch";


const checkGeneralInfo = generalData => {

    const emptyKeys = [
        'product_name',
        'brand',
        'product_model',
        'category_slug',
        // 'subcategory_slug',
        'description',
    ];

    const emptyKeysErrors = [
        'Name',
        'Brand',
        'Model',
        'Category',
        // 'Sub Category',
        'Description',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!generalData.general[emptyKeys[i]]) {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};



function GeneralSection({ generalData, prevGeneralData, setSwipeableFlag, specificationProps, descriptionProps }) {
    function getSpecificationsConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Product Specifications",
            cleanHTML: {
                fillEmptyParagraph: false
            }
        }
        return config;
    }

    const specificationsConfig = useMemo(() => getSpecificationsConfig(specificationProps), [specificationProps]);
    function getDescriptionConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Product Description",
            cleanHTML: false
        }
        return config;
    }

    const descriptionConfig = useMemo(() => getDescriptionConfig(descriptionProps), [descriptionProps]);

    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue((value) => value + 1); // update the state to force render
    }

    const forceUpdate = useForceUpdate();

    const editorRef = useRef(null);
    const [content, setContent] = useState("")
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        product_name: "",
        brand: "",
        product_model: "",
        category_slug: "",
        subcategory_slug: "",
        third_category_slug: "",
        fourth_category_slug: "",
        description: "",
        specification: "",
        shippingFlag: false,
        inventoryFlag: false,
        product_status_clearence: false,
        product_status_flag: false
    });

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [subSubCategoryOptions, setSubSubCategoryOptions] = useState([]);
    const [fourthCategoryOptions, setFourthCategoryOptions] = useState([]);
    const [categoryOptionsData, setCategoryOptionsData] = useState([]);
    const [subCategoryOptionsData, setSubCategoryOptionsData] = useState([]);
    const [subSubCategoryOptionsData, setSubSubCategoryOptionsData] = useState([]);
    const [fourthCategoryOptionsData, setFourthCategoryOptionsData] = useState([]);
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [subSubCategory, setSubSubCategory] = useState("");
    const [fourthCategory, setFourthCategory] = useState("");
    const [brandOptions, setBrandOptions] = useState([]);
    const [brandOptionsData, setBrandOptionsData] = useState([]);

    const dragItem = useRef();
    const dragOverItem = useRef();

    useEffect(() => {
        productCategory();

        if (prevGeneralData) {
            setCategory(prevGeneralData.category_slug);
            productSubCategory(prevGeneralData.category_slug);
            setSubCategory(prevGeneralData.subcategory_slug);

            productSubSubCategory(prevGeneralData.category_slug, prevGeneralData.subcategory_slug)
            setSubSubCategory(prevGeneralData.third_category_slug);

            productFourthCategory(prevGeneralData.category_slug, prevGeneralData.subcategory_slug, prevGeneralData.third_category_slug)

            setFourthCategory(prevGeneralData.fourth_category_slug)

            setState(prevGeneralData)

            setLoading(true)
        } else {
            listedDetails();
        }
    }, []);

    useEffect(() => {
        let backData = {
            data: state,
            is_submit: false,
        }
        generalData(backData)
    }, [state]);

    useEffect(() => {
        getBrandsList();
    }, []);

    const getBrandsList = async () => {
        await getMethod(urls.getBrandsListMerchant).then((response) => {
            if (response.status === 200) {
                setBrandOptions(response.data.results.data);
                setBrandOptionsData(response.data.results.data);
            }
        }).catch((error) => {
            console.log("API error", error);
        });
    }

    const productCategory = async () => {
        if (categoryOptions.length <= 0) {
            await getMethod(urls.productCategory).then((response) => {
                if (response.status === 200) {
                    setCategoryOptions(response.data);
                    setCategoryOptionsData(response.data);
                }
            }).catch((error) => {
                console.log("API error", error);
            });
        }
    };

    const productSubCategory = async (slug) => {
        if (slug !== "" && slug !== undefined) {
            await getMethod(urls.productSubCategory + slug + "/").then((response) => {
                if (response.status === 200) {
                    setSubCategoryOptions(response.data);
                    setSubCategoryOptionsData(response.data);
                }
            }).catch((error) => {
                console.log("API error", error);
            });
        }
    };

    const productSubSubCategory = async (categoryslug, slug) => {
        if (slug !== "" && slug !== undefined) {
            await getMethod(urls.productSubCategory + categoryslug + "/" + slug + "/").then((response) => {
                if (response.status === 200) {
                    setSubSubCategoryOptions(response.data);
                    setSubSubCategoryOptionsData(response.data);
                }
            }).catch((error) => {
                console.log("API error", error);
            });
        }
    };

    const productFourthCategory = async (categoryslug, subCategoryslug, slug) => {
        if (slug !== "" && slug !== undefined) {
            await getMethod(urls.productSubCategory + categoryslug + "/" + subCategoryslug + "/" + slug + "/").then((response) => {
                if (response.status === 200) {
                    setFourthCategoryOptions(response.data);
                    setFourthCategoryOptionsData(response.data);
                }
            }).catch((error) => {
                console.log("API error", error);
            });
        }
    };

    const listedDetails = async () => {
        if (getProductId() !== null && getProductEditableFlag() !== 'added') {
            let storeId = getStoreId();
            let productId = getProductId();
            let moduleName = "general";

            await getProductDetails(storeId, productId, moduleName).then((productDetails) => {

                if (productDetails.status === 200) {
                    setLoading(true);

                    const generalResults = productDetails.data.response;

                    setState((prevState) => ({
                        ...prevState,
                        ["product_name"]: generalResults.product_name,
                        ["brand"]: generalResults.brand,
                        ["product_model"]: generalResults.product_model,
                        ["category_slug"]: generalResults.categories !== null && generalResults.categories.length !== 0 ? generalResults.categories[0].slug : "",
                        ["subcategory_slug"]: generalResults.product_subcategory !== null && generalResults.product_subcategory.length !== 0 ? generalResults.product_subcategory[0].slug : "",
                        ["third_category_slug"]: generalResults.productThirdCategory !== null && generalResults.productThirdCategory.length !== 0 ? generalResults.productThirdCategory[0].slug : "",
                        ["fourth_category_slug"]: generalResults.productFourthCategory !== null && generalResults.productFourthCategory.length !== 0 ? generalResults.productFourthCategory[0].slug : "",
                        ["description"]: generalResults.description,
                        ["specification"]: generalResults.specification,
                        ["shippingFlag"]: generalResults.shippingFlag,
                        ["inventoryFlag"]: generalResults.inventoryFlag,
                        ["product_status_clearence"]: generalResults.product_status_clearence,
                        ["product_status_flag"]: generalResults.product_status_flag
                    }));

                    setCategory(generalResults.categories !== null && generalResults.categories.length !== 0 ? generalResults.categories[0].slug : "");
                    productSubCategory(generalResults.categories !== null && generalResults.categories.length !== 0 ? generalResults.categories[0].slug : "");
                    setSubCategory(generalResults.product_subcategory !== null && generalResults.product_subcategory.length !== 0 ? generalResults.product_subcategory[0].slug : "");

                    productSubSubCategory(
                        generalResults.categories !== null && generalResults.categories.length !== 0 ? generalResults.categories[0].slug : "",
                        generalResults.product_subcategory !== null && generalResults.product_subcategory.length !== 0 ? generalResults.product_subcategory[0].slug : ""
                    )
                    setSubSubCategory(generalResults.productThirdCategory !== null && generalResults.productThirdCategory.length !== 0 ? generalResults.productThirdCategory[0].slug : "");

                    productFourthCategory(
                        generalResults.categories !== null && generalResults.categories.length !== 0 ? generalResults.categories[0].slug : "",
                        generalResults.product_subcategory !== null && generalResults.product_subcategory.length !== 0 ? generalResults.product_subcategory[0].slug : "",
                        generalResults.productThirdCategory !== null && generalResults.productThirdCategory.length !== 0 ? generalResults.productThirdCategory[0].slug : ""
                    )

                    setFourthCategory(generalResults.productFourthCategory !== null && generalResults.productFourthCategory.length !== 0 ? generalResults.productFourthCategory[0].slug : "")
                }

            }).catch((error) => {
                console.log("API error", error);
            });
        }
        setLoading(true);
    };

    const handleSwitchChange = (event, name) => {
        setState((prevState) => ({
            ...prevState,
            [name]: event,
        }))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value.trimStart(),
        }));
    };

    const handleSpcificationChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            ['specification']: e,
        }));
    };

    const handleDescriptionChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            ['description']: e,
        }));
    };

    const categoryChange = (event) => {
        if (event.target.value !== undefined) {
            setSubCategory('')
            setSubSubCategory('')
            setFourthCategory('')

            setState((prevState) => ({
                ...prevState,
                ["subcategory_slug"]: '',
                ["third_category_slug"]: '',
                ["fourth_category_slug"]: '',
            }));

            setCategory(event.target.value);

            setState((prevState) => ({
                ...prevState,
                ["category_slug"]: event.target.value,
            }));

            productSubCategory(event.target.value);
        }
    };
    const brandChange = (event) => {
        if (event.target.value !== undefined) {
            setState((prevState) => ({
                ...prevState,
                ["brand"]: '',
            }));
            setState((prevState) => ({
                ...prevState,
                ["brand"]: event.target.value,
            }));
        }
    }

    const subCategoryChange = (event) => {
        if (event.target.value !== undefined) {
            setSubSubCategory('')
            setFourthCategory('')
            setState((prevState) => ({
                ...prevState,
                ["third_category_slug"]: '',
                ["fourth_category_slug"]: '',
            }));

            setSubCategory(event.target.value);

            setState((prevState) => ({
                ...prevState,
                ["subcategory_slug"]: event.target.value,
            }));

            productSubSubCategory(category, event.target.value)
        }
    };

    const subSubCategoryChange = (event) => {
        if (event.target.value !== undefined) {
            setFourthCategory('')
            setState((prevState) => ({
                ...prevState,
                ["fourth_category_slug"]: '',
            }));

            setSubSubCategory(event.target.value);

            setState((prevState) => ({
                ...prevState,
                ["third_category_slug"]: event.target.value,
            }));

            productFourthCategory(category, subCategory, event.target.value)
        }
    };

    const fourthCategoryChange = (event) => {
        if (event.target.value !== undefined) {
            setFourthCategory(event.target.value);
            setState((prevState) => ({
                ...prevState,
                ["fourth_category_slug"]: event.target.value,
            }));
        }
    };

    const onSubmitGeneral = async (value) => {
        let storeId = getStoreId();
        let product_id = getProductId();

        let generalBody = {
            store_id: storeId,
            general: state,
        }

        const generalError = checkGeneralInfo(generalBody);

        if (generalError) {
            toast.error(generalError)
        } else {
            if (product_id && product_id !== null) {
                await putMethodWithToken(urls.aboutStore + storeId + "/product/" + product_id + "/?module=" + value, generalBody).then((response) => {
                    if ((response.status === 200 || response.status === 201) && value === "general") {
                        toast.success(response.data.success)

                        let backData = {
                            data: state,
                            is_submit: true,
                        }

                        generalData(backData)
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
            } else {
                await postMethodWithToken(urls.addProduct + value, generalBody).then((response) => {
                    if ((response.status === 200 || response.status === 201) && value === "general") {
                        toast.success(response.data.success)

                        let backData = {
                            product_id: response.data.product_id,
                            data: state,
                            is_submit: true,
                        }

                        setProductId(response.data.product_id)
                        generalData(backData)
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
            }
        }
    };

    const firstCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = categoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCategoryOptions(results);
        } else {
            setCategoryOptions(categoryOptionsData);
        }
    };

    const secondCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = subCategoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setSubCategoryOptions(results);
        } else {
            setSubCategoryOptions(subCategoryOptionsData);
        }
    };

    const thirdCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = subSubCategoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setSubSubCategoryOptions(results);
        } else {
            setSubSubCategoryOptions(subSubCategoryOptionsData);
        }
    };

    const fourthCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = fourthCategoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFourthCategoryOptions(results);
        } else {
            setFourthCategoryOptions(fourthCategoryOptionsData);
        }
    };

    const brandCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = brandOptionsData.filter((item) => {
                return item.brand_name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setBrandOptions(results);
        } else {
            setBrandOptions(brandOptionsData);
        }
    };

    return (
        <div>
            {!loading ? (
                <SpinnerLoader />
            ) : (
                <ValidatorForm>
                    <div className="row pt-3">
                        <div className="col-4 product-name">
                            <TextValidator
                                required
                                inputProps={{ maxLength: 80 }}
                                validators={[
                                    "minStringLength:1",
                                    "maxStringLength:80",
                                    "required",
                                ]}
                                onChange={handleChange}
                                value={state.product_name}
                                name="product_name"
                                label="Product Name"
                                variant="outlined"
                            />

                        </div>
                        <div className="col-4">
                            <FormControl variant="outlined" >
                                <InputLabel required classid="brand-select">Brand</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-brand"
                                    id="Product-brand-dropdown"
                                    value={state.brand}
                                    defaultValue={state.brand}
                                    name="brand"
                                    label="Brand"
                                    onChange={(e) => brandChange(e)}
                                    onClose={() => setBrandOptions(brandOptionsData)}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={brandCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>

                                    {brandOptions.length > 0 && brandOptions.map((item) => {
                                        return (

                                            <MenuItem key={item.id} value={item.brand_name}>
                                                {item.brand_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-4 product-modal">
                            <TextValidator
                                required
                                inputProps={{ maxLength: 20 }}
                                validators={[
                                    "minStringLength:1",
                                    "maxStringLength:30",
                                    "required",
                                ]}
                                onChange={handleChange}
                                value={state.product_model}
                                name="product_model"
                                label="Model"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-3">
                            <FormControl variant="outlined">
                                <InputLabel required classid="category-select">Product Category</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-category"
                                    id="Product-category-dropdown"
                                    value={category}
                                    defaultValue={category}
                                    label="Product Category"
                                    onChange={(e) => categoryChange(e)}
                                    onClose={() => setCategoryOptions(categoryOptionsData)}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={firstCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {categoryOptions.length > 0 && categoryOptions.map((item) => {
                                        return (
                                            <MenuItem key={item.slug} value={item.slug} disabled={item.slug === "all"}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-3">
                            <FormControl variant="outlined">
                                <InputLabel required id="subcategory-select">Product Sub Category</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-subcategory"
                                    id="Product-subcategory-dropdown"
                                    value={subCategory}
                                    defaultValue={subCategory}
                                    label="Product Sub Category"
                                    onChange={(e) => subCategoryChange(e)}
                                    onClose={() => setSubCategoryOptions(subCategoryOptionsData)}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={secondCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {subCategoryOptions.length > 0 && subCategoryOptions.map((item) => {
                                        return (
                                            <MenuItem key={item.slug} value={item.slug}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-3">
                            <FormControl variant="outlined">
                                <InputLabel id="subcategory-select">Product Sub Sub Category</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-subcategory"
                                    id="Product-subcategory-dropdown"
                                    value={subSubCategory}
                                    defaultValue={subSubCategory}
                                    label="Product Sub Sub Category"
                                    onChange={(e) => subSubCategoryChange(e)}
                                    onClose={() => setSubSubCategoryOptions(subSubCategoryOptionsData)}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={thirdCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {subSubCategoryOptions.length > 0 && subSubCategoryOptions.map((item) => {
                                        return (
                                            <MenuItem key={item.slug} value={item.slug}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-3">
                            <FormControl variant="outlined">
                                <InputLabel id="subcategory-select">Product Sub Sub Sub Category</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-subcategory"
                                    id="Product-subcategory-dropdown"
                                    value={fourthCategory}
                                    defaultValue={fourthCategory}
                                    label="Product Sub Sub Sub Category"
                                    onChange={(e) => fourthCategoryChange(e)}
                                    onClose={() => setFourthCategoryOptions(fourthCategoryOptionsData)}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={fourthCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {fourthCategoryOptions.length > 0 && fourthCategoryOptions.map((item) => {
                                        return (
                                            <MenuItem key={item.slug} value={item.slug}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-6">
                            <div className={"joditEdit"}>
                                <JoditEditor
                                    ref={editorRef}
                                    value={state.description}
                                    tabIndex={1}
                                    config={descriptionConfig}
                                    onChange={(newContent) => handleDescriptionChange(newContent)}

                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={"joditEdit"}>
                                <JoditEditor
                                    ref={editorRef}
                                    value={state.specification}
                                    config={specificationsConfig}
                                    onBlur={newContent => handleSpcificationChange(newContent)}
                                    onChange={newContent => { }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-r">
                        <div className="col-6 mt-5">
                            <div className="row col-sm-12">
                                <InputLabel style={{ fontSize: "14px" }} className="mt-2 mr-3">On Clearance ?</InputLabel>
                                <Switch
                                    checked={state.product_status_clearence}
                                    className={(state.product_status_clearence) ? 'selected_sett' : 'unselected_sett'}
                                    onColor="#1D3762"
                                    offColor="#808080"
                                    onHandleColor="#F2F5F7"
                                    offHandleColor="#F2F5F7"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    name="product_status_clearence"
                                    onChange={(e) => handleSwitchChange(e, 'product_status_clearence')}
                                    disabled={true}
                                />
                                <InputLabel style={{ fontSize: "14px" }} className="ml-1 mt-2 mr-3">Active Status</InputLabel>
                                <Switch
                                    checked={state.product_status_flag}
                                    className={(state.product_status_flag) ? 'selected_sett' : 'unselected_sett'}
                                    onColor="#1D3762"
                                    offColor="#808080"
                                    onHandleColor="#F2F5F7"
                                    offHandleColor="#F2F5F7"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    name="product_status_flag"
                                    onChange={(e) => handleSwitchChange(e, 'product_status_flag')}
                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end mt-5">
                            <Button onClick={() => onSubmitGeneral("general")} name="Save" className="btn btn-primary mb-5" />
                        </div>
                    </div>
                </ValidatorForm>
            )}
        </div>
    );
}
export default GeneralSection;
