import React, { useState } from "react";
import Button from "../../../components/button/button";
import { Elements, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { setStripeToken } from "../../../utils/common";
import { stripePaymentKeyUSA, stripePaymentKeyCAN } from "../../../utils/env.json";

const CheckoutPayMoney = (props) => {
    const stripe = useStripe();
    const payMoney = async (e) => {
        console.log(e);
        e.preventDefault();
        if (!stripe || !props.payData) {
            return;
        }
        const paymentMethodObj = {
            type: "card",
            card: props.payData.getElement(CardNumberElement),
        };
        const { error, token } = await stripe.createToken(
            props.payData.getElement(CardNumberElement)
        );
        if (error) {
            props.setCardSuccess(false);
            toast.error(error.message);
        } else {
            props.setCardSuccess(true);
            setStripeToken(token.id);
            props.finish(token.id);
        }
    };

    return (
        <div className="text-right">
            {/* <Button name="Save" className="btn btn-primary mb-5" onClick={(event) => { payMoney(event); }} /> */}
            <div ref={props.reference} onClick={(event) => payMoney(event)}>
            </div>
        </div>
    );
};

export const CheckoutForm = (props) => {
    const [cardSuccess, setCardSuccess] = useState(false);
    const elements = useElements();

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    let cardFontSize = '';
    if (screenSize.width <= 1100) {
        cardFontSize = "12px";
    } else if (screenSize.width <= 1500) {
        cardFontSize = "14px";
    } else {
        cardFontSize = "14px";
    }

    return (
        <form>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="textfieldedituserstripe">
                        <CardNumberElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: cardFontSize,
                                        fontFamily: '"Segoe UI", Arial, sans-serif',
                                        color: "#808080",
                                        fontWeight: "400",
                                    },
                                    invalid: {
                                        color: "red",
                                    },
                                },
                                placeholder: "Card Number",
                            }}
                            onChange={props.handle(elements)}
                        />
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-6">
                    <div className=" textfieldedituserstripeExpiry">
                        <CardExpiryElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: cardFontSize,
                                        fontFamily: '"Segoe UI", Arial, sans-serif',
                                        color: "#808080",
                                        fontWeight: "400",
                                    },
                                    invalid: {
                                        color: "red",
                                    },
                                },
                                placeholder: "Expire (MM/YY)",
                            }}
                            onChange={props.handle(elements)}
                        />
                    </div>
                </div>

                <div className="col-6">
                    <div className="textfieldedituserstripeCvv">
                        <CardCvcElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: cardFontSize,
                                        fontFamily: '"Segoe UI", Arial, sans-serif',
                                        color: "#808080",
                                        fontWeight: "400",
                                    },
                                    invalid: {
                                        color: "red",
                                    },
                                },
                            }}
                            onChange={props.handle(elements)}
                        />
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <CheckoutPayMoney
                    payData={props.payData}
                    finish={(val) => props.finish(val)}
                    cardSuccess={cardSuccess}
                    setCardSuccess={() => setCardSuccess()}
                    reference={props.reference}
                />
            </div>
        </form>
    );
};

const CardDetails = (props) => {
    let stripeKey;
    if (props.setStripeCountry === 'USA') {
        stripeKey = stripePaymentKeyUSA;
    } else {
        stripeKey = stripePaymentKeyCAN
    }

    const [stripePromise, setStripePromise] = useState(() =>
        loadStripe(stripeKey)
    );

    return (
        <div>
            <Elements stripe={stripePromise}>
                <CheckoutForm
                    payData={props.stripeCardData}
                    handle={(elements) => props.setStripeCardData(elements)}
                    finish={(val) => props.finish(val)}
                    reference={props.reference}
                />
            </Elements>
        </div>
    );
};
export default CardDetails;
