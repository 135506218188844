import React from "react";

function HelpCenter(props) {
    return (
        <div className={props.menu ? "screenLeftMargin w-100" :"screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mr-3 mobileResponsive mt-3">
                <h1 className="heading-title col-12">Help Center</h1>
            </div>
            <div style={{marginTop:"60px"}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="row ml-3 mr-3 mobileResponsive mt-3">
                        <h1 className="heading-title col-12">Connect With Shopeez Customer Support Team</h1>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center",marginTop:"10px" }}>
                    <div className="row ml-3 mr-3 mobileResponsive mt-3">
                        <h1 className="heading-title col-12">Email: <span style={{color:"blue"}}>support@myshopez.com</span></h1>
                        {/* <a href="mailto:deepak.tyagi@rootinfosol.com">email</a> */}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center",marginTop:"10px" }}>
                    <div className="row ml-3 mr-3 mobileResponsive mt-3">
                        <h1 className="heading-title col-12">Phone: <span style={{color:"blue"}}>+1 (855) 814-0000</span></h1>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default HelpCenter;