import React from 'react';
import './userDetails.scss';
import { useHistory } from "react-router-dom";
import Email from "../../../../assets/images/Email_E.svg";
import Bell from "../../../../assets/images/Bell_B.svg";
import AppUsersDb from "../../../../assets/images/Appusers_A.svg";
import UserDB from "../../../../assets/images/man.png";
import Button from "../../../../components/button/button";
import Phone from "../../../../assets/images/Call_C.svg";
import Emaill from "../../../../assets/images/email.png";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import RecentOrders from '../recentOrders/recentOrders';
import PreviousOrders from '../previousOrders/previousOrders';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';

const UserDetails = () => {
    var panelOpen = window.$panelActive;
    const [currTab, setCurrTab] = React.useState(0);

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }
    const history = useHistory();

    const notification = async () => {
        history.push("/onlineenquiry/notifications");

    }
    const gotoBack = async () => {
        history.goBack()
    };

    return (
        <div className='w-100 screenLeftMargin'>
            <div className="row ml-5 mr-5 mt-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <div className="d-flex" style={{justifyContent: "center", alignItems: "center"}}>
                        <button className="Button" onClick={() => gotoBack()}>
                            <ArrowBackIcon /> Back
                        </button>
                    </div>
                    <div className="enquiry-text d-flex ml-3" style={{justifyContent: "center", alignItems: "center"}}>
                        <img className='mb-2 mr-3' src={AppUsersDb} />
                        <span>App Users</span>
                    </div>
                </div>
                <div className="col-5 justify-content-end">
                    <div className='appusers-notifications-icon ml-3' onClick={() => notification()}>
                        <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                    <div className='appusers-notifications-icon'>
                        <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                </div>
            </div>

            <div className="row ml-5 mr-5 mt-3 mobileResponsive">
                <div className="enquiry-text col-12">
                    <div className='userDetailsBox'>
                        <div className='row'>
                            <div className='col-8'>
                                <div className='user-text col-3 d-flex justify-content-center mt-3'>User Details</div>
                                <div className='row mt-3'>
                                    <div className='col-3 p-0 m-0 d-flex justify-content-center ml-3'>
                                        <img src={UserDB}
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                borderRadius: "50%",
                                            }} />
                                    </div>
                                    <div className='col-6'>
                                        <h1 className='user-name pt-3'>Alan Gilton</h1>
                                        <div>
                                            <span className='d-flex'>
                                                <img className="user-icons mt-2 mr-3" src={Phone} />
                                                <h1 className='user-email'>+1 (403) 785-6565</h1>
                                            </span>
                                        </div>
                                        <div>
                                            <span className='d-flex'>
                                                <img className="user-icons mt-2 mr-3" src={Emaill} />
                                                <h1 className='user-email'>alangilton@gmail.com</h1>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-4 d-flex justify-content-center mt-5'>
                                <Button
                                    onClick={() => ("")}
                                    className="disableUser-btn "
                                    name="Disable User"
                                    >
                                </Button>
                            </div>
                        </div>

                        <div className='row ml-4 mr-4'>
                            <div className='col-12 mt-5'>
                                <hr style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className={panelOpen ? "width75" : "width75"}>
                                    <Tabs
                                        value={currTab}
                                        onChange={handleTabChange}
                                        TabIndicatorProps={{ style: { background: '#233949' } }}
                                        textColor="primary"
                                        variant="fullWidth"
                                        >
                                        <Tab label="Recent Orders" />
                                        <Tab label="Previous Orders" />
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: "flex" }}>
                <div className={panelOpen ? "width79" : "width79"}>
                    <SwipeableViews
                        index={currTab}
                        onChangeIndex={handleChangeIndex}
                        enableMouseEvents={true}
                        >
                        <div>
                            <RecentOrders />
                        </div>
                        <div>
                            <PreviousOrders />
                        </div>

                    </SwipeableViews>
                </div>
            </div>
        </div>
    )
};

export default UserDetails;
