import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";
import "./thumbNailview.scss";
import {
  setProductId,
  setInnerProductId,
  setMethod,
} from "../../../../../utils/common";
import TabBar from "../../components/tabs/tabs";
import DefaultImage from "../../../../../assets/images/ShopezDefult.svg"
import Tooltip from '@material-ui/core/Tooltip';
import { getMethod } from "../../../../../utils/services/api";
import urls from "../../../../../utils/services/apiUrl.json";
import OnHoldModal from "../../../../../components/OnHoldModal/onHoldModal";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    marginRight: "30px",
    height: "300px",
  },
  draftToolTip: {
    backgroundColor: "rgb(255, 127, 0)",
  },
  draftToolTipArrow: {
    color: "rgb(255, 127, 0)",
  },
  activeToolTip: {
    backgroundColor: "rgb(0, 180, 0)",
  },
  activeToolTipArrow: {
    color: "rgb(0, 180, 0)",
  },
  deactiveToolTip: {
    backgroundColor: "rgb(255, 0, 0)",
  },
  deactiveToolTipArrow: {
    color: "rgb(255, 0, 0)",
  },
});


const styles = {
  cardAction: {
    display: "block",
    textAlign: "initial",
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
  },
};

export default function ThumbNailView({
  cardViewData,
  goToProductDetails,
  myFun,
}) {
  window.$addProduct = false;
  const [addProduct, setAddProduct] = useState(window.$addProduct);
  const classes = useStyles();
  const [cardData, setCardData] = useState(cardViewData);
  const [show, setShow] = useState(true);
  const [modalMe,setModalMe] = useState(false)

  const getSteps = async () => {
    await getMethod(urls.getCustomerStripeId).then((response) => {
        if (response.status === 200) {
            setModalMe(response.data.on_hold_check_flag)    
        }
    });
};


  const goToDetails = async (productClicked, index) => {
    getSteps()
    myFun(!show);
    setMethod("PUT");
    setProductId(productClicked.value.id);
    setInnerProductId(productClicked.value.id);
    setAddProduct(true);
    window.$productDetails = productClicked.value;
  };

  const SortableItem = SortableElement((item, index) => {
    return (
      <>
        <Card className={classes.root} style={{ cursor: "pointer" }}>
          {item.value.children.length === 0
            ? item?.value?.images?.length > 0 ?
              item.value.images.slice(0, 1).map((items, i) => {
                return (
                  <>
                    <div style={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        alt=""
                        style={{ height: "180px" }}
                        image={items.original.length > 0 ? items.original : DefaultImage}
                        onClick={() => goToDetails(item, index)}
                      />
                      <div className="d-flex pt-1 pl-1 pr-1" style={{ position: "absolute", color: "white", top: 2, left: "4%", }}>
                        {item.value.productNewStatus !== true ?
                          <Tooltip classes={{ tooltip: classes.draftToolTip, arrow: classes.draftToolTipArrow }} title="Draft" placement="left" arrow>
                            <div className="prolisting-card-color" style={{ backgroundColor: "rgb(255, 127, 0)" }}>Status</div>
                          </Tooltip>
                          : item.value.status === true ?
                            <Tooltip classes={{ tooltip: classes.activeToolTip, arrow: classes.activeToolTipArrow }} title="Active" placement="left" arrow>
                              <div className="prolisting-card-color" style={{ backgroundColor: "rgb(0, 180, 0)" }}>Status</div>
                            </Tooltip>
                            :
                            <Tooltip classes={{ tooltip: classes.deactiveToolTip, arrow: classes.deactiveToolTipArrow }} title="Deactive" placement="left" arrow>
                              <div className="prolisting-card-color" style={{ backgroundColor: "rgb(255, 0, 0)" }}>Status</div>
                            </Tooltip>
                        }
                        <div className="d-flex pl-5" style={{ position: "absolute", color: "white", top: 150,}}>
                          <div className="row m-0 p-0">
                            <div className="col-12 m-0 p-0">
                              {item.value.children.length === 0 ? (
                                <Typography className="mt-1 pr-3 d-flex justify-content-end" style={{ background: "rgba(0, 0, 0, 0.8)", width: "130px", height: "23px" }}>
                                  <span className="priceLabel ft-14">
                                    {item.value.compare_at_price > 0 ? "$" + item.value.compare_at_price : ""}
                                  </span>
                                  <span className="inv_price ml-1 ft-14 ">
                                    {item.value.price > 0
                                      ? "$" + item.value.price
                                      : ""}
                                  </span>
                                </Typography>
                              ) :
                                (
                                  <Typography className=" mt-1 pr-3 d-flex justify-content-end" style={{ background: "rgba(0, 0, 0, 0.8)", width: "130px" }}>
                                    <span className="priceLabel ft-14">
                                      {item.value.children[0].compare_at_price > 0
                                        ? "$" + item.value.children[0].compare_at_price
                                        : ""}
                                    </span>
                                    <span className="inv_price ft-14 ml-1">
                                      {item.value.children[0].price > 0
                                        ? "$" + item.value.children[0].price
                                        : ""}
                                    </span>
                                  </Typography>
                                )
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
              :
              <div style={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  alt=""
                  style={{ height: "180px" }}
                  image={DefaultImage}
                  onClick={() => goToDetails(item, index)}
                />
                <div className="d-flex pt-1 pl-1 pr-1" style={{ position: "absolute", color: "white", top: 2, left: "4%", }}>
                  {item.value.productNewStatus !== true ?
                    <Tooltip classes={{ tooltip: classes.draftToolTip, arrow: classes.draftToolTipArrow }} title="Draft" placement="left" arrow>
                      <div className="prolisting-card-color" style={{ backgroundColor: "rgb(255, 127, 0)" }}>Status</div>
                    </Tooltip>
                    : item.value.status === true ?
                      <Tooltip classes={{ tooltip: classes.activeToolTip, arrow: classes.activeToolTipArrow }} title="Active" placement="left" arrow>
                        <div className="prolisting-card-color" style={{ backgroundColor: "rgb(0, 180, 0)" }}>Status</div>
                      </Tooltip>
                      :
                      <Tooltip classes={{ tooltip: classes.deactiveToolTip, arrow: classes.deactiveToolTipArrow }} title="Deactive" placement="left" arrow>
                        <div className="prolisting-card-color" style={{ backgroundColor: "rgb(255, 0, 0)" }}>Status</div>
                      </Tooltip>
                  }
                </div>
              </div>

            :
            item.value.children.length > 0 &&
            item.value.children.slice(0, 1).map((items, i) => {
              return (
                <>
                  <div style={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      alt=""
                      style={{ height: "180px" }}
                      image={
                        items.images.length > 0
                          ? items.images[0].original
                          : DefaultImage
                      }
                      onClick={() => goToDetails(item, index)}
                    />
                    <div className="d-flex pt-1 pl-1 pr-1" style={{ position: "absolute", color: "white", top: 2, left: "4%", }}>
                      {/* <div>Status</div> */}
                      <div>
                        {item.value.productNewStatus !== true ?
                          <Tooltip classes={{ tooltip: classes.draftToolTip, arrow: classes.draftToolTipArrow }} title="Draft" placement="left" arrow>
                            <div className="prolisting-card-color" style={{ backgroundColor: "rgb(255, 127, 0)" }}>Status</div>
                          </Tooltip>
                          : item.value.status === true ?
                            <Tooltip classes={{ tooltip: classes.activeToolTip, arrow: classes.activeToolTipArrow }} title="Active" placement="left" arrow>
                              <div className="prolisting-card-color" style={{ backgroundColor: "rgb(0, 180, 0)" }}>Status</div>
                            </Tooltip>
                            :
                            <Tooltip classes={{ tooltip: classes.deactiveToolTip, arrow: classes.deactiveToolTipArrow }} title="Deactive" placement="left" arrow>
                              <div className="prolisting-card-color" style={{ backgroundColor: "rgb(255, 0, 0)" }}>Status</div>
                            </Tooltip>
                        }
                      </div>
                    
                    <div className="d-flex pl-5" style={{ position: "absolute", color: "white", top: 150 }}>
                      <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0 d-flex justify-content-end">
                          {item.value.children.length === 0 ? (
                            <Typography className="mt-1 pr-3 d-flex justify-content-end" style={{ background: "rgba(0, 0, 0, 0.8)", width: "130px", height: "25px" }}>
                              <span className="priceLabel ft-14">
                                {item.value.compare_at_price > 0 ? "$" + item.value.compare_at_price : ""}
                              </span>
                              <span className="inv_price ml-1 ft-14 ">
                                {item.value.price > 0
                                  ? "$" + item.value.price
                                  : ""}
                              </span>
                            </Typography>
                          ) :
                            (
                              <Typography className=" mt-1 pr-3 d-flex justify-content-end" style={{ background: "rgba(0, 0, 0, 0.8)", width: "130px" }}>
                                <span className="priceLabel ft-14">
                                  {item.value.children[0].compare_at_price > 0
                                    ? "$" + item.value.children[0].compare_at_price
                                    : ""}
                                </span>
                                <span className="inv_price ft-14 ml-1">
                                  {item.value.children[0].price > 0
                                    ? "$" + item.value.children[0].price
                                    : ""}
                                </span>
                              </Typography>
                            )
                          }

                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </>
              );
            })}

          <CardContent
            onClick={() => goToDetails(item, index)}
          >
            {(item?.value?.partner_sku?.length > 0 || item?.value?.children[0]?.partner_sku?.length > 0) &&
              <div className="row">
                <div className="col-12 d-flex pt-1" id="cardName" >
                  <Typography className="ft-14 ml-2 f-600" style={{ fontFamily: "Source Sans Pro"}}>SKU:</Typography>
                  {item.value.children.length === 0 ? (
                    <Typography id="cardName">
                      <span className="ft-14 ml-1">
                        {item?.value?.partner_sku}
                      </span>
                    </Typography>
                  ) :
                    (
                      <Typography id="cardName">
                        <span className="ft-14 ml-1">
                          {item?.value?.children[0]?.partner_sku}
                        </span>
                      </Typography>
                    )
                  }
                </div >
              </div>
            }
            {(item?.value?.upc?.length > 0 || item?.value?.children[0]?.upc?.length > 0) &&
              <div className="row">
                <div className="col-12 d-flex pt-1" id="cardName">
                  <Typography className="ft-14 pl-2 f-600" style={{ fontFamily: "Source Sans Pro"}}>UPC:</Typography>
                  {item.value.children.length === 0 ? (
                    <Typography id="cardName">
                      <span className="ft-14 ml-1">
                        {item?.value?.upc}
                      </span>
                    </Typography>
                  ) :
                    (
                      <Typography id="cardName">
                        <span className="ft-14 ml-1">
                          {item?.value?.children[0]?.upc}
                        </span>
                      </Typography>
                    )
                  }
                </div>
              </div>
            }

            <Typography className="pt-1 pl-2" id="cardName">
              {item.value.product_name}
            </Typography>
            {/* <Typography id="cardModel" className="mt-2 ft-14 ">
              {item.value.product_model}
            </Typography> */}
            <div className="row">
              <div className="col-lg-12 col-md-12 pt-1">
                {item?.value?.children?.slice(0)?.length > 0 && item.value.children.slice(0).map((aitem, aindex) => {
                  return (
                    <>
                      <div className="d-flex ml-1">
                        {aindex === 0 && aitem?.attributes?.length > 0 && aitem?.attributes?.map((citem, cindex) => {
                          return (
                            <>
                              {citem.value_text !== 'color_code_swatch' &&
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      {cindex === 0 ?
                                        <div className="d-flex" style={{ marginBottom: "5px", color: aitem?.color_code && aitem?.image_picker?.length === 0 ? "white" : "#203864", background: aitem.color_code && aitem?.image_picker?.length > 0 ? "" : aitem.color_code, padding: "0px 5px 0 5px",textShadow: "1px 1px #203864" }}><b>{citem.name} :</b>
                                          {aitem?.image_picker?.length > 0 ? aitem.image_picker.map((mitem, mindex) => {
                                            return (
                                              <>
                                                <div>
                                                  <img src={mitem.original} alt="" className="pickerImage" />
                                                </div>
                                              </>
                                            )
                                          }) :
                                            citem.value_text
                                          }
                                        </div>
                                        :
                                        <div style={{ marginBottom: "5px", color: aitem.color_code && aitem?.image_picker?.length > 0 ? "#203864" : "white", background: aitem.color_code && aitem?.image_picker?.length > 0 ? "" : aitem.color_code, padding: "0px 5px 0 0px",textShadow: "1px 1px #203864" }}><b>,{citem.name} :</b> {citem.value_text}</div>

                                      }

                                    </div>
                                  </div>
                                </>
                              }
                            </>
                          )
                        })}

                      </div>

                    </>
                  )
                })}
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  });

  const SortableGridView = SortableContainer(({ items }) => {
    return (
      <div className="row">
        <div className="thumbNailContainer">
          {items.map((value, index) => (
            <SortableItem key={`item-${value}`} index={index} value={value} />
          ))}
        </div>
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // setCardData(arrayMove(cardData, oldIndex, newIndex));
  };

  return addProduct ? (
    <div>
      <TabBar />
    </div>
  ) : (
    <SortableGridView
      items={cardData}
      onSortEnd={onSortEnd}
      axis={"xy"}
      distance={1}
    />
  );
}
