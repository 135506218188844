import React, { useEffect, useState } from "react";
import "./forgotPassword.scss";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ShopezLogo from "../../../assets/images/shopez_logo.png";
import ShopezBack from "../../../assets/images/arrow_left_alt.svg";
import Reset from "./reset_password.png";
import LogoReset from "./logo_Reset.svg";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
import { postMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { toast } from "react-toastify";
import ErrorImage from "../../../assets/images/404Image.png"

const ForgotPassword = () => {
    const history = useHistory();
    const [urlTokenId, setUrlTokenId] = useState();
    const [getPassword, setPassword] = useState({
        password: "",
        confirm_password: "",
    });
    const [status, setStatus] = useState("")

    let url_string = window.location.href;
    let url = new URL(url_string);
    let token = url.searchParams.get("token");
    let email = url.searchParams.get("email").replace(' ', '+');

    const gotoBack = async () => {
        history.push("/login");
    };

    useEffect(() => {
        validateToken();
    }, []);

    const validateToken = async () => {
        let body = {
            email: email,
            forget_token: token,
        };
        await postMethod(urls.forgotTokenValidate, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setUrlTokenId(response.data.user_id);
                setStatus(response.status)
            } else if (response.status === 400) {
                toast.error(response.data.Response);
                setStatus(response.status)
            } else {
                history.push("/invalidToken");
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const changePassword = async () => {
        let body = {
            user_id: urlTokenId,
            password: btoa(getPassword.password),
        };

        if (
            getPassword.password !== "" &&
            getPassword.confirm_password !== "" &&
            getPassword.password === getPassword.confirm_password
        ) {
            await postMethod(urls.setNewPassword, body).then((response) => {
                if (response.status === 200) {
                    history.push("/login");
                } else {
                    toast.error(response.error);
                }
            });
        }
    };

    const gotoBackback = async () => {
        history.goBack()
    };

    return (
        <React.Fragment>
            <div className="container-fluid p-0 m-0">
                <div className="row p-0 m-0">

                    <div className="col-sm-12 col-lg-6 forget_left">
                        <div className="forgotPasswordDeal container"
                            style={{
                                background: `url(${Reset})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'calc(100% - 40px) calc(100% - 56px)',
                                backgroundPosition: '20px 30px',
                                // backgroundSize: 'cover',
                            }} >
                            <img className="logo_home img-fluid" src={ShopezLogo} />
                            <img className="logo_forgot img-fluid mobile-setting" src={Reset} />
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-6 p-0">
                        {status === 200 ?
                            <div className="loginForm">
                                <div onClick={() => gotoBackback()} className="back_set_other forgor_css_back" style={{ cursor: "pointer" }}>
                                    <img className="back_other img-fluid" src={ShopezBack} />
                                </div>
                                <div id="create-card" className="forgot_card_container reset_card_container pb-5">{ /* pt-5  */}
                                    <div className="container ">
                                        <div className="text-center img_div"><img className="new_img_logo text-center img-fluid" src={LogoReset} /></div>
                                        <div className="heading-title text-center mb-2">Reset Password</div>
                                        <div className="h6-label text-center" style={{ color: "#808080" }}>Enter new password  to reset it</div>

                                        <ValidatorForm>

                                            <div className="" >
                                                <div className="text_mng first_div_top">
                                                    <TextValidator
                                                        required
                                                        errorMessages={"Please Enter The Password"}
                                                        validators={[
                                                            "minStringLength:5",
                                                            "maxStringLength:30",
                                                            "required",
                                                        ]}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 20 }}
                                                        type="password"
                                                        name="password"
                                                        label="Password"
                                                        variant="outlined"
                                                        value={getPassword.password}
                                                    />
                                                </div>
                                            </div>

                                            <div className=" ">
                                                <div className="text_mng text_mng_lr">
                                                    <TextValidator
                                                        required
                                                        errorMessages={"Please Enter The Confirm Password"}
                                                        validators={[
                                                            "minStringLength:1",
                                                            "maxStringLength:30",
                                                            "required",
                                                        ]}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 20 }}
                                                        type="password"
                                                        name="confirm_password"
                                                        label="Confirm Password"
                                                        variant="outlined"
                                                        value={getPassword.confirm_password}
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                {getPassword.password !== getPassword.confirm_password && getPassword.confirm_password.length > 0 ? (
                                                    <div style={{ marginLeft: "3vw", fontSize: "12px", color: "Red", height: "22px" }}>
                                                        Password and confirm password doesn't match!
                                                    </div>
                                                ) : (
                                                    <div style={{ marginLeft: "3vw", fontSize: "12px", height: "22px" }}>

                                                    </div>
                                                )}

                                                <div>
                                                    <div style={{ margin: "0 35px 52px 35px" }}>
                                                        {getPassword.password !== getPassword.confirm_password && getPassword.confirm_password.length > 0 ?
                                                            <Button className="btn btn-secondary w-100 text-truncate" disabled={true} name="Continue"></Button>
                                                            :
                                                            <Button onClick={() => changePassword()} className="btn btn-primary w-100 text-truncate" name="Continue"></Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="">
                                <div id="create-card" className="set_card_container-error create_welcome_card_container pd-5">
                                    <div className="container">
                                        <div className="heading_error">400 Error</div>
                                        <div className="error_text">This Link is Alredy Used</div>
                                        <div className="d-flex justify-content-center pt-3">
                                            <img src={ErrorImage} alt="ErrorImage" style={{ height: "55vh" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;
