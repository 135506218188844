import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./discounts.scss";
import Button from "../../../../components/button/button";
import Modal from "react-modal";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { OutlinedInput } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardTimePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import { getMethod, postMethodWithToken, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import { getStoreId } from "../../../../utils/common";
import { toast } from "react-toastify";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import OutsideClickHandler from "react-outside-click-handler";
import NoImage from "../../../../assets/images/storelisting.png";
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";

let timeOutId;

const checkDiscount = discount => {

    const emptyKeys = [
        'discount_code',
        'discount_conditions',
        'start_datetime',
        'end_datetime',
        'discount_type',
    ];

    const emptyKeysErrors = [
        'Discount Code',
        'Discount Conditions',
        'Start Datetime',
        'End Datetime',
        'Discount Type',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!discount[emptyKeys[i]]) {
            return `Please Fill In ${emptyKeysErrors[i]}`;
        }

        if(discount['discount_type'] && (discount['discount_type'] === 'percentage' || discount['discount_type'] === 'amount') && discount['discount_value'] === '') {
            return `Please Fill In Discount Value`;
        }

        if(discount['discount_conditions'] && discount['discount_conditions'] !== 'none' && discount['discount_conditions_value'] === '') {
            return `Please Fill In Discount Conditions Value`;
        }
    }

    return '';
};

function Discounts(props) {
    const history = useHistory();
    const params = useParams();

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const discountConditionsList = [
        { value: "none", name: "None" },
        { value: "minimum_order_value", name: "Minimum Order Value" },
        { value: "minimum_order_quantity", name: "Minimum Order Quantity" },
    ]

    const discountTypeList = [
        { value: "percentage", name: "Percentage" },
        { value: "amount", name: "Amount" },
        { value: "buy_one_get_one", name: "Buy One Get One" },
        { value: "free_shipping", name: "Free Shipping" },
    ]

    const allStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "70vw",
            height: screenSize.width <= 800 ? "65vh" : screenSize.width <= 1100 ? "75vh" : "62vh",
            outline: "none",
            padding: "15px",
        },
    };

    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [selectedStartTime, setSelectedStartTime] = useState();
    const [selectedEndTime, setSelectedEndTime] = useState();

    const [entireOrderChecked, setEntireOrderChecked] = useState(false);
    const [selectedProductsModalFlag, setSelectedProductsModalFlag] = useState(false);
    const [selectedCollectionsModalFlag, setSelectedCollectionsModalFlag] = useState(false);

    const [searchProductsInput, setSearchProductsInput] = useState("");
    const [searchCollectionInput, setSearchCollectionInput] = useState("");
    const [noMatch, setNoMatch] = useState(false);
    const [productsList, setProductsList] = useState([]);
    const [collectionsList, setCollectionsList] = useState([]);

    const [productsIdList, setProductsIdList] = useState([]);
    const [productsItemList, setProductsItemList] = useState([]);
    const [productsParentIdList, setProductsParentIdList] = useState([]);

    const [collectionsIdList, setCollectionsIdList] = useState([]);
    const [collectionsItemList, setCollectionsItemList] = useState([]);
    const [modalMe, setModalMe] = useState(false)

    const [formValue, setFormValue] = useState({
        discount_code: "",
        discount_conditions: "",
        discount_conditions_value: "",
        start_datetime: "",
        end_datetime: "",
        discount_type: "",
        discount_value: "",
        discount_entire_order: false,
        discount_products_ids: [],
        discount_collection_ids: []
    });

    useEffect(() => {
        getSteps()
        if(params.id !== 'create'){
            getDiscountDetails();
        }
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    }

    const getDiscountDetails = async () => {
        await getMethod(urls.getDiscountDetails + params.id + "/").then((response) => {
            if(response.status === 200 | response.status === 201) {
                setFormValue((prevState) => ({
                    ...formValue,
                    ['discount_code']: response.data.response.discount_code,
                    ['discount_conditions']: response.data.response.discount_conditions,
                    ['discount_conditions_value']: response.data.response.discount_conditions_value,
                    ['discount_type']: response.data.response.discount_type,
                    ['discount_value']: response.data.response.discount_value,
                    ['discount_entire_order']: response.data.response.discount_entire_order,
                }));

                setEntireOrderChecked(response.data.response.discount_entire_order);
                setSelectedStartDate(response.data.response.start_datetime);
                setSelectedStartTime(response.data.response.start_datetime);
                setSelectedEndDate(response.data.response.end_datetime);
                setSelectedEndTime(response.data.response.end_datetime);

                if(response.data.response.discount_collection_ids.length > 0) {
                    setCollectionsItemList(response.data.response.discount_collection_ids);
                    
                    const list = [...collectionsIdList];
                    response.data.response.discount_collection_ids.forEach((x, i) => {
                        list.push(x.id)
                    });
                    setCollectionsIdList(list);
                }

                if(response.data.response.discount_products_ids.length > 0) {
                    //setCollectionsItemList(response.data.response.discount_products_ids);
                    
                    const list = [...productsIdList];
                    const qlist = [...productsItemList];
                    response.data.response.discount_products_ids.forEach((x, i) => {
                        list.push(x.id)
                        qlist.push({ "id": x.id, "parent_id": '', "images": x.images, "product_name": x.product_name, "upc": x.upc, "partner_sku": x.partner_sku, "price": x.price, "compare_at_price": x.compare_at_price });
                    });
                    setProductsIdList(list);
                    setProductsItemList(qlist);
                }
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCheckbox = () => {
        setFormValue((prevState) => ({
            ...prevState,
            ['discount_entire_order']: !entireOrderChecked,
        }));

        setEntireOrderChecked(!entireOrderChecked);
    };

    const viewAllSelectedProducts = () => {
        setSelectedProductsModalFlag(true);
    };

    const closeAllSelectedProduct = (value) => {
        setSelectedProductsModalFlag(false);

        if(value === 'cancle') {
            setProductsIdList([]);
            setProductsList([]);
            setSearchProductsInput("");
            setProductsParentIdList([]);
            setProductsItemList([]);
        }
    };

    const viewAllSelectedCollections = () => {
        setSelectedCollectionsModalFlag(true);
    };

    const closeAllSelectedCollections = (value) => {
        setSelectedCollectionsModalFlag(false);

        if(value === 'cancle') {
            setCollectionsList([]);
            setCollectionsIdList([]);
            setCollectionsItemList([]);
        }
    };

    const debounce = (func, delay) => {
        return (...args) => {
            if (timeOutId) clearTimeout(timeOutId);

            timeOutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const handleSearchProductInputChange = (e) => {
        setSearchProductsInput(e.target.value);
        debounceProductsCall(e.target.value);
    };

    const handleSearchCollectionInputChange = (e) => {
        setSearchCollectionInput(e.target.value);
        debounceCollectionCall(e.target.value);
    };

    const getSearchProduct = async (text) => {
        let storeId = getStoreId();
        if (text !== "") {
            await getMethod(urls.aboutStore + storeId + "/searchproduct/?product_name=" + text).then((response) => {
                if (response.status === 200) {
                    if (response.data.results.data.length > 0) {
                        setNoMatch(false);
                        setProductsList(response.data.results.data);
                    } else {
                        setNoMatch(true);
                    }
                }
            });
        } else {
            setNoMatch(false);
            setProductsList([])
        }
    };

    const getSearchCollection = async (text) => {
        let storeId = getStoreId();
        if (text !== "") {
            await getMethod(urls.getCollectionSearch + "?collection_name=" + text).then((response) => {
                if (response.status === 200) {
                    setCollectionsList(response.data.response);
                }
            }).catch((error) => {
                console.log("error", error);
            });

        } else {
            setNoMatch(false);
            setCollectionsList([])
        }
    };

    const debounceProductsCall = debounce(getSearchProduct, 500);

    const debounceCollectionCall = debounce(getSearchCollection, 500);

    function addRemoveProductbutton(value) {
        const flag = productsIdList.includes(value);
        return flag;
    }
    function addRemoveProductsbutton(value) {
        const flag = productsParentIdList.includes(value);
        return flag;
    }

    const handleProductsAdd = (event, value, index, type, parentId) => {
        const list = [...productsIdList];
        const qlist = [...productsItemList];
        const plist = [...productsParentIdList]

        if(type === 'parent' && productsList[index].children.length > 0) {
            productsList[index].children.forEach((x, i) => {
                if (!productsIdList.includes(x.id)) {
                    list.push(x.id);
                    qlist.push({ "id": x.id, "parent_id": parentId, "images": x.images, "product_name": x.product_name, "upc": x.upc, "partner_sku": x.partner_sku, "price": x.price, "compare_at_price": x.compare_at_price });
                }
            });

            plist.push(value.id);
            setProductsParentIdList(plist);
        } else {
            list.push(value.id);
            qlist.push({ "id": value.id, "parent_id": parentId, "images": value.images, "product_name": value.product_name, "upc": value.upc, "partner_sku": value.partner_sku, "price": value.price, "compare_at_price": value.compare_at_price });
        }

        setProductsIdList(list);
        setProductsItemList(qlist);
    }

    const handleProductsRemove = (event, id, index, type, parentId) => {
        const list = [...productsIdList];
        const qlist = [...productsItemList];
        const plist = [...productsParentIdList];

        if(type === 'parent' && productsList[index].children.length > 0) {
            productsList[index].children.forEach((x, i) => {
                let indexId = list.indexOf(x.id);
                list.splice(indexId, 1);

                qlist.splice(indexId, 1);
            });

            let indexpId = list.indexOf(parentId);
            plist.splice(indexpId, 1);
        } else {
            let indexId = list.indexOf(id);
            list.splice(indexId, 1);

            qlist.splice(indexId, 1);

            let indexpId = list.indexOf(parentId);
            plist.splice(indexpId, 1);
        }

        setProductsIdList(list);
        setProductsItemList(qlist);
        setProductsParentIdList(plist);
    }

    const addRemoveCollectionbutton = (value) => {
        const flag = collectionsIdList.includes(value);
        return flag;
    }

    const handleCollectionAdd = async (event, id, name) => {
        const list = [...collectionsIdList];
        list.push(id);
        setCollectionsIdList(list);

        const qlist = [...collectionsItemList];
        qlist.push({ "id": id, "collectionName": name });
        setCollectionsItemList(qlist);
    };

    const handleCollectionRemove = async (event, id) => {
        const list = [...collectionsIdList];
        let index = list.indexOf(id);
        list.splice(index, 1);
        setCollectionsIdList(list);

        const qlist = [...collectionsItemList];
        qlist.splice(index, 1);
        setCollectionsItemList(qlist);
    };

    const handleCloseProductsList = () => {
        setSearchProductsInput("")
        setProductsList([]);
    }

    const handleCloseCollectionList = () => {
        setSearchCollectionInput("")
        setCollectionsList([]);
    }

    const submitData = async () => {
        formValue['start_datetime'] = `${moment(selectedStartDate).format('YYYY-MM-DD')} ${moment(selectedStartTime).format('HH:mm')}`;
        formValue['end_datetime'] = `${moment(selectedEndDate).format('YYYY-MM-DD')} ${moment(selectedEndTime).format('HH:mm')}`;
        formValue['discount_products_ids'] = productsIdList;
        formValue['discount_collection_ids'] = collectionsIdList;

        const error = checkDiscount(formValue);

        if(error) {
            toast.error(error);
        } else {
            if (params.id === "create") {
                await postMethodWithToken(urls.postDiscountDetails, formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.data.response);
                        history.push('/promotions/discounts')
                    } else {
                        toast.error(response.data.response);
                    }
                });
            } else {
                await putMethodWithToken(urls.updateDiscountDetails + params.id + "/", formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.data.response);
                        history.push('/promotions/discounts')
                    } else {
                        toast.error(response.data.response);
                    }
                });
            }
        }
    }

    const showProductImage = (item) => {
        if (item.images.length > 0) {
            return (
                <img height="50" width="50" style={{ marginLeft: "15px" }} src={item.images[0].original} alt="" />
            );
        } else {
            return <img height="50" width="50" style={{ marginLeft: "15px" }} src={NoImage} alt="" />;
        }
    };

    return (
        <div className={props.menu ? "screenLeftMargin w-100" :"screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mt-3 mr-3">
                <div onClick={() => history.push("/promotions/discounts")} style={{ cursor: "pointer" }}>
                    <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Discounts</a>
                </div>
            </div>

            <div className="row ml-3 mt-2 mr-3">
                <div className="col-12">
                    <h1 className="heading-title">{"Create Discount"}</h1>
                </div>
            </div>

            <div className="row ml-3 mr-3">
                <div className="col-12">
                    <ValidatorForm>
                        <div className="row mt-3">
                            <div className="col-2 d-flex align-items-center">
                                Create Discount Code
                            </div>

                            <div className="col-4">
                                <TextValidator
                                    required
                                    inputProps={{ maxLength: 20 }}
                                    name="discount_code"
                                    label="Discount Code"
                                    variant="outlined"
                                    value={formValue.discount_code}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-12 muiDescripationInfo">
                                This code will be used by customers at the checkout to activate at discount.
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-12">
                                <div style={{border: "1px solid #F8F8F8"}}></div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-center">
                                        Discount Type
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-12">
                                                <FormControl variant="outlined">
                                                    <InputLabel required id="discount-type">
                                                        Discount Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="discount-type"
                                                        id="discount-type-dropdown"
                                                        label="Discount Type"
                                                        name="discount_type"
                                                        onChange={handleInputChange}
                                                        name="discount_type"
                                                        value={formValue.discount_type}
                                                        >
                                                            {discountTypeList.map((item) => {
                                                                return (
                                                                    <MenuItem value={item.value}> {item.name} </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </div> 
                                            {formValue.discount_type && (formValue.discount_type === 'percentage' || formValue.discount_type === 'amount') &&
                                                <div className="col-12 mt-3">
                                                    <TextValidator
                                                        inputProps={{ maxLength: 20 }}
                                                        name="discount_value"
                                                        label="Discount Value"
                                                        variant="outlined"
                                                        value={formValue.discount_value}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="row">
                                    <div className="col-4 d-flex align-items-center">
                                        Discount Applies to
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="disclose">
                                                    <Checkbox
                                                        defaultChecked
                                                        color="primary"
                                                        checked={entireOrderChecked}
                                                        onChange={() => handleCheckbox()}
                                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                                    />
                                                    <span className="muiDescripationInfoCheckbox">Entire order?</span>
                                                </div>
                                            </div>

                                            {!entireOrderChecked &&
                                                <> 
                                                    <div className="col-12 mt-3">
                                                        <div className="defaultInput">
                                                            <a onClick={viewAllSelectedProducts}>Selected Products ({productsIdList.length})</a>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-3">
                                                        <div className="defaultInput">
                                                            <a onClick={viewAllSelectedCollections}>Selected collections ({collectionsIdList.length})</a>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <div style={{border: "1px solid #F8F8F8"}}></div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-2 d-flex align-items-center">
                                Discount Conditions
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        <FormControl variant="outlined">
                                            <InputLabel required id="discount-conditions">
                                                Discount Conditions
                                            </InputLabel>
                                            <Select
                                                labelId="discount-conditions"
                                                id="discount-conditions-dropdown"
                                                label="Discount Conditions"
                                                onChange={handleInputChange}
                                                name="discount_conditions"
                                                value={formValue.discount_conditions}
                                                >
                                                    {discountConditionsList.map((item) => {
                                                        return (
                                                            <MenuItem value={item.value}> {item.name} </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {formValue.discount_conditions && formValue.discount_conditions !== 'none' &&
                                        <div className="col-12 mt-3">
                                            <TextValidator
                                                inputProps={{ maxLength: 20 }}
                                                name="discount_conditions_value"
                                                label="Discount Conditions Value"
                                                variant="outlined"
                                                value={formValue.discount_conditions_value}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    }
                                </div>  
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <div style={{border: "1px solid #F8F8F8"}}></div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-2 d-flex align-items-center">
                                Start Day
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-6">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                required
                                                InputProps={{ disableUnderline: true }}
                                                inputVariant="outlined"
                                                label="Start Date"
                                                value={selectedStartDate}
                                                onChange={date => setSelectedStartDate(date)}
                                                format="yyyy/MM/dd"
                                                disablePast
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="col-6">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                required
                                                InputProps={{ disableUnderline: true }}
                                                inputVariant="outlined"
                                                label="Start Time"
                                                mask="__:__ _M"
                                                value={selectedStartTime}
                                                onChange={date => setSelectedStartTime(date)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-2 d-flex align-items-center">
                                End Day
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-6">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                required
                                                inputStyle={{ textAlign: 'center' }}
                                                inputVariant="outlined"
                                                label="End Date"
                                                format="yyyy/MM/dd"
                                                value={selectedEndDate}
                                                onChange={date => setSelectedEndDate(date)}
                                                disablePast
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="col-6">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                required
                                                InputProps={{ disableUnderline: true }}
                                                inputVariant="outlined"
                                                label="End Time"
                                                mask="__:__ _M"
                                                value={selectedEndTime}
                                                onChange={date => setSelectedEndTime(date)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-end">
                                <Button type="onSubmit" onClick={() => history.push("/promotions/discounts")} name="Cancel" className="btn btn-cancle mr-3" />
                                <Button type="onSubmit" onClick={() => submitData()} name="Save" className="btn btn-primary" />
                            </div>
                        </div>
                    </ValidatorForm>
                    <OnHoldModal isOnHold={modalMe} />
                </div>
            </div>

            <Modal isOpen={selectedProductsModalFlag} style={allStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row mr-3 ml-3">
                    <div className="col-12">
                        <OutsideClickHandler onOutsideClick={() => handleCloseProductsList()}>
                            <div className="row mt-3">
                                <div className="h5-label col-12">
                                    Select Products
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <TextField
                                        name="search_products"
                                        label="Search Products"
                                        variant="outlined"
                                        value={searchProductsInput}
                                        onChange={(event) => handleSearchProductInputChange(event)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3 discountProductList">
                                <div className="col-12">
                                    <div className="listItems">
                                        {productsList.length > 0 && productsList.map((x, i) => {
                                            return(
                                                <>
                                                    {i === 0 &&
                                                        <Row className="mt-3 mb-3 headers" style={{ padding: "10px", borderBottom: "1px solid #C0C0C0" }}>
                                                            <Col md={10} sm={10} xs={10}>
                                                                <Row >
                                                                    <Col md={2} sm={2} xs={2}>
                                                                        <Row>
                                                                            <Col md={6} sm={6} xs={6}>
                                                                                S.No.
                                                                            </Col>
                                                                            <Col md={6} sm={6} xs={6}>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={2} sm={2} xs={2}>
                                                                        Product Name
                                                                    </Col>
                                                                    <Col md={2} sm={2} xs={2}>
                                                                        UPC
                                                                    </Col>
                                                                    <Col md={2} sm={2} xs={2}>
                                                                        SKU
                                                                    </Col>
                                                                    <Col md={2} sm={2} xs={2}>
                                                                        Sales Price
                                                                    </Col>
                                                                    <Col md={2} sm={2} xs={2}>
                                                                        list Price
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col md={2} sm={2} xs={2}>
                                                                Action
                                                            </Col>
                                                        </Row>
                                                    }

                                                    <Row className="productlist">
                                                        <Col md={10} sm={10} xs={10}>
                                                            <Row >
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <Row>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                            <p key={x.id}>{i + 1}.</p>
                                                                        </Col>
                                                                        <Col md={6} sm={6} xs={6}>
                                                                            {showProductImage(x)}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={x.id}>{x.product_name}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={x.id}>{x.upc}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={x.id}>{x.partner_sku}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={x.id}>{x.price}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={x.id}>{x.compare_at_price}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={2} sm={2} xs={2}>
                                                            {addRemoveProductsbutton(x.id) === true ?
                                                                <Button data-key={x.id} name="- Added" className="button" type="primary" onClick={(event) => handleProductsRemove(event, x.id, i, 'parent', x.id)} />
                                                                :
                                                                <Button data-key={x.id} name="+ Add" className="button" type="primary" onClick={(event) => handleProductsAdd(event, x, i, 'parent', x.id)} />
                                                            }
                                                        </Col>
                                                    </Row>

                                                    {x.children.map((value, index) => {
                                                        return (
                                                            <Row className="productlist" >
                                                                <Col md={10} sm={10} xs={10}>
                                                                    <Row>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                            <Row>
                                                                                <Col md={2} sm={2} xs={2}>
                                                                                </Col>
                                                                                <Col md={6} sm={6} xs={6}>
                                                                                    {showProductImage(value)}
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                            <p key={value.id}>{value.product_name}</p>
                                                                        </Col>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                            <p key={value.id}>{value.upc}</p>
                                                                        </Col>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                            <p key={value.id}>{value.partner_sku}</p>
                                                                        </Col>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                            <p key={value.id}>{value.price}</p>
                                                                        </Col>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                            <p key={value.id}>{value.compare_at_price}</p>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    {addRemoveProductbutton(value.id) === true ?
                                                                        <Button data-key={value.id} name="- Added" className="button" type="primary" onClick={(event) => handleProductsRemove(event, value.id, index, 'child', x.id)} />
                                                                        :
                                                                        <Button data-key={value.id} name="+ Add" className="button" type="primary" onClick={(event) => handleProductsAdd(event, value, index, 'child', x.id)} />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <table className="listSectionAddItem">
                                        {productsItemList.map((items, indexes) => {
                                            return (
                                                <>
                                                    {indexes === 0 &&
                                                        <tr className="headers" style={{ textAlign: "center", alignItems: "center" }}>
                                                            <th style={{ width: "8%" }} >S.NO.</th>
                                                            <th style={{ width: "15%" }}>Product Name</th>
                                                            <th style={{ width: "15%" }}>UPC</th>
                                                            <th style={{ width: "15%" }}>SKU</th>
                                                            <th style={{ width: "15%" }}>Sales Price</th>
                                                            <th style={{ width: "15%" }}>list Price</th>
                                                            <th style={{ width: "15%" }}>Actions</th>
                                                        </tr>
                                                    }

                                                    <tr key={indexes + 1} style={{ cursor: "pointer", backgroundColor: "#F2F2F2", height: "60px", textAlign: "center", alignItems: "center" }}>
                                                        <td style={{ display: "flex", alignItems: "center", textAlign: "center", margin: "auto", height: "60px" }}>
                                                            <p style={{ marginRight: "25px", marginLeft: "25px", display: "flex", alignItems: "center", textAlign: "center", margin: "auto", height: "60px" }}>{indexes + 1}.</p>
                                                            {showProductImage(items)}
                                                        </td>
                                                        <td>{items.product_name}</td>
                                                        <td>{items.upc}</td>
                                                        <td>{items.partner_sku}</td>
                                                        <td>{items.price}</td>
                                                        <td>{items.compare_at_price}</td>
                                                        <td >
                                                            <Button data-key={items.id} name="Remove" className="button" onClick={(event) => handleProductsRemove(event, items, indexes, 'child', items.parent_id)} />
                                                        </td>
                                                    </tr>

                                                </>
                                            );
                                        })}
                                    </table>
                                </div>
                            </div>

                            <div className="row mt-3 mb-3">
                                <div className="col-12">
                                    <Button type="onSubmit" name="Save" onClick={() => closeAllSelectedProduct('save')} className="btn btn-primary ml-3 float-right"/>
                                    <Button type="onSubmit" onClick={() => closeAllSelectedProduct('cancel')} name="Cancel" className="btn btn-cancle float-right"/>
                                </div>
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={selectedCollectionsModalFlag} style={allStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row mr-3 ml-3">
                    <div className="col-12">
                        <OutsideClickHandler onOutsideClick={() => handleCloseCollectionList()}>
                            <div className="row mt-3">
                                <div className="h5-label col-12">
                                    Select Collections
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <TextField
                                        name="search_collection"
                                        label="Search Collections"
                                        variant="outlined"
                                        value={searchCollectionInput}
                                        onChange={(event) => handleSearchCollectionInputChange(event)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3 discountCollectionList">
                                <div className="col-12">
                                    <div className="listItems">
                                        {collectionsList.length > 0 && collectionsList.map((x, i) => {
                                            return(
                                                <>
                                                    {i === 0 &&
                                                        <Row className="mt-3 mb-3 headers" style={{ padding: "10px", borderBottom: "1px solid #C0C0C0" }}>
                                                            <Col md={10} sm={10} xs={10}>
                                                                <Row >
                                                                    <Col md={12} sm={12} xs={12}>
                                                                        Collection Name
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col md={2} sm={2} xs={2}>
                                                                Action
                                                            </Col>
                                                        </Row>
                                                    }

                                                    <Row className="collectionlist">
                                                        <Col md={10} sm={10} xs={10}>
                                                            <Row >
                                                                <Col md={12} sm={12} xs={12}>
                                                                    {x.collectionName}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={2} sm={2} xs={2}>
                                                            {addRemoveCollectionbutton(x.id) === true ?
                                                                <Button data-key={x.id} name="- Added" className="button" type="primary" onClick={(event) => handleCollectionRemove(event, x.id)} />
                                                                :
                                                                <Button data-key={x.id} name="+ Add" className="button" type="primary" onClick={(event) => handleCollectionAdd(event, x.id, x.collectionName)} />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <table className="listSectionAddItem">
                                        {collectionsItemList.map((items, indexes) => {
                                            return (
                                                <>
                                                    {indexes === 0 &&
                                                        <tr className="headers" style={{ textAlign: "center", alignItems: "center" }}>
                                                            <th style={{ width: "10%" }} >S.NO.</th>
                                                            <th style={{ width: "75%" }}>Collection Name</th>
                                                            <th style={{ width: "15%" }}>Actions</th>
                                                        </tr>
                                                    }

                                                    <tr key={indexes + 1} style={{ cursor: "pointer", height: "60px", backgroundColor: "#F2F2F2", textAlign: "center", alignItems: "center" }}>
                                                        <td>
                                                            {indexes + 1}.
                                                        </td>
                                                        <td>{items.collectionName}</td>
                                                        <td >
                                                            <Button data-key={items.id} name="Remove" className="button" onClick={(event) => handleCollectionRemove(event, items.id)} />
                                                        </td>
                                                    </tr>

                                                </>
                                            );
                                        })}
                                    </table>
                                </div>
                            </div>

                            <div className="row mt-3 mb-3">
                                <div className="col-12">
                                    <Button type="onSubmit" name="Save" onClick={() => closeAllSelectedCollections('save') } className="btn btn-primary ml-3 float-right"/>
                                    <Button type="onSubmit" onClick={() => closeAllSelectedCollections('cancel') } name="Cancel" className="btn btn-cancle float-right"/>
                                </div>
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default Discounts;