import React from 'react';
import './BussinessCred.scss';

const BussinessCred = () => {
    let financialDetails =  window.$ReviewStoreDetails;
    return (
        <div>
            <div className='BussinessBox mt-3'>
                <div className="row pt-3 pl-5">
                    {financialDetails.store_detail && financialDetails.store_detail[0].business_address && financialDetails.store_detail[0].business_address.business_doc.length > 0 &&
                        <img 
                            alt=""
                            style={{width: "200px", height: "200px"}}
                            src={financialDetails.store_detail[0].business_address.business_doc[0].file}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default BussinessCred;
