import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from "react-toastify";
import Modal from "react-modal";
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import SpinnerLoader from "../../../../components/loader/spinnerLoader";
import Button from "../../../../components/button/button";
import { getMethod, deleteMethodWithToken, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import DiscountImage from "../../../../assets/images/discount.png";
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";

function Discounts(props) {
    const history = useHistory();

    const itemsPerPage = 20;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const [loading, setLoading] = useState(false);
    const [discountList, setDiscountList] = useState([]);

    const [confirmation, setConfirmation] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [modalMe, setModalMe] = useState(false)

    useEffect(() => {
        getDiscountList();
        getSteps()
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    }

    const getDiscountList = async () => {
        setLoading(true);
        await getMethod(urls.getMerchantDiscountList).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setDiscountList(response.data.results.data);
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setLoading(false);
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)
        getSteps()
        await getMethod(urls.getMerchantDiscountList + "?page=" + value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setDiscountList(response.data.results.data);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    const deleteProduct = async (id) => {
        // setConfirmation(false);
        // setLoading(true);
        await deleteMethodWithToken(urls.deleteMerchantDiscountList + id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success("Discount Deleted Successfully.");
                getSteps()
                getDiscountList();
                setConfirmation(false);
                setLoading(true);
            }else{
                toast.error(response.data.response)
            }
        });
        // setLoading(false);
    };

    const ToDelete = (id) => {
        setConfirmation(true);
        getSteps()
        setIndexIdToDelete(id);
    };

    const discountStatusUpdate = async (status, id) => {
        let body = {
            is_active: status
        }

        await putMethodWithToken(urls.putDiscountStatus + id + "/", body).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response.data.response)
                getDiscountList();
            } else {
                toast.error(response.data.response);
            }
        });
    }

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mt-3 mr-3">
                <div className="col-6">
                    <h1 className="heading-title">{"Discounts"}</h1>
                </div>
                <div className="col-6 d-flex justify-content-end">
                    <Button className="btn btn-cancle btn-lg" name="Create Discounts" onClick={() => history.push("/promotions/discount/create")}></Button>
                </div>
            </div>

            {count > 1 &&
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <OnHoldModal isOnHold={modalMe} />
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mt-3 mr-3">
                {!loading ?
                    <div className="col-12">
                        <div className="row">
                            <div className="col-2 f-600">S.No.</div>
                            <div className="col-2 f-600">Code</div>
                            <div className="col-2 f-600">Type</div>
                            <div className="col-2 f-600">{"Start Date"}</div>
                            <div className="col-2 f-600">End Date</div>
                            <div className="col-1 f-600">Status</div>
                            <div className="col-1 f-600">Actions</div>
                        </div>
                        {discountList && discountList.length > 0 ? discountList.map((item, index) => {
                            return (
                                <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6" }}>
                                    <div className="col-2" style={{ cursor: "pointer" }} onClick={() => history.push("/promotions/discount/" + item.id)}>
                                        {index + 1}.
                                    </div>
                                    <div className="col-2" style={{ cursor: "pointer" }} onClick={() => history.push("/promotions/discount/" + item.id)}>
                                        {item.discount_code}
                                    </div>

                                    <div className="col-2" style={{ cursor: "pointer" }} onClick={() => history.push("/promotions/discount/" + item.id)}>
                                        <span><b>Type:</b> {item.discount_type}</span><br />
                                        <span><b>Value:</b> {item.discount_value}</span>
                                    </div>

                                    <div className="col-2" style={{ cursor: "pointer" }} onClick={() => history.push("/promotions/discount/" + item.id)}>
                                        {moment(item.start_datetime).format('YYYY-MM-DD HH:mm ')}
                                    </div>

                                    <div className="col-2" style={{ cursor: "pointer" }} onClick={() => history.push("/promotions/discount/" + item.id)}>
                                        {moment(item.end_datetime).format('YYYY-MM-DD HH:mm')}
                                    </div>

                                    <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                        <Switch
                                            checked={item.is_active}
                                            className={(item.is_active) ? 'selected_sett' : 'unselected_sett'}
                                            onColor="#00A739"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            name="status"
                                            onChange={(event) => discountStatusUpdate(event, item.id)}
                                        />
                                    </div>

                                    <div className="col-1" >
                                        <EditIcon fontSize="small" onClick={() => history.push("/promotions/discount/" + item.id)} style={{ cursor: "pointer", color: "#000000", marginRight: "5px" }} />
                                        <DeleteIcon fontSize="small" onClick={() => ToDelete(item.id)} style={{ cursor: "pointer", color: "#eb2813", marginLeft: "5px" }} />
                                    </div>
                                </div>
                            )
                        })
                            :
                            <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6", textAlign: "center", alignItems: "center", height: "75px" }}>
                                <div className="col-12 " >No discounts code found.</div>
                            </div>
                        }
                        <OnHoldModal isOnHold={modalMe} />
                    </div>
                    :
                    <div className="col-12">
                        <SpinnerLoader />
                    </div>
                }
            </div>

            {count > 1 &&
                <div className="row ml-3 mr-3 mt-3 mb-5 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <OnHoldModal isOnHold={modalMe} />
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 mt-3">
                <div className="col-12">
                    <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                        <div className="mt-4 mb-4">
                            <div className="row">
                                <OnHoldModal isOnHold={modalMe} />
                                <div className="col-12 text-center">
                                    Are you sure you want to delete this coupon code?
                                </div>
                                <div className="col-12 mt-4  d-flex justify-content-center">
                                    <Button type="onSubmit" onClick={() => { setConfirmation(false); getSteps() }} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                                    <Button type="onSubmit" onClick={() => { deleteProduct(indexIdToDelete); }} name="Delete" className="btn btn-sm btn-primary" />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
export default Discounts;