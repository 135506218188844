import React, { useEffect, useState } from "react";
import "./table.scss";
import NoImage from "../../assets/images/storelisting.png";

function CustomizedTables(props) {
    const [listData, setListData] = useState(props.storeData);
    const [checkStatus, setCheckStatus] = useState(false);
    const [expandIndex, setExpandIndex] = useState("");
    
    const handleChange = (index) => {
        setCheckStatus(!checkStatus);
    };

    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
        } else {
            setExpandIndex(index);
        }
    };

    return (
        <div className="col-12">
            <div className="row mt-2">
                <div className="col-1 f-600">S No.</div>
                <div className="col-3 f-600">Product Name</div>
                <div className="col-2 f-600">UPC</div>
                <div className="col-2 f-600">SKU</div>
                <div className="col-1 f-600">Qty.</div>
                <div className="col-1 f-600">Cost</div>
                <div className="col-2 f-600">Price</div>
            </div>

            {listData && listData !== "" && listData.map((item, index) => {
                return (
                    <>
                        <div className="row ft-14 mt-2 pt-3 pb-3" key={index + 1} style={{background: "#ECECEC"}}>
                            <div className="col-1 d-flex productImg" style={{alignItems: "center"}}>
                                {item.structure === "parent" && expandIndex !== index ? (
                                    <i style={{marginRight: "10px", fontSize: "25px", verticalAlign: "middle", cursor: "pointer"}} className="fa fa-angle-down iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                ) : item.structure === "parent" && expandIndex === index ? (
                                    <i style={{marginRight: "10px", fontSize: "25px", verticalAlign: "middle", cursor: "pointer"}} className="fa fa-angle-up iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                ) : null}

                                {item.images && item.images.slice(0, 1).map((item, i) => {
                                    return (
                                        <img height="45" width="45" src={item.original} alt=""/>
                                    );
                                })}

                                {typeof json_data !== "undefined" && item.images.length == 0 &&
                                    <img height="45" width="45" src={NoImage} alt="" />
                                }
                            </div>
                            <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                {listData[index].product_name}
                            </div>
                            <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                {listData[index].upc}
                            </div>
                            <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                {listData[index].partner_sku}
                            </div>
                            <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                {listData[index].num_in_stock}
                            </div>
                            <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                {listData[index].invoice_cost}
                            </div>
                            <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                {listData[index].price}
                            </div>
                        </div>

                        {expandIndex === index && listData[index].children.map((items, index) => {
                            return (
                                <div className="row ft-14 mt-3 pt-3 pb-3" key={index + 1} style={{background: "#ECECEC"}}>
                                    <div className="col-1 d-flex productImg" style={{alignItems: "center"}}>
                                        {items.images && items.images.slice(0, 1).map((items, i) => {
                                            return (
                                                <img height="45" width="45" src={items.original} alt=""/>
                                            );
                                        })}

                                        {typeof json_data !== "undefined" && items.images.length == 0 ? (
                                            <img height="45" width="45" src={NoImage} alt="" />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                        {items.product_name}
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        {items.upc}
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        {items.partner_sku}
                                    </div>
                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        {items.num_in_stock}
                                    </div>
                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        {items.invoice_cost}
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        {items.price}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                );
            })}
        </div>
    );
}
export default CustomizedTables;
