import React from "react";
import emptyStore from '../../assets/images/storelisting.png'
import "./storeListingEmpty.scss"
import { useHistory } from 'react-router-dom';

function StoreListing() {
    const history = useHistory()
    const skip = () => {
        history.push("/financials")
    };
    
    return (
        <div >
            <div className="storeListingEmpty" style={{display:"flex",justifyContent:"center"}}><img src={emptyStore} alt="" /></div>
        </div>

    )
}
export default StoreListing