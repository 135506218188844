import React, { useState, useRef, useEffect } from "react";
import "./createStore.scss";
import ShopezLogo from "../../assets/images/shopez_logo.png";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";
import Button from "../../components/button/button";
import { useHistory } from "react-router-dom";
import Geosuggest from "react-geosuggest";
import { postMethod, postMethodWithPlatformWithoutToken } from "../../utils/services/api";
import urls from "../../utils/services/apiUrl.json";
import { toast } from "react-toastify";
import USADB from "../../assets/images/usa.png";
import canadaDB from "../../assets/images/canada.png";
import indiaDB from "../../assets/images/india.png"
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import LocationDb from "../../assets/images/location_icon.png";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OtpInput from 'react-otp-input';
import tellUsIcon from "../../assets/images/tell-us-store/tell-us-icon.svg";
import SignUpLogo from "../../assets/images/tell-us-store/signUpLogo.png"
import LogoOTP from "../../assets/images/tell-us-store/otpIcon.svg";
import tellUsBanner from "../../assets/images/tell-us-store/tell-us-banner.png";
import otpBanner from "../../assets/images/tell-us-store/Otpverification.png";
import ShopezBack from "../../assets/images/arrow_left_alt.svg";
import InputAdornment from "@material-ui/core/InputAdornment"
import FileCopyIcon from '@material-ui/icons/FileCopy';


const CreateStore = () => {
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [storeName, setStoreName] = useState("");
    const [recaptchaVerification, setRecaptchaVerification] = useState("");
    const [otpVerification, setOtpVerification] = useState("");
    const [loginScreenVisible, setLoginScreenVisible] = useState(true);

    const [otpValue, setOtpValue] = useState("");
    const [countInvalid, setCountInvalid] = useState(0);
    const [count, setCount] = useState(60)
    const [disable, setDisable] = useState(false)


    const [storeDetails, setStoreDetails] = useState({
        owner_name: "",
        email: "",
        country_code: "+1",
        phone_number: "",
        store_company_name: "",
        location: "",
        country: "",
    });
    const countriesFlag = [
        {
            value: "USA",
            flag: USADB,
            code: "+1",
        },
        {
            value: "Canada",
            flag: canadaDB,
            code: "+1",
        },
        {
            value: "India",
            flag: indiaDB,
            code: "+91"
        }
    ];

    const [countryCodeSelect, setCountryCodeSelect] = useState("Canada");
    const history = useHistory();
    const geosuggestEl = useRef("");
    const initialValue = [];
    const google = window.google;

    const gotoBack = async () => {
        history.push("/");
    };

    const promptPermission = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            if (position.coords.longitude) {
                alert("Location permission is already allowed");
                codeLatLng(position.coords.latitude, position.coords.longitude);
            }
        });
    };

    const codeLatLng = (lat, lng) => {
        let geocoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        let country = "";
        let sublocality = "";
        let locality = "";
        let postalCode = "";
        let Address = "";
        geocoder.geocode({ location: latlng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                    for (var i = 0; i < results[0].address_components.length; i++) {
                        for (var j = 0; j < results[0].address_components[i].types.length; j++) {
                            if (results[0].address_components[i].types[j] === "country") {
                                country = results[0].address_components[i].short_name.toString();
                            }
                            if (results[0].address_components[i].types[j] === "locality") {
                                locality = results[0].address_components[i].short_name.toString();
                            }
                            if (results[0].address_components[i].types[j] === "postal_code") {
                                postalCode = results[0].address_components[i].short_name.toString();
                            }
                            if (results[0].address_components[i].types[j] === "sublocality_level_2") {
                                sublocality = results[0].address_components[i].short_name.toString();
                            }
                        }
                    }
                }

                Address = sublocality + " " + country + " " + locality + " " + postalCode;
                setStoreDetails((prevState) => ({
                    ...prevState,
                    ["location"]: Address,
                }));
            }
        });
    };

    const handleOtpChange = (e) => {
        setOtpValue(e)
    }

    const gotoWelcome = async () => {
        if (
            storeDetails.owner_name !== "" &&
            storeDetails.email !== "" &&
            storeDetails.phone_number !== "" &&
            storeDetails.phone_number.substring(0).replace(/[^0-9]/g, "").length === 10 &&
            storeDetails.store_company_name !== "" &&
            storeDetails.location !== "" &&
            storeDetails.country !== "" &&
            storeName
        ) {
            let phoneString = storeDetails.phone_number;
            let requiredPhoneFormat = phoneString.substring(0);

            const auth = getAuth();
            const phoneNumber = (countryCodeSelect !== "India" ? '+1' : '+91') + requiredPhoneFormat.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

            let body = {
                email: storeDetails.email
            }

            await postMethodWithPlatformWithoutToken(urls.postEmailValidation, body, "merchant").then((response) => {
                if (response.status === 201 || response.status === 200) {
                    const appVerifier = new RecaptchaVerifier('recaptcha-container', {
                        'size': 'invisible',
                        'callback': (response) => {
                            setRecaptchaVerification(response)
                        }
                    }, auth);

                    signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((confirmationResult) => {
                        setLoginScreenVisible(false)
                        setOtpVerification(confirmationResult)
                    }).catch((error) => {
                        toast.error("Please Enter Valid Phone Number")
                    });
                } else {
                    toast.error('This Email Already Exists !!');
                    setLoginScreenVisible(true)
                }
            });

        } else {

            if (storeDetails.owner_name == "") {
                toast.error("Please Enter Owner Name");
            } else if (storeDetails.email == "") {
                toast.error("Please Enter Email Address");
            } else if (storeDetails.location == "") {
                toast.error("Please Choose Location");
            } else if (storeDetails.phone_number == "") {
                toast.error("Please Enter Phone Number");
            } else if (storeDetails.store_company_name == "") {
                toast.error("Please Enter Store Name");
            } else if (!storeName) {
                toast.error("Please Enter Valid Store Name");
            } else {
                toast.error("Please Enter Valid Details");
            }
        }
    };

    const verifiedOtp = async () => {
        if (otpValue !== '') {
            otpVerification.confirm(otpValue).then((result) => {

                createStore()

            }).catch((error) => {
                toast.error('Please Enter Valid OTP')
            });
        } else {
            toast.error('Please Enter Valid OTP')
            let countNumber = countInvalid + 1;
            setCountInvalid(countNumber);

            if (countNumber >= 3) {
                sessionStorage.clear();
                history.push("/createstore");
            }
        }
    }

    function countdown() {
        var seconds = 59;
        function tick() {
            setCount(seconds)
            seconds--;
            if (seconds >= 0) {
                setTimeout(tick, 1000);
            } else {
                setDisable(false)
            }
        }
        tick();
    }

    const sendResendOtp = () => {
        if (storeDetails.phone_number !== '') {
            let phoneString = storeDetails.phone_number;
            let requiredPhoneFormat = phoneString.substring(0);

            const authInfo = getAuth();
            const phoneNumber = (countryCodeSelect !== "India" ? '+1' : '+91') + requiredPhoneFormat.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
            // const appVerifier = new RecaptchaVerifier('recaptcha-container', {
            //     'size': 'invisible',
            //     'callback': function (response) {
            //         setRecaptchaVerification(response)
            //     },
            //     'expired-callback': function (r) {
            //         console.log("expired", r)
            //     }
            // }, authInfo)
            if (!window.recaptchaVerifier) {
                window.recaptchaVerifier = new RecaptchaVerifier(
                    "recaptcha-container",
                    {
                        size: "invisible",
                        'callback': function (response) {
                            setRecaptchaVerification(response)
                        },
                        'expired-callback': function (r) {
                            
                        }
                    },
                    authInfo
                );
            }
            window.recaptchaVerifier.render();

            signInWithPhoneNumber(authInfo, phoneNumber, window.recaptchaVerifier).then((confirmationResult) => {
                setLoginScreenVisible(false)
                setOtpVerification(confirmationResult)
                setDisable(true)
                countdown();
            }).catch((error) => {
                setLoginScreenVisible(true)
                toast.error('Phone Number Is Not Valid. Please Fill Up Details Again')
            });
        } else {
            setLoginScreenVisible(true)
            toast.error('Phone Number Is Not Valid. Please Fill Up Details Again')
        }
    }

    const createStore = async () => {
        let phoneString = storeDetails.phone_number;
        let requiredPhoneFormat = phoneString.substring(0)

        let body = {
            owner_name: storeDetails.owner_name,
            email: storeDetails.email.toLowerCase(),
            country_code: "+1",
            phone_number: requiredPhoneFormat,
            store_company_name: storeDetails.store_company_name,
            location: storeDetails.location,
            country: storeDetails.country
        };

        await postMethod(urls.applyForStore, body).then((response) => {
            if (response.status === 201) {
                toast.success(response.data.response);
                history.push("/welcome");
            } else {
                if (response.data.errors && response.data.errors.email.length > 0) {
                    toast.error(response.data.errors.email[0]);
                } else {
                    toast.error('Please Fill Up Valid Details');
                }

                setLoginScreenVisible(true)
            }
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStoreDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setStoreDetails((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const onSuggestSelect = (suggest) => {
        var streetNumber = "", route = "", locality = "", stateAddress = "", state = "", country = "", postal_code = "", addressValue = "";

        suggest && suggest.gmaps && suggest.gmaps.address_components.map((item, index) => {
            if (item.types[0] === "street_number") {
                streetNumber = item.long_name;
            }

            if (item.types[0] === "route") {
                route = item.short_name;
            }

            if (item.types[0] === "locality") {
                locality = item.long_name;
            }

            if (item.types[0] === "administrative_area_level_1") {
                state = item.long_name;
                stateAddress = item.short_name;
            }

            if (item.types[0] === "country") {
                country = item.short_name;

                if (country === 'US') {
                    setStoreDetails((prevState) => ({
                        ...prevState,
                        ["country"]: 'USA',
                    }));
                } else {
                    setStoreDetails((prevState) => ({
                        ...prevState,
                        ["country"]: 'CAN',
                    }));
                }
            }

            if (item.types[0] === "postal_code") {
                postal_code = item.long_name;
            }
        });

        if (streetNumber !== "") {
            addressValue += streetNumber;
        }

        if (route !== "" && streetNumber !== "") {
            addressValue += ", " + route;
        } else if (route !== "") {
            addressValue += route;
        }

        if (locality !== "") {
            addressValue += ", " + locality;
        }

        if (state !== "") {
            addressValue += ", " + stateAddress;
        }

        if (country !== "") {
            addressValue += ", " + country;
        }

        /*if(postal_code !== "") {
            addressValue += ", " + postal_code;
        }*/

        setStoreDetails((prevState) => ({
            ...prevState,
            ["location"]: addressValue,
        }));
        geosuggestEl.current.update(addressValue);
    };

    const handleCountryCodeChange = (event) => {
        setCountryCodeSelect(event.target.value);
    };

    useEffect(() => {
        checkStoreName();
    }, [storeDetails.store_company_name, storeName]);

    useEffect(() => { }, [storeName, storeDetails.location]);

    const checkStoreName = async () => {
        if (storeDetails.store_company_name && storeDetails.store_company_name.length > 0) {
            let body = {
                storename: storeDetails.store_company_name,
            };
            await postMethod(urls.verifyStoreName, body).then((response) => {
                if (response.status === 200) {
                    setStoreName(true);
                } else {
                    setStoreName(false);
                }
            }).catch((error) => {
                console.log("Error in APi calling", error);
            });
        }
    };



    const gotoBackback = async () => {
        history.goBack()
    };

    return (
        <React.Fragment>
            {/* <div className="row">
                    <div className="col-6">
                        <img src={tellUsBanner} />
                    </div>
                    <div className="col-6">
                        <div className="">
                            <div className="tell-us-header text-center">
                                <img src={tellUsIcon} />
                                <h1 className="title">Tell Us About Your Store</h1>
                                <p className="desc">Enter your personal details and start journey with us.</p>
                            </div>
                        </div>
                    </div>
                </div> */}



            {/* <div className="col-6 p-0 m-0">
                        <div className="createBusinessDeal container h-100">
                            <div className="back" onClick={() => gotoBack()}>
                                <ArrowBackIcon style={{color: "#fff", fontSize: "3em"}} />
                            </div>
                            <div className="logo">
                                <img className=" logosize" src={ShopezLogo} />
                            </div>
                            <div className="logo logo_text fontsize mt-3">
                                <div>“Increase your Traffic”</div>
                                <div>“More Revenue”</div>
                                <div>“Higher Exposure”</div>
                            </div>
                        </div>
                    </div> */}

            {loginScreenVisible ?
                <div className="container-fluid p-0 m-0">
                    <div className="row p-0 m-0">
                        <div className="col-sm-12 col-lg-6 forget_left">
                            <div className="forgotPasswordDeal createBusinessDeal container"
                                style={{
                                    background: `url(${tellUsBanner})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'calc(100% - 40px) calc(100% - 56px)',
                                    backgroundPosition: '20px 30px',
                                    // backgroundSize: 'cover',
                                }} >
                                <img className="logo_home img-fluid" src={ShopezLogo} />
                                <img className="logo_forgot img-fluid mobile-setting" src={tellUsBanner} />
                            </div>
                        </div>
                        <div className="loginForm col-sm-12 col-lg-6 p-0">
                            <div onClick={() => gotoBackback()} className="back_set_other" style={{ cursor: "pointer" }}>
                                <img className="back_other img-fluid" src={ShopezBack} />
                            </div>
                            <div id="create-card" className="create_card_container">
                                <div>
                                    <div className="text-center img_div "><img className="new_img_logo text-center img-fluid" src={SignUpLogo} /></div>
                                    <h1 className="heading-title text-center pb-2">Tell us about your store</h1>
                                    <p className="h6-label-tagline text-center" style={{ fontSize: "16px" }}>
                                        Enter your personal details and embark on your online journey with us.
                                    </p>

                                    <div>
                                        <ValidatorForm>
                                            <div className="">
                                                <div className="text_mng first_div_top">
                                                    <TextValidator
                                                        errorMessages={[
                                                            "Please Enter A Valid Owner Name",
                                                            "Please Enter A Valid Owner Name",
                                                            "Please Enter A Owner Name",
                                                        ]}
                                                        validators={[
                                                            "minStringLength:2",
                                                            "maxStringLength:30",
                                                            "required",
                                                        ]}
                                                        type="text"
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 20 }}
                                                        name="owner_name"
                                                        label="Business Owner name"
                                                        variant="outlined"
                                                        value={storeDetails.owner_name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="text_mng text_mng_lr">
                                                    <TextValidator
                                                        errorMessages={[
                                                            "Please Enter Email",
                                                            "Please Enter Valid Email",
                                                        ]}
                                                        validators={["required", "isEmail"]}
                                                        onChange={handleEmailChange}
                                                        name="email"
                                                        label="Email"
                                                        variant="outlined"
                                                        value={storeDetails.email}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" ">
                                                <div className="text_mng text_mng_lr">
                                                    <div className="row Geosuggest_main_setting">
                                                        <div className="  geosuggest-left-top-bottom Geosuggest_setting">
                                                            <Geosuggest
                                                                id="createstore"
                                                                ref={geosuggestEl}
                                                                placeholder="Business Address"
                                                                name="location"
                                                                fields={["address_components", "geometry"]}
                                                                types={["address"]}
                                                                country={["US", "CA"]}
                                                                initialValue={storeDetails.location}
                                                                value={storeDetails.location}
                                                                onChange={(value) =>
                                                                    setStoreDetails((prevState) => ({
                                                                        ...prevState,
                                                                        ["location"]: value,
                                                                    }))
                                                                }
                                                                onSuggestSelect={onSuggestSelect}
                                                                location={new google.maps.LatLng(lat, lng)}
                                                                radius="100"
                                                            />
                                                        </div>
                                                        {/* <div className="col-1 pl-0"> */}
                                                        <div className="inputLocationIcon">
                                                            <img onClick={() => promptPermission()} src={LocationDb} />
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className=" ">
                                                    <div className=" ">
                                                        <div className="text_mng text_mng_lr">
                                                            <FormControl variant="outlined">
                                                                <InputLabel id="cCode-select"></InputLabel>
                                                                <Select
                                                                    onChange={handleCountryCodeChange}
                                                                    value={countryCodeSelect}
                                                                    className="country_changes"
                                                                    labelId="cCode-select"
                                                                    id="cCode-dropdown"
                                                                >
                                                                    {countriesFlag.map((item) => {
                                                                        return (
                                                                            <MenuItem value={item.value}>
                                                                                <img className="flagSize" src={item.flag} alt="" />
                                                                                <span className="pl-1 h6-label">
                                                                                    {" "}{item.code}
                                                                                </span>
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                <div className="phone_attach">
                                                                    <InputMask
                                                                        mask={"(999) 999-9999"}
                                                                        maskChar="_"
                                                                        onChange={handleChange}
                                                                        value={storeDetails.phone_number}
                                                                    >
                                                                        {() => (
                                                                            <TextValidator
                                                                                required
                                                                                errorMessages={[
                                                                                    "Please Enter Phone Number",
                                                                                ]}
                                                                                validators={[
                                                                                    "required",
                                                                                ]}
                                                                                name="phone_number"
                                                                                label="Phone Number"
                                                                                className="phone_value"
                                                                                value={storeDetails.phone_number}
                                                                                variant="outlined"
                                                                                onChange={handleChange}
                                                                            />
                                                                        )}
                                                                    </InputMask>
                                                                </div>
                                                            </FormControl>
                                                        </div>
                                                        <div className="">
                                                            <div className="text_mng text_mng_lr">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="text_mng text_mng_lr">
                                                    <TextValidator
                                                        required
                                                        errorMessages={[
                                                            "Please Enter Valid Store Name",
                                                            "Please Enter Valid Store Name",
                                                            "Please Enter Store Name",
                                                        ]}
                                                        validators={[
                                                            "minStringLength:2",
                                                            "maxStringLength:30",
                                                            "required",
                                                        ]}
                                                        name="store_company_name"
                                                        onChange={handleChange}
                                                        label="Company / Store Name"
                                                        variant="outlined"
                                                        value={storeDetails.store_company_name}
                                                    />
                                                    <div>
                                                        {storeName && storeDetails.store_company_name && storeDetails.store_company_name.length > 0 ? (
                                                            <div style={{ color: "green", paddingTop: "10px" }}>
                                                                Store name is available
                                                            </div>
                                                        ) : !storeName && storeDetails.store_company_name && storeDetails.store_company_name.length > 0 ? (
                                                            <div style={{ color: "Red", paddingTop: "10px" }}>
                                                                Store name is not available
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="text_mng text_mng_lr">
                                                    <div id="recaptcha-container"></div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="">
                                                    <div className="text_mng submit_div">
                                                        <Button id="sign-in-button" onClick={() => gotoWelcome()} className="btn btn-primary w-100 text-truncate mb-2" name="Continue"></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :

                <div className="container-fluid p-0 m-0">
                    <div className="row p-0 m-0">
                        <div className="col-sm-12 col-lg-6 forget_left">
                            <div className="forgotPasswordDeal createBusinessDeal container"
                                style={{
                                    background: `url(${otpBanner})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'calc(100% - 40px) calc(100% - 56px)',
                                    backgroundPosition: '20px 30px',
                                    // backgroundSize: 'cover',
                                }} >
                                <img className="logo_home img-fluid" src={ShopezLogo} />
                                <img className="logo_forgot img-fluid mobile-setting" src={otpBanner} />
                            </div>
                        </div>
                        <div className="loginForm p-0  col-sm-12 col-lg-6 login_otp_card_container">
                            <div className=" ">
                                <div onClick={() => gotoBackback()} className="back_set_other" style={{ cursor: "pointer" }}>
                                    <img className="back_other img-fluid" src={ShopezBack} />
                                </div>
                                <div id="create-card" className="forgot_card_container create_otp_card_container">
                                    <div className="container">
                                        <div className="text-center img_div"><img className="new_img_logo text-center img-fluid" src={LogoOTP} /></div>
                                        <h1 className="heading-title mb-2 text-center">OTP verification</h1>
                                        <p className="h6-label-tagline text-center">
                                            Please enter the OTP to verify your phone number
                                        </p>

                                        <div>
                                            <ValidatorForm>
                                                <div className="">
                                                    <div className="">
                                                        <div className="forgot_password otp_max_width otp-input text_mng_lr mb-1 first_div_top_otp">
                                                            <OtpInput
                                                                value={otpValue}
                                                                onChange={(e) => handleOtpChange(e)}
                                                                numInputs={6}
                                                                className="opt_box"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="">
                                                    <div className=" text_mng_lr"> */}
                                                <div className="h5-label-resend heading-reset" style={{ cursor: "pointer" }}  >
                                                    <Button disabled={disable} onClick={(e) => sendResendOtp(e)} name="Resend OTP" ></Button>
                                                    <div>{count}</div>
                                                </div>
                                                {/* </div>
                                                </div> */}
                                                <div id="recaptcha-container"></div>
                                                <div className=" ">
                                                    <div className="">
                                                        <div className="d-flex justify-content-center ">
                                                            <Button id="sign-in-button" onClick={() => verifiedOtp()} className="btn btn-primary-verify" name="Verify" style={{ width: "50vw" }}></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

export default CreateStore;
