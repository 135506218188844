import React, { useState, useEffect } from "react";
import "./shippingDelivery.scss";
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "react-switch";
import { getMethod } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json"
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";

const ShippingDelivery = (props) => {
    const [switchStatus, setSwitchStatus] = useState(false);
    const [modalMe, setModalMe] = useState(false)

    const typeData = [
        {
            heading: "In-store pickup",
            typeInfo: "Enables customers to pick up from your store",
            switchStatus: true,
        },
        {
            heading: "Local delivery",
            typeInfo: "Select this option if you either deliver goods or use a local delivery service.",
            switchStatus: false,
        },
        {
            heading: "Flat Rate",
            typeInfo: "Let's you charge afixed rate for shipping",
            switchStatus: true,
        },
        {
            heading: "Other Charges",
            typeInfo: "Enables carriers to calculate shipping rate for various delivery methods",
            switchStatus: false,
        },
    ];

    useEffect(() => {
        getSteps()
    }, [])
    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    }

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mr-3 mobileResponsive mt-3">
                <h1 className="col-12 heading-title">Shipping & Delivery</h1>
            </div>

            <div className="row ml-3 mr-3 mobileResponsive mt-3">
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="h4-label">Shipping Origin</div>
                            <div className="muiDescripationInfo mt-2">
                                This is the address from where your orders will be shipped. This
                                address will be used to calculato real-time shipping rates and may
                                be your current location where customers can pick up their order.{" "}
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <TextField
                                errorMessages={"Please Enter Address"}
                                validators={["minStringLength:1", "maxStringLength:30", "required"]}
                                onChange={(text) => console.log(text)}
                                name="address"
                                label="Address"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton className="edit">
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="h4-label">Shipping Destination</div>
                            <div className="muiDescripationInfo mt-2">
                                This is the address from where customers can pick up their orders.
                                Please provide instructions on how and when customers can pick up
                                their order.
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <TextField
                                errorMessages={"Please Enter Address"}
                                validators={["minStringLength:1", "maxStringLength:30", "required"]}
                                onChange={(text) => console.log(text)}
                                name="address"
                                label="Address"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton className="edit">
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 mobileResponsive mt-3">
                <div className="col-12 h4-label">
                    Shipping & Delivery Methods
                </div>
                <div className="col-12 muiDescripationInfo mt-2">
                    Shopez has partnered with USPS and Canada Post to get you best rates for
                    the deliveries made in the USA and Canada. You can enable customers to
                    pickup their orders in store or get them delivered through your
                    preferred shipping carriers.
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3">
                <div className="col-12 h6-label">Type</div>
                <div className="col-12">
                    <div className="col-12 mt-2" style={{ background: "#F6F6F6", padding: "30px" }}>
                        {typeData.map((item, index) => {
                            return (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <div className="h5-label">{item.heading}</div>
                                        <p className="typeInfo">{item.typeInfo}</p>
                                    </div>
                                    <td>
                                        <Switch
                                            checked={item.switchStatus}
                                            onColor="#00A739"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onChange={() => console.log("call onchange")}
                                        />{" "}
                                    </td>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <OnHoldModal isOnHold={modalMe} />
            </div>
        </div>
    );
};
export default ShippingDelivery;
