import React from "react";
import "./giftReciept.scss";
import FaceBook from "../../../../assets/images/Facebook_F.svg";
import Twitter from "../../../../assets/images/Twitter_T.svg";
import Insta from "../../../../assets/images/Instagram_I.svg";

function GiftReceiptSlip() {
    return (
        <div className="row ml-3 mr-3">
            <div className="packingSlip col-12">
                <div className="row mt-5 ml-2 mr-2">
                    <div className="col-6">
                        <p className="text1">A gift for you</p>
                        <div className="text2">
                            <span style={{ fontSize: "16px", color: '#233949' }}>
                            Order: </span> #PP123456
                        </div>
                        <div className="text2">
                            <span style={{ fontSize: "16px", color: '#233949' }}>
                            Order Date: </span> 31/07/2020 
                        </div>
                        <div className="text2">
                            <span style={{ fontSize: "16px", color: '#233949' }}>
                            Shipping: </span> Express Shipping
                        </div>
                    </div>
                </div>

                <div className="row mt-4 ml-2 mr-2">
                    <div className="col-6 billto">Bill to
                        <div className="addressText">3232, Stephens Avenue,
                            Edmonton AB, T3H3Y3, Canada
                            Tel +1 555 555 5555</div>
                    </div>
                    <div className="col-6 shipto">Ship to
                        <div className="addressText">3232, Stephens Avenue,
                            Edmonton AB, T3H3Y3, Canada
                            Tel +1 555 555 5555</div>
                    </div>
                </div>

                <div className="row mt-4 ml-2 mr-2">
                    <div className="col-12">
                        <hr style={{border: "1px solid #535353", marginTop: "4vw"}}/>
                    </div>
                </div>

                <div className="row mt-2 ml-2 mr-2">
                    <p className="col-8 itemDis"> Item Discription </p>
                    <p className="col-4 qty"> Qty. </p>
                </div>

                <div className="row mt-2 ml-2 mr-2">
                    <div className="col-12">
                        <hr style={{border: "1px solid #535353"}}/>
                    </div>
                </div>

                <div className="row mt-2 ml-2 mr-2">
                    <p className="col-8 pName">iPhone 12 Pro</p>
                    <p className="col-4 qty"> x2 </p>
                </div>

                {/*<div className="row mt-2 ml-2 mr-2">
                    <div className="col-12">
                        <p><span style={{ color: "orange"}}>Note:</span> This is a gift.</p>
                    </div>
                </div>*/}

                <div className="row mt-5 mb-5 ml-2 mr-2">
                    <div className="col-12">
                        <div className="thank">Thank for your business!</div>
                        <div className=" thank1">Please complete the above form & return it with items carefully packed.</div>

                        <div style={{ marginTop: "1vw" }}>
                            <div className="thank">8 Degrees co.</div>
                            <div className=" thank1">3232, Stephens Avenue, Edmonton AB, T3H3Y3, Canada</div>
                            <div className=" thank1">Tel +1 555 555 5555</div>
                        </div>
                        <div style={{ textAlign: "center"}}>
                            <img style ={{marginRight: "1vw"}} src={FaceBook} />
                            <img style ={{marginRight: "1vw"}} src={Twitter} />
                            <img style ={{marginRight: "1vw"}} src={Insta} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GiftReceiptSlip;