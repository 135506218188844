import "mdbootstrap/css/bootstrap.css";
import "mdbootstrap/css/mdb.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/roboto/Roboto-Regular.ttf";
import "./assets/fonts/roboto/Roboto-Bold.ttf";
import "./assets/fonts/roboto/Roboto-Medium.ttf";


  
  
window.$progessPercentage = "0%";
window.$progressStatus = "0%";
window.$step = 0;
console.log(window.$progessPercentage,window.$progressStatus,window.$step)

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
