import React from 'react';
import './recentOrders.scss';
import NoImage from "../../../../assets/images/storelisting.png";

const RecentOrders = () => {
    return (
        <div className="row ml-5 mr-5 mt-3 mobileResponsive">
            <div className='col-12'>
                <table className="OrderSection">
                    <tr>
                        <th className='pb-3'>Product Name</th>
                        <th className='pb-3'>Categories</th>
                        <th className='pb-3'>Full time</th>
                        <th className='pb-3'>Qty.</th>
                        <th className='pb-3'>Cost</th>
                        <th className='pb-3'>Price</th>
                        <th className='pb-3'>Discount</th>
                    </tr>
                    <tr >
                        <td>
                            <img src={NoImage}
                                className='mt-3 pr-2'
                                style={{
                                    width: "50px",
                                    height: "50px",
                                }}
                            />
                            iPhone 12 Pro
                            <div className='ml-5 pl-2 mb-2 product-type'>6” 32 GB, Silver</div>
                        </td>
                        <td>Apparel</td>
                        <td>
                            Offline
                        </td>
                        <td>12</td>
                        <td>
                            $ 12.00
                        </td>
                        <td>$ 12.00</td>
                        <td>10%</td>
                    </tr>
                </table>
            </div>
        </div>
    )
};

export default RecentOrders;
