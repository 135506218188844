import React from 'react';
import "./spinner.scss";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

const SpinnerLoader=()=>{
    return(
        <div className="spinnerStyle">
            <Dots color="#727981" size={40} speed={1} animating={true} />
        </div>
    )
}
export default SpinnerLoader;