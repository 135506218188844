import React, { useEffect, useState } from "react";
import "./addItems.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import { TextField } from "@material-ui/core";
import SearchDB from "../../../../../../assets/images/search.png";
import FilterDB from "../../../../../../assets/images/filter.png";
import ListingDB from "../../../../../../assets/images/listing.png";
import ListviewDB from "../../../../../../assets/images/list view.png";
import GridviewDB from "../../../../../../assets/images/grid view.png";
import DeleteDB from "../../../../../../assets/images/delete.png";
import SpinnerLoader from "../../../../../../components/loader/spinnerLoader";
import emptyStore from "../../../../../../assets/images/storelisting.png";
import NoImage from "../../../../../../assets/images/storelisting.png";

function AddItemsSection({
    loader,
    productListData,
    noSearchResult,
    handleSearchInputChange,
    searchInput,
}) {

    const [expandIndex, setExpandIndex] = useState("");
    const [productListToShow, setProductListToShow] = useState([]);
    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
        } else {
            setExpandIndex(index);
        }
    };

    useEffect(() => {
        setProductListToShow(productListData);
    }, [productListData]);

    return (
        <div className="ml-4">
            <div className="row">
                <div className="col-6">
                    <Input
                        autoComplete="off"
                        type="text"
                        disableUnderline={true}
                        name="search"
                        className="searchbar mt-2 ml-2"
                        placeholder="Search Products "
                        value={searchInput}
                        onChange={(val) => handleSearchInputChange(val)}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={SearchDB} />
                            </InputAdornment>
                        }
                    />
                </div>

                <div className="col-6 d-flex justify-content-end">
                    <div>
                        <img className="paddingRight80" src={FilterDB} alt="" />
                        <img className="paddingRight80" src={ListingDB} alt="" />
                        <img className="paddingRight80" src={ListviewDB} alt="" />
                        <img className="paddingRight00" src={GridviewDB} alt="" />
                    </div>
                </div>
            </div>

            {loader ? (
                <SpinnerLoader />
            ) : productListToShow.length > 0 ? (
                <div>
                    <table className="listSectionAddItem">
                        <tr>
                            <th width="3vw"></th>
                            <th width="3vw">Product Name</th>
                            <th width="3vw">UPC</th>
                            <th width="3vw">SKU</th>
                            <th width="3vw">Sales Price</th>
                            <th width="3vw">list Price</th>
                            <th width="3vw">Actions</th>
                        </tr>

                        {productListToShow && productListToShow.map((item, index) => {
                            return (
                                <>
                                    <tr key={index + 1} style={{ cursor: "pointer" }}>
                                        <td>
                                            {item.structure === "parent" && expandIndex !== index ? (
                                                <i
                                                    class="fa fa-angle-down iconColorArrow"
                                                    onClick={() => expandDropdown(index)}
                                                    aria-hidden="true"
                                                    >
                                                </i>
                                            ) : item.structure === "parent" && expandIndex === index ? (
                                                <i
                                                    class="fa fa-angle-up iconColorArrow"
                                                    onClick={() => expandDropdown(index)}
                                                    aria-hidden="true"
                                                    >
                                                </i>
                                            ) : null}
                                            {item.images && item.images.slice(0, 1).map((item, i) => {
                                                return (
                                                    <img height="50" width="50" src={item?.original} alt=""/>
                                                );
                                            })}

                                            {typeof json_data !== "undefined" && item.images.length == 0 ? (
                                                <img height="50" width="50" src={NoImage} alt="" />
                                            ) : (
                                                ""
                                            )}{" "}
                                        </td>
                                        <td>{productListToShow?.[index]?.product_name}</td>
                                        <td>{productListToShow?.[index]?.upc}</td>
                                        <td>{productListToShow?.[index]?.partner_sku}</td>
                                        <td>{productListToShow?.[index]?.invoice_cost}</td>
                                        <td>{productListToShow?.[index]?.price}</td>
                                        <td>
                                            <img onClick={() => alert("delete")} src={DeleteDB} />
                                        </td>
                                    </tr>

                                    {expandIndex === index && productListToShow[index].children.map((items, index) => {
                                        return (
                                            <tr key={index + 1}>
                                                <td>
                                                    {items.images && items.images.slice(0, 1).map((items, i) => {
                                                        return (
                                                            <img height="50" width="50" src={items?.original} alt=""/>
                                                        );
                                                    })}

                                                    {typeof json_data !== "undefined" && items.images.length == 0 ? (
                                                        <img height="50" width="50" src={NoImage} alt=""/>
                                                    ) : (
                                                        ""
                                                    )}{" "}
                                                </td>
                                                <td>{items?.product_name}</td>
                                                <td>{items?.upc}</td>
                                                <td>{items?.partner_sku}</td>
                                                <td>{items?.invoice_cost}</td>
                                                <td>{items?.price}</td>
                                                <td>
                                                    <img onClick={() => alert("delete")} src={DeleteDB}/>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                            );
                        })}
                    </table>
                </div>
            ) : (
                <div>
                    <div className="storeListingEmpty">
                        <img src={emptyStore} alt="" />
                    </div>
                </div>
            )}
        </div>
    );
}
export default AddItemsSection;
