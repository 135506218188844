import React, { useState, useEffect } from "react";
import CollectionDB from "../../../assets/images/collection.png";
import { getMethodWithPlatfrom } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { useHistory } from "react-router-dom";
import WebIcon from "../../../assets/images/Web_W.svg";
import Phone from "../../../assets/images/Call_C.svg";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import { getUserRole } from "../../../utils/common";
import { apiKeyLocation } from "../../../utils/env.json";
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import Geocode from "react-geocode";
import Pagination from '@mui/material/Pagination';

Geocode.setApiKey(apiKeyLocation);

function OrderStores() {
    const itemsPerPage = 20;
    const history = useHistory();
    const [listOfStores, setListOfStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (getUserRole() === "super-admin") {
            storeListing(urls.ordersAdmin + "?query_for=all_stores", "super-admin");
        } else {
            history.push("/orders/listing");
        }
    }, []);

    const storeListing = async (url, userType) => {
        setLoading(true);
        await getMethodWithPlatfrom(url, userType).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setListOfStores(response.data.results.data);
                setLoading(false);
            }
        });
        setLoading(false);
    };

    const handlePageChange = async (event, value) => {
        if (getUserRole() === "super-admin") {
            setLoading(true);
            setPage(value)

            await getMethodWithPlatfrom(urls.ordersAdmin + "?query_for=all_stores&page="+value, "super-admin").then((response) => {
                if (response.status === 200) {
                    setCount(Math.ceil(response.data.count/itemsPerPage))
                    setListOfStores(response.data.results.data);
                    setLoading(false);
                }
            });

            setLoading(false);
        }
    }

    const showStoreList = () => {
        if (loading) {
            return <SpinnerLoader />;
        } else {
            return (
                <div className="col-12">
                    {listOfStores.length !== 0 ? (
                        <>
                            <div className="row listSectionAddproduct">
                                <div className="col-4 f-600">Store Name</div>
                                <div className="col-4 f-600">Owner's Name</div>
                                <div className="col-4 f-600">Info</div>
                            </div>

                            {listOfStores && listOfStores.map((item, index) => {
                                return (
                                    <div className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC", cursor: "pointer"}} onClick={() => history.push(`/orders/listing/${item.store_id}`)}>
                                        <div className="col-4 d-flex" style={{alignItems: "center"}}>
                                            {item.store_name}
                                        </div>
                                        <div className="col-4 d-flex" style={{alignItems: "center"}}>
                                            {item.first_name + " " + item.last_name}
                                        </div>
                                        <div className="col-4" style={{alignItems: "center"}}>
                                            {item.contact_number !== "" && item.contact_number !== undefined && item.contact_number !== null &&
                                                <div>
                                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item.contact_number}
                                                </div>
                                            }

                                            {item.website !== "" && item.website !== undefined && item.website !== null &&
                                                <div>
                                                    <LanguageIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item.website}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <div className="img-center-store">
                            <img className="collectionImage-store" src={CollectionDB} alt=""/>
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <div className="w-100 screenLeftMargin">
            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="heading-title col-12">Orders</div>
            </div>

            {count > 1 && 
                <div className="row ml-5 mr-5 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                    <div>{showStoreList()}</div>
                </div>
            </div>

            {count > 1 && 
                <div className="row ml-3 mr-3 mt-3 mb-5 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
export default OrderStores;
