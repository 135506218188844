import React, { useState, useEffect } from "react";
import "./Order.scss";
import { useHistory, useParams } from "react-router-dom";
import Email from "../../../../assets/images/Email_E.svg";
import Bell from "../../../../assets/images/Bell_B.svg";
import OrderDb from "../../../../assets/images/Order_Main.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "../../../../components/button/button";
import Input from "@material-ui/core/Input";
import SearchDB from "../../../../assets/images/Search_S.svg";
import CompleteDb from "../../../../assets/images/Completed_C.svg";
import CancelDb from "../../../../assets/images/Cancel_C.svg";
import FilterDb from "../../../../assets/images/Filter_F.svg";
import SpinnerLoader from "../../../../components/loader/spinnerLoader";
import TextField from "@material-ui/core/TextField";
import Modal from "react-modal";
import { getMethodWithPlatfrom, getMethod } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import { getUserRole } from "../../../../utils/common";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import Pagination from '@mui/material/Pagination';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";

const Order = (props) => {
    const itemsPerPage = 20;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const orderStatus = [
        { color: "#00AF50", status: "Order Received" },
        { color: "#FFA500", status: "Order in Process" },
        { color: "#005AFF", status: "Order Ready" },
        { color: "#00AF50", status: "Order Shipped" },
        { color: "#C55B11", status: "Order Cancelled" },
        { color: "#c70e0e", status: "Order Returned" },
        { color: "#436a7d", status: "Order Picked Up" }
    ];
    const location = useParams();
    const [selectedDate, handleDateChange] = useState(new Date());
    const history = useHistory();
    const [filterDetail, setFilterDetail] = useState(false);
    const [merchantOrderList, setMerchantOrderList] = useState([]);
    const [currentOrderCount, setCurrentOrderCount] = useState("");
    const [orderNumberFilter, setOrderNumberFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [customerFilter, setCustomerFilter] = useState("");
    const [fromDateFilter, setFromDateFilter] = useState("");
    const [toDateFilter, setToDateFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [modalMe, setModalMe] = useState(false)
    const [expandIndex, setExpandIndex] = useState("");

    const notification = async () => {
        history.push("/notifications");
    };

    const openFilter = (Action) => {
        setFilterDetail(true);
    };

    const closeFilter = () => {
        setFilterDetail(false);
    };

    const orderDetails = async (order_id, item, status, orderPick) => {
        if (getUserRole() === "super-admin") {
            history.push({
                pathname: `/orders/details/${location.id}/${order_id}`,
                orderStatus: status
            });
        } else {
            history.push({
                pathname: `/orders/details/${order_id}/${orderPick.for_pick ? "pickUp" : "deliver"}`,
                orderStatus: status,
                orderValue: orderPick,
            });
        }
    };

    const orderListing = async (url, type) => {
        setLoading(true);
        await getMethodWithPlatfrom(url, type).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setMerchantOrderList(response.data.results.data);
                setLoading(false);
            }
            setLoading(false);
        });
    };

    const handleCurrentOrdersCount = async (url, type) => {
        await getMethodWithPlatfrom(url, type).then((response) => {
            if (response.status === 200) {
                setCurrentOrderCount(response.data)
            }
        });
    }

    useEffect(() => {
        if (getUserRole() !== "super-admin") {
            orderListing(urls.ordersMerchant + "?query_for=order_list", "merchant");
            handleCurrentOrdersCount(urls.ordersMerchant + "?query_for=order_count", "merchant");
            getSteps()
        } else {
            orderListing(urls.ordersAdmin + "?store_id=" + location.id, "super-admin");
            handleCurrentOrdersCount(urls.ordersAdmin + "?stores_id=" + location.id + "&order_count=1", "super-admin");
        }
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    };

    const handlePageChange = async (event, value) => {

        if (orderNumberFilter || customerFilter || toDateFilter || fromDateFilter || statusFilter) {
            if (getUserRole() === "super-admin") {
                setLoading(true);
                setPage(value)

                await getMethodWithPlatfrom(urls.ordersMerchantFilter + "?store_id=" + location.id + "&order_number=" + orderNumberFilter + "&first_name=" + customerFilter + "&date_to=" + toDateFilter + "&date_from=" + fromDateFilter + "&status=" + statusFilter + "&page=" + value, "super-admin").then((response) => {
                    if (response.status === 200) {
                        setCount(Math.ceil(response.data.count / itemsPerPage))
                        setMerchantOrderList(response.data.results.data);
                    }
                });

                setLoading(false);
            } else {
                setLoading(true);
                getSteps();
                setPage(value)

                await getMethodWithPlatfrom(urls.ordersMerchantFilter + "?order_number=" + orderNumberFilter + "&first_name=" + customerFilter + "&date_to=" + toDateFilter + "&date_from=" + fromDateFilter + "&status=" + statusFilter + "&page=" + value, "merchant").then((response) => {
                    if (response.status === 200) {
                        setCount(Math.ceil(response.data.count / itemsPerPage))
                        setMerchantOrderList(response.data.results.data);
                    }
                });

                setLoading(false);
            }
        } else {

            if (getUserRole() === "super-admin") {
                setLoading(true);
                setPage(value)

                await getMethodWithPlatfrom(urls.ordersAdmin + "?store_id=" + location.id + "&page=" + value, "super-admin").then((response) => {
                    if (response.status === 200) {
                        setCount(Math.ceil(response.data.count / itemsPerPage))
                        setMerchantOrderList(response.data.results.data);
                    }
                });

                setLoading(false);
            } else {
                setLoading(true);
                getSteps();
                setPage(value)

                await getMethodWithPlatfrom(urls.ordersMerchant + "?query_for=order_list&page=" + value, "merchant").then((response) => {
                    if (response.status === 200) {
                        setCount(Math.ceil(response.data.count / itemsPerPage))
                        setMerchantOrderList(response.data.results.data);
                    }
                });

                setLoading(false);
            }
        }
    }

    const handleOrderListFilter = async () => {

        if (getUserRole() === "super-admin") {
            setLoading(true);
            setPage(1)

            await getMethodWithPlatfrom(urls.ordersMerchantFilter + "?store_id=" + location.id + "&order_number=" + orderNumberFilter + "&first_name=" + customerFilter + "&date_to=" + toDateFilter + "&date_from=" + fromDateFilter + "&status=" + statusFilter, "super-admin").then((response) => {
                if (response.status === 200) {
                    setCount(Math.ceil(response.data.count / itemsPerPage))
                    setMerchantOrderList(response.data.results.data);
                }
            });

            setLoading(false);
        } else {
            setLoading(true);
            setPage(1)
            await getMethodWithPlatfrom(urls.ordersMerchantFilter + "?order_number=" + orderNumberFilter + "&first_name=" + customerFilter + "&date_to=" + toDateFilter + "&date_from=" + fromDateFilter + "&status=" + statusFilter, "merchant").then((response) => {
                if (response.status === 200) {
                    setCount(Math.ceil(response.data.count / itemsPerPage))
                    setMerchantOrderList(response.data.results.data);
                }
            });

            setLoading(false);
        }

        setFilterDetail(false);
    }
    const changeToFloat = (value) => {
        let valueInFloat = value !== null ? value.toFixed(2) : "";
        return valueInFloat;
    };

    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
        } else {
            setExpandIndex(index);
        }
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value)
    }

    const allStyless = {
        content: {
            top: "50%",
            left: "75%",
            transform: screenSize.width <= 1100 ? 'translate(-60%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "35vw" : "25vw",
            height: "35vh",
        },
    };

    // const gotoBack = async () => {
    //     history.goBack()
    // };

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
            {/* <div className="row ml-3 pl-3 mr-3 mt-3">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div> */}

            <div className="row ml-3 mr-3 mt-3">
                <div className="col-6 d-flex">
                    <div className="d-flex mr-3">
                        <img width="30" src={OrderDb} />
                    </div>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                        <h1 className="heading-title">Orders</h1>
                    </div>
                </div>

                {/* <div className="col-6 justify-content-end d-flex">
                    <div className="muiNotificationsIcon" onClick={() => notification()}>
                        <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                    <div className="muiNotificationsIcon ml-3">
                        <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                </div> */}
            </div>

            <div className="row ml-3 mr-3">
                <div className="Order-date-div ml-md-3 mt-3">
                    <div className="order-day ">Orders for today</div>
                    <div className="order-num">{currentOrderCount.total_order}</div>
                    {/* <div className="order-time ">{momen t(currentOrderCount.today_date).format('dddd DD MMM, YYYY')}</div> */}
                    <div className="order-time ">{moment(currentOrderCount?.today_date).format('DD/MM/YY')}</div>
                </div>

                <div className="Order-complete-div mt-3">
                    {/* <img className="complete-icon ml-3 mt-3" src={CompleteDb} /> */}
                    <div className="order-day ">Orders Completed</div>
                    {/* <div className="d-flex"> */}
                    {/* <span className="order-complete-text ml-2 mt-4">
                            Orders completed
                        </span> */}
                    <div className="order-num">{currentOrderCount?.order_completed}</div>
                    {/* <div className="order-complete-num ml-3 mt-2">{currentOrderCount.order_completed}</div> */}
                    <div className="order-time ">{moment(currentOrderCount?.today_date).format('DD/MM/YY')}</div>
                    {/* </div> */}
                </div>

                <div className="Order-cancle-div mt-3">
                    {/* <img className="complete-icon ml-3 mt-3" src={CancelDb} /> */}
                    <div className="order-day ">Orders Cancelled</div>
                    {/* <div className="d-flex"> */}
                    {/* <span className="order-complete-text ml-2 mt-4">
                            Orders Cancelled
                        </span> */}
                    <div className="order-num ">{currentOrderCount?.order_cancelled}</div>
                    {/* <div className="order-complete-num ml-3 mt-2">{currentOrderCount.order_cancelled}</div> */}
                    <div className="order-time ">{moment(currentOrderCount?.today_date).format('DD/MM/YY')}</div>
                    {/* </div> */}
                </div>
                <div className="right-part_order_top">
                    <div className="title_orders">LEGEND</div>
                    <div className="desc_orders">
                        <div className="order received_order">Received</div>
                        <div className="order inprogress_order">In Process</div>
                        <div className="order ready_order">Ready</div>
                        <div className="order shiped_order">Shipped</div>
                        <div className="order packed_order">Picked Up</div>
                        <div className="order cancelled_order">Cancelled</div>
                        <div className="order returned_order">Returned</div>
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3">
                <div className="order-filter col-12 d-flex justify-content-end">
                    <div onClick={() => openFilter()} style={{ cursor: "pointer" }}>
                        <img src={FilterDb} />
                        <span className="filter-text ml-2">Filter</span>
                    </div>
                </div>
            </div>

            {/* <div className="row ml-3 mr-3 mt-3">
                <div className="col-12">
                    <div className="status-box mt-3">
                        <div className="status-box-text ml-3 mt-2 pb-2">
                            App user status legend
                        </div>
                        <div className="d-flex">
                            {orderStatus.map((statusInfo) => {
                                return (
                                    <div>
                                        <div style={{backgroundColor: statusInfo.color, width: "10.55vw", height: "0.7vw", paddingLeft: "0.2rem"}}></div>
                                        <div className="status-text mt-2 mb-2">{statusInfo.status}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row ml-3 mr-3 pl-3 pr-3 mt-3">
                {count > 1 &&
                    <div className="col-12 mt-3">
                        <div className="shopPagination">
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                }

                <div className="col-12 mt-4 mb-4">
                    {!loading ?
                        <>
                            <div className="row">
                                <div className="col-3 f-600">{'Date'}</div>
                                <div className="col-2 f-600">Order#</div>
                                <div className="col-2 f-600">Name</div>
                                <div className="col-2 f-600">Address</div>
                                <div className="col-1 f-3600">Total</div>
                                <div className="col-2 f-600">Status</div>
                            </div>

                            {merchantOrderList.length > 0 && merchantOrderList.map((item, index) => {
                                return (
                                    <>
                                        {merchantOrderList?.[index]?.order_break?.length > 1 ?
                                            <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#ECECEC", cursor: "pointer", height: "70px" }}>
                                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                                    <div className="d-flex ml-2" style={{ alignItems: "center" }}>
                                                        {merchantOrderList?.[index]?.order_break?.length > 0 && expandIndex !== index ? (
                                                            <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-down iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                                        ) :
                                                            merchantOrderList?.[index]?.order_break?.length > 0 && expandIndex === index ? (
                                                                <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-up iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                                            )
                                                                : null}
                                                    </div>
                                                    <div className="d-flex ml-1">
                                                        {moment(item?.date_placed,).format('YYYY/MM/DD HH:mm ')}
                                                    </div>
                                                </div>

                                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                    {item?.number}
                                                </div>

                                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                    {item?.shipping_address?.first_name + " " + item?.shipping_address?.last_name}
                                                </div>

                                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                    {item.shipping_address.line1 + " " + item.shipping_address.line2 + " " + item.shipping_address.line3}
                                                    {item.shipping_address.line4 + " " + item.shipping_address.state + " " + item.shipping_address.postcode}
                                                </div>

                                                <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                                    ${changeToFloat(+ item?.sub_total)}
                                                </div>

                                            </div>
                                            :
                                            merchantOrderList?.[index]?.order_break?.length > 0 && merchantOrderList?.[index]?.order_break.map((axItem, axIndex) => {
                                                return (
                                                    <>
                                                        {axItem?.length > 0 && axItem.map((bxItem, bxIndex) => {
                                                            return (
                                                                <>
                                                                    {bxIndex === 0 &&
                                                                        <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#ECECEC", cursor: "pointer", height: "70px" }} onClick={() => orderDetails(item.id, item, item.status, bxItem)}>
                                                                            <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                                                                <div className="d-flex">

                                                                                    <div className={bxItem.status.replace(" ", "").replace(" ", "") + 'Bar ' + (item.cancellationflag && item.cancellationflag.replace(" ", "")) + ' ' + (item.returnedflag && item.returnedflag.replace(" ", "")) + ' orderlisting-color'}></div>
                                                                                </div>

                                                                                <div className="d-flex ml-2">
                                                                                    {moment(item?.date_placed,).format('YYYY/MM/DD HH:mm ')}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                                {item?.number}
                                                                            </div>

                                                                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                                {item?.shipping_address?.first_name + " " + item?.shipping_address?.last_name}
                                                                            </div>

                                                                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                                {item.shipping_address.line1 + " " + item.shipping_address.line2 + " " + item.shipping_address.line3}
                                                                                {item.shipping_address.line4 + " " + item.shipping_address.state + " " + item.shipping_address.postcode}
                                                                            </div>

                                                                            <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                                                                ${changeToFloat(+ bxItem?.cost)}
                                                                            </div>

                                                                            <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                                <div className={bxItem.status.replace(" ", "").replace(" ", "") + 'Color ' + (item.cancellationflag && item.cancellationflag.replace(" ", "")) + ' ' + (item.returnedflag && item.returnedflag.replace(" ", ""))}>{bxItem.for_delivery === true ? bxItem.status : bxItem.status}</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })
                                        }

                                        {expandIndex === index && merchantOrderList?.[index]?.order_break?.length > 0 && merchantOrderList?.[index]?.order_break.map((exItem, exIndex) => {
                                            return (
                                                <>
                                                    {exItem.length > 0 && exItem.map((acItem, acIndex) => {
                                                        return (
                                                            <>
                                                                {acIndex === 0 &&
                                                                    <div className="row ft-14 ml-1 mt-3 pt-3 pb-3" style={{ background: "#ECECEC", cursor: "pointer" }} onClick={() => orderDetails(item.id, item, item.status, acItem)}>
                                                                        <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                                                            <div className="d-flex">
                                                                                <div className={acItem.status.replace(" ", "").replace(" ", "") + 'Bar ' + (item.cancellationflag && item.cancellationflag.replace(" ", "")) + ' ' + (item.returnedflag && item.returnedflag.replace(" ", "")) + ' orderlisting-color'}></div>
                                                                            </div>
                                                                            <div className="d-flex ml-4">
                                                                                {moment(item?.date_placed,).format('YYYY/MM/DD HH:mm ')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                            {item?.number}
                                                                        </div>

                                                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                            {item?.shipping_address?.first_name + " " + item?.shipping_address?.last_name}
                                                                        </div>
                                                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                            {item.shipping_address.line1 + " " + item.shipping_address.line2 + " " + item.shipping_address.line3}
                                                                            {item.shipping_address.line4 + " " + item.shipping_address.state + " " + item.shipping_address.postcode}
                                                                        </div>
                                                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                                                            ${changeToFloat(+ acItem?.price_excl_tax)}
                                                                        </div>

                                                                        <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                                                            <div className={acItem.status.replace(" ", "").replace(" ", "") + 'Color ' + (item.cancellationflag && item.cancellationflag.replace(" ", "")) + ' ' + (item.returnedflag && item.returnedflag.replace(" ", ""))}>{acItem.for_delivery === true ? acItem.status : acItem.status}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })}
                                    </>
                                );
                            })}
                            {merchantOrderList.length == 0 &&
                                <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6", textAlign: "center", alignItems: "center", height: "75px" }}>
                                    <div className="col-12 " >No Orders found.</div>
                                </div>
                            }
                        </>
                        :
                        <SpinnerLoader />
                    }
                    <OnHoldModal isOnHold={modalMe} />
                </div>

                {count > 1 &&
                    <div className="col-12 mt-4 mb-5">
                        <div className="shopPagination">
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                }
            </div>

            <div>
                <Modal isOpen={filterDetail} style={allStyless} overlayClassName="myoverlay" closeTimeoutMS={500}>
                    <div className="row">
                        <div className="col-6" >
                            <img src={FilterDb} />
                            <span className="filter-text ml-2">Filter by</span>
                        </div>
                        <div className="col-6" style={{ textAlign: "right" }}>
                            <button onClick={() => closeFilter()} className="filterCloseIcon">
                                <CloseIcon style={{ fontSize: "18px" }} />
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 pr-2">
                            <FormControl variant="standard" className="filter-textfield mt-3" style={{ width: "100%" }}>
                                <TextField
                                    className="orderNumber"
                                    label="Order Number"
                                    name="orderNumber"
                                    variant="outlined"
                                    value={orderNumberFilter}
                                    onChange={(e) => {
                                        setOrderNumberFilter(e.target.value)
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="col-6 pl-2">
                            <FormControl variant="standard" className="filter-textfield mt-3" style={{ width: "100%" }}>
                                <TextField
                                    className="customerNumber"
                                    label="Customer Number"
                                    name="customerNumber"
                                    variant="outlined"
                                    value={customerFilter}
                                    onChange={(e) => {
                                        setCustomerFilter(e.target.value)
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="outlined"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Date From"
                                    className={toDateFilter !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    value={toDateFilter ? toDateFilter : ''}
                                    onChange={(e) => {
                                        setToDateFilter(moment(e).format('YYYY-MM-DD'))
                                    }}
                                    error={false}
                                    helperText={null}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="outlined"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Date To"
                                    className={fromDateFilter !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    value={fromDateFilter ? fromDateFilter : ''}
                                    onChange={(e) => {
                                        setFromDateFilter(moment(e).format('YYYY-MM-DD'))
                                    }}
                                    error={false}
                                    helperText={null}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <hr className="filter-hr w-100 mt-3 mr-3" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <FormControl variant="outlined" className="gray-select">
                                <InputLabel
                                    required
                                    id="status-select"
                                >
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="status-select"
                                    id="status-dropdown"
                                    value={statusFilter}
                                    onChange={(e) => handleStatusFilterChange(e)}
                                    label="Status"
                                    name="status"
                                >
                                    {orderStatus.map((item) => {
                                        return (
                                            <MenuItem value={item.status}> {item?.status} </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                            <Button className="btn btn-primary" name="Filter" onClick={handleOrderListFilter}></Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Order;
