import React,{useState, useEffect}  from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "../../../components/navBar/navBar";
import SideNav from "../../../components/sideNav/sideNav";
import Panel from "../../../routes/panel";
import { getUserRole, getStoreId } from "../../../utils/common";
import "./dashboard.scss";

function Dashboard() {
    const [progress, setProgessbar] =  useState()
    
    useEffect(() => {
        setProgessbar(window.location.pathname)
    }, [progress])

    const [menuStatus, setMenuStatus] = useState(false)
       
    return (
        <Router>
            <div>
                <div style={{display:"flex", backgroundColor:"#fff"}} className={getUserRole()}>
                    <Panel />
                </div>
            </div>
        </Router>
    )
}

export default Dashboard ;