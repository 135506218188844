import React, { useEffect, useState, useCallback } from "react";
import "./detailsTab.scss";
import TextField from "@material-ui/core/TextField";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardTimePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Row, Col } from "react-bootstrap";
import Dropzone, { useDropzone } from 'react-dropzone';
import ImportImage from "../../../../../assets/images/UploadImage.svg";
import { getMethod, postMethodWithTokenProgress, postMethodWithToken, getProductDetails, putMethodWithToken } from "../../../../../utils/services/api";
import urls from "../../../../../utils/services/apiUrl.json";

function DetailSection({ showListing, getCollectionName, getCollectionDescription, getImageId, getStartDate, getStartTime, getEndDate, getEndTime }) {

    const forceUpdate = useForceUpdate();

    const [value, setValue] = useState({
        collectionName: showListing ? showListing.deal_name : "",
        description: showListing ? showListing.deal_description : ""
    })

    const [campaignImagesData, setCampaignImagesData] = useState(showListing ? showListing.deal_image.file : "");
    const [selectedStartDate, setSelectedStartDate] = useState(showListing ? showListing.start_datetime : null);
    const [selectedEndDate, setSelectedEndDate] = useState(showListing ? showListing.end_datetime : null);
    const [selectedStartTime, setSelectedStartTime] = useState(showListing ? showListing.start_datetime : null);
    const [selectedEndTime, setSelectedEndTime] = useState(showListing ? showListing.end_datetime : null);

    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue((value) => value + 1); // update the state to force render
    }

    const handleCollectionName = (e) => {
        setValue((value) => ({
            ...value,
            [e.target.name]: e.target.value,
        }))
        getCollectionName(e.target.value);
    }

    const handleCollectionDescription = (e) => {
        setValue((value) => ({
            ...value,
            [e.target.name]: e.target.value,
        }))
        getCollectionDescription(e.target.value);
    }

    const imageUpload = async (id) => {
        const formData = new FormData()
        formData.append("file", id);
        const result = await postMethodWithTokenProgress(urls.fileUpload, formData);
        if (result.status === 200) {
            setCampaignImagesData(result.data[0].file);
            getImageId(result.data && result.data[0].id);
        }
        forceUpdate();
    }

    const uploadPhotoButton = (
        <div >
            <img src={ImportImage} alt={"UploadImage"} style={{ marginTop: "60px", height: "50px", width: "50px", color: "#535353" }} />
            <div style={{ fontSize: "18px", color: "#868686" }}>
                Banner of the Deals
            </div>
        </div>
    );

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            acceptedFiles.map((key) => imageUpload(key));
        }
    }, [])


    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/jpg, image/png",
    });

    const HandleStartDate = (date) => {
        setSelectedStartDate(date);
        getStartDate(date);
    }

    const HandleStartTime = (date) => {
        setSelectedStartTime(date);
        getStartTime(date);
    }

    const HandleEndDate = (date) => {
        setSelectedEndDate(date);
        getEndDate(date);
    }

    const HandleEndTime = (date) => {
        setSelectedEndTime(date);
        getEndTime(date);
    }

    return (

        <React.Fragment >

            <div className="mt-2">
                <TextField
                    label="Deal Name"
                    name="collectionName"
                    value={value.collectionName}
                    variant="outlined"
                    onChange={(e) => handleCollectionName(e)}
                />
            </div>

            <div className="textFieldDetailSection mt-3" style={{ display: "flex" }}>
                <div style={{ marginRight: "20px", width: "100%" }}>
                    <textarea
                        rows="8"
                        placeholder="Description"
                        className="description"
                        value={value.description}
                        name="description"
                        onChange={(e) => handleCollectionDescription(e)}
                    >
                    </textarea>
                </div>
                <div style={{ marginLeft: "20px", width: "50%", height: "200px", border: "1px solid #C0C0C0", borderRadius: "4px" }}>
                    <div className="uploadPhoto">

                        {campaignImagesData === "" ?
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                {isDragAccept && (<p>All files will be accepted</p>)}
                                {/* {isDragReject && (notification("error","Some files will be rejected"))} */}
                                {(<div className='text-center'>
                                    {uploadPhotoButton}
                                </div>)}
                            </div>
                            :
                            <div>
                                <img src={campaignImagesData} alt={"UploadImage"} style={{ height: "200px", width: "100%" }} />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Row className="textFieldDetailDateSection mt-3" >

                <Col>
                    <div className="row">
                        <div className="col-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required
                                    InputProps={{ disableUnderline: true }}
                                    inputVariant="outlined"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={date => HandleStartDate(date)}
                                    format="yyyy/MM/dd"
                                    disablePast
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className="col-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    required
                                    InputProps={{ disableUnderline: true }}
                                    inputVariant="outlined"
                                    label="Start Time"
                                    mask="__:__ _M"
                                    value={selectedStartTime}
                                    onChange={date => HandleStartTime(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </Col>

                <Col>

                    <div className="row">
                        <div className="col-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required
                                    inputStyle={{ textAlign: 'center' }}
                                    inputVariant="outlined"
                                    label="End Date"
                                    format="yyyy/MM/dd"
                                    value={selectedEndDate}
                                    onChange={date => HandleEndDate(date)}
                                    disablePast
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className="col-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    required
                                    InputProps={{ disableUnderline: true }}
                                    inputVariant="outlined"
                                    label="End Time"
                                    mask="__:__ _M"
                                    value={selectedEndTime}
                                    onChange={date => HandleEndTime(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>

                </Col>

            </Row>

        </React.Fragment >
    )
}
export default DetailSection;